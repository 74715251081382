import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const TranslationProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { i18n, ready } = useTranslation();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const fetchTranslations = async () => {
      setLoaded(false);

      const MAX_RETRY_TIME = 20000; // 30 seconds
      const RETRY_INTERVAL = 2000; // 2 seconds
      const startTime = Date.now();

      let success = false;

      while (!success && Date.now() - startTime < MAX_RETRY_TIME) {
        try {
          const translations = await fetch(
            import.meta.env.VITE_API_URL + '/public/en.json'
          );

          if (!translations.ok) {
            throw new Error(
              `Failed to fetch translations: ${translations.status}`
            );
          }

          const translationsJson = await translations.json();
          for (const namespace of Object.keys(translationsJson)) {
            await i18n.addResourceBundle(
              'en',
              namespace,
              translationsJson[namespace]
            );
          }

          success = true;
          setLoaded(true);
        } catch (error) {
          console.error('Error fetching translations:', error);

          if (Date.now() - startTime < MAX_RETRY_TIME) {
            console.log(`Retrying in ${RETRY_INTERVAL / 1000} seconds...`);
            // Wait for the retry interval before trying again
            await new Promise(resolve => setTimeout(resolve, RETRY_INTERVAL));
          }
        }
      }

      if (!success) {
        console.error('Failed to fetch translations after multiple retries');
        // You might want to handle this case differently, e.g., show an error message
        // For now, we'll set loaded to true to avoid blocking the app indefinitely
        setLoaded(true);
      }
    };

    fetchTranslations();
  }, []);

  if (!ready || !loaded) {
    return <></>;
  }
  return <>{children}</>;
};
