import { RoundedContainer } from '@components/RoundedContainer';
import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { Input } from '@elements/Input';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import { useAccountContext } from '@providers/UserContext';
import { useForgotPassword } from '@services/auth/use-forgot-password';
import { useLogout } from '@services/auth/use-logout';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const ActivateAccountPage = () => {
  const logout = useLogout(true);
  const { secret } = useParams();
  const { validateHash, resetPassword, loading } = useForgotPassword();
  const { t } = useTranslation('settings', {
    keyPrefix: 'members.activate_account'
  });
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const { mutate } = useAccountContext();
  const navigate = useNavigate();

  useEffect(() => {
    logout();
  }, []);

  useEffect(() => {
    if (secret) {
      validateHash(secret).then(isValid => setIsValid(isValid));
    }
  }, [secret]);

  const isFormValid = () => {
    return password === confirmPassword && password.length > 6;
  };

  const handleResetPassword = async () => {
    if (isFormValid() && secret) {
      await resetPassword(secret, password);
      mutate();
      navigate('/');
    }
  };

  const formContent = () => {
    return (
      <>
        <div className="mb-6 w-full text-center">
          <Typography variant="h1" className="text-xl">
            {t('form_title')}
          </Typography>
          <Typography
            variant="h2"
            className="mt-6 !text-sm !font-normal text-primary"
          >
            {t('form_subtitle')}
          </Typography>
        </div>
        <div className="mb-5 flex flex-col gap-4">
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
          />

          <Button
            disabled={!isFormValid() || loading}
            variant="primary"
            onClick={handleResetPassword}
          >
            {loading ? <ClipLoader color="#fff" size={16} /> : t('form_button')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <>
      <MetaTags title="Reset password" />
      <Container className="mt-[-44px] flex h-full items-center justify-center">
        <RoundedContainer className="min-h-[325px] max-w-[500px]">
          {(!isValid && loading) ?? (
            <div className="flex h-full items-center justify-center">
              <ClipLoader color="#fff" size={16} />
            </div>
          )}
          {isValid === false && !loading && (
            <div className="h-full">
              <div className="flex min-h-[320px] flex-col items-center justify-center gap-4">
                <Typography
                  variant="h2"
                  color="dark"
                  className="text-center text-xl leading-10"
                >
                  {t('invalid_link')}
                </Typography>
                <Typography
                  variant="body1"
                  color="dark"
                  className="mb-7 text-center leading-6"
                >
                  {t('error_invalid_link')}
                </Typography>
                <Button
                  variant="primary"
                  onClick={() => navigate('/forgot-password')}
                >
                  {t('go_to_login')}
                </Button>
              </div>
            </div>
          )}
          {isValid && formContent()}
        </RoundedContainer>
      </Container>
    </>
  );
};
