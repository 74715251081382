import { Button } from '@elements/Button';
import { Typography } from '@elements/Typography';
import { useScreenSize } from '@hooks/use-screen-size';
import { useAccountContext } from '@providers/UserContext';
import { useEmployers } from '@services/employer/use-employers';
import { Colors } from '@utils/ColorUtils';
import { Trans, useTranslation } from 'react-i18next';
import Slider from 'react-infinite-logo-slider';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
export const PartnerSection = () => {
  const { t } = useTranslation('home', { keyPrefix: 'partners' });
  const { t: tHome } = useTranslation('home');
  const { accountType } = useAccountContext();
  const { employers, isLoading } = useEmployers();
  const { screenWidth } = useScreenSize();
  const isMobile = screenWidth < 768;
  return (
    <div className="flex flex-col rounded-[20px_60px_60px_60px] bg-light-blue p-4 md:p-12">
      <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
        <div>
          <Typography variant="h2" className="!font-normal">
            <Trans components={{ b: <span className="font-bold" /> }}>
              {t('title')}
            </Trans>
          </Typography>
          <Typography variant="body1" className="mt-2">
            {t('subtitle')}
          </Typography>
        </div>
      </div>
      <div
        className="relative mx-auto mt-5"
        style={{ width: !isMobile ? '100%' : `calc(${screenWidth}px - 40px)` }}
      >
        {employers && !isLoading ? (
          <Slider width="100px" duration={1000} pauseOnHover={true}>
            {employers
              ?.filter(employer => employer.logoUrl)
              .map(employer => (
                <Link
                  to={`/companies/${employer.id}`}
                  key={employer.id}
                  className="flex items-center justify-center"
                >
                  <img
                    style={{ mixBlendMode: 'multiply' }}
                    className="m-4 max-w-[100px] rounded-[20px] object-cover"
                    src={employer.logoUrl}
                    alt={employer.companyName}
                  />
                </Link>
              ))}
          </Slider>
        ) : (
          <div className="flex items-center justify-center">
            <ClipLoader color={Colors.primary} size={40} />
          </div>
        )}
      </div>
      <div className="mt-3 flex justify-end">
        <Link to={`/register?type=${accountType}`}>
          <Button variant="primary" size="sm">
            {tHome('try_for_free')}
          </Button>
        </Link>
      </div>
    </div>
  );
};
