import { ChatBox } from '@components/chat/ChatBox';
import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Typography } from '@elements/Typography';
import { Candidate } from '@models/candidate';
import { useChat } from '@services/chat/use-chat';
import { Trans, useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

interface CandidateIntakeChatProps {
  onNext: () => void;
}

export const CandidateIntakeChat = ({ onNext }: CandidateIntakeChatProps) => {
  const { user } = useAccountContext<Candidate>();
  const { canComplete } = useChat({
    about: { aboutType: 'UserAccount', aboutId: user!.id }
  });

  const { t } = useTranslation('candidate_intake', {
    keyPrefix: 'candidate_intake_chat'
  });
  const { t: tCommon } = useTranslation('common');

  const header = () => {
    return (
      <div className="animate-fade-in pb-4">
        <Card className="!p-6">
          <Typography variant="h4" color="dark" className="text-base">
            {t('title')}
          </Typography>
          <Typography variant="body1" color="dark" className="mt-1 text-sm">
            <Trans
              i18nKey={t('description')}
              components={{
                a: <a className="text-secondary no-underline" />
              }}
            />
          </Typography>
          <div className="mt-4 flex gap-4">
            <div className="flex gap-4">
              <Button
                variant="secondary"
                className="flex items-center"
                size="sm"
                onClick={onNext}
              >
                {tCommon('next')}
              </Button>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <div className="flex h-full flex-col">
      {canComplete && header()}
      <ChatBox about={{ aboutType: 'UserAccount', aboutId: user!.id }} />
    </div>
  );
};
