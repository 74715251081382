import { useToast } from '@elements/Toast';
import { Address } from '@models/address';
import { Employer, EmployerWebsites } from '@models/Employer';
import { UserAccount } from '@models/UserAccount';
import { KoraApi, objectToFormData } from '@services/api';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

interface EmployerInfo {
  email?: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  websites?: EmployerWebsites;
  foundingDate?: Date;
  totalEmployees?: number;
  dutchEmployees?: number;
  indRegistration?: boolean;
  linkedinUrl?: string;
  address?: Address;
  phone?: string;
  industry?: string;
}

interface EmployerState extends EmployerInfo {
  summaryUrl?: string;
}

const initializeState = (
  user: UserAccount<Employer> | null
): EmployerState => ({
  email: user?.email,
  firstName: user?.firstName,
  lastName: user?.lastName,
  companyName: user?.accountInfo?.companyName,
  websites: user?.accountInfo?.websites,
  address: user?.accountInfo?.address ?? undefined,
  summaryUrl: user?.accountInfo?.summaryUrl,
  phone: user?.accountInfo?.phone,
  industry: user?.accountInfo?.industry,
  linkedinUrl: user?.accountInfo?.linkedinUrl,
  foundingDate: user?.accountInfo?.foundingDate,
  totalEmployees: user?.accountInfo?.totalEmployees,
  dutchEmployees: user?.accountInfo?.dutchEmployees,
  indRegistration: user?.accountInfo?.indRegistration
});

const formatWebsites = (websites?: EmployerWebsites): EmployerWebsites => {
  if (!websites) return {};

  return Object.entries(websites).reduce(
    (acc, [key, website]) => ({
      ...acc,
      [key]: website
        ? website.startsWith('http')
          ? website
          : `https://${website}`
        : undefined
    }),
    {}
  );
};

const getChangedValues = (
  current: EmployerState,
  original: UserAccount<Employer> | null,
  overrides?: EmployerInfo
): EmployerInfo => {
  const compareWith = overrides ?? current;
  const changes: EmployerInfo = {};

  // Helper function to check and set changed values
  const checkAndSetChange = <K extends keyof EmployerInfo>(key: K) => {
    const newValue = compareWith[key];
    const originalValue =
      key === 'email'
        ? original?.email
        : original?.accountInfo?.[key as keyof Employer];

    if (key === 'address') {
      if (JSON.stringify(newValue) !== JSON.stringify(originalValue)) {
        changes[key] = newValue;
      }
    } else if (key === 'foundingDate') {
      // Compare dates by converting to ISO strings
      const newDate =
        newValue instanceof Date ? newValue.toISOString() : newValue;
      const origDate =
        originalValue instanceof Date
          ? originalValue.toISOString()
          : originalValue;
      if (newDate !== origDate) {
        changes[key] = newValue;
      }
    } else if (newValue !== originalValue) {
      changes[key] = newValue;
    }
  };

  // Check all possible fields
  [
    'email',
    'firstName',
    'lastName',
    'companyName',
    'websites',
    'linkedinUrl',
    'address',
    'phone',
    'industry',
    'foundingDate',
    'totalEmployees',
    'dutchEmployees',
    'indRegistration'
  ].forEach(key => {
    checkAndSetChange(key as keyof EmployerInfo);
  });

  return changes;
};

export const useEmployerPatch = () => {
  const { user, mutate } = useAccountContext<Employer>();
  const { t } = useTranslation('settings');
  const { error } = useToast();
  const [isSaving, setIsSaving] = useState(false);

  // Initialize all state fields
  const [state, setState] = useState<EmployerState>(initializeState(user));

  // Create individual setters for each field
  const setters = useMemo(
    () =>
      Object.keys(state).reduce(
        (acc, key) => ({
          ...acc,
          [`set${key.charAt(0).toUpperCase()}${key.slice(1)}`]: (value: any) =>
            setState(prev => ({ ...prev, [key]: value }))
        }),
        {}
      ),
    []
  );

  useEffect(() => {
    if (user) {
      setState(initializeState(user));
    }
  }, [user]);

  const canSave = useMemo(() => {
    const changes = getChangedValues(state, user);
    return Object.keys(changes).length > 0 && Boolean(state.companyName);
  }, [state, user]);

  const patch = async (overrides?: EmployerInfo) => {
    setIsSaving(true);

    try {
      const changes = getChangedValues(state, user, overrides);

      if (Object.keys(changes).length === 0) {
        return;
      }

      if (changes.websites) {
        changes.websites = formatWebsites(changes.websites);
      }

      const formData = objectToFormData(changes);
      await KoraApi.patch('/account', formData);
      mutate();
    } catch (e) {
      console.error(e);
      error(t('update_failed'));
    } finally {
      setIsSaving(false);
    }
  };

  const isComplete = useMemo(() => {
    const accountInfo = user?.accountInfo;
    const requiredFields: (keyof EmployerState)[] = [
      'email',
      'firstName',
      'lastName',
      'companyName',
      'websites',
      'address',
      'phone',
      'industry'
    ];

    return requiredFields.every(
      field =>
        state[field] || (field === 'email' ? user?.email : accountInfo?.[field])
    );
  }, [state, user]);

  return useMemo(
    () => ({
      patch,
      canSave,
      isComplete,
      isSaving,
      ...state,
      ...setters
    }),
    [patch, canSave, isComplete, isSaving, state, setters]
  );
};
