import { IPaginated } from '@models/Paginated';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import React, { useState } from 'react';
import useSWR from 'swr';

export const BASE_URL = import.meta.env.VITE_API_URL;
const JWT_TOKEN = 'kora_token';
const JWT_TOKEN_EXP = JWT_TOKEN + '_expiration';
const JWT_REFRESH = 'kora_refresh_token';

export const useSWRPaginated = (key: string, _pageSize = defaultPageSize) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState<any[]>([]);
  const { data, ...swr } = useSWR(
    key + `?page=${currentPage}&pageSize=${_pageSize}`
  );

  const { page, totalPages } = paginationInfo(data);

  // Update totalData when new data arrives
  React.useEffect(() => {
    if (data?.items) {
      setTotalData(prev => [...prev, ...data.items]);
    }
  }, [data]);

  return {
    data: totalData, // Return accumulated data instead of just current page
    isLoading: swr.isLoading,
    isError: swr.error,
    hasReachedEnd: page === totalPages,
    handleLoadMore: () => {
      if (page < totalPages) {
        setCurrentPage(previousValue => previousValue + 1);
      }
    },
    mutate: swr.mutate
  };
};

export const paginationInfo = (data: IPaginated<any>) => {
  return {
    page: data?.page,
    pageSize: data?.pageSize,
    total: data?.total,
    totalPages: data?.totalPages
  };
};

export const defaultPageSize = 10;
export const hasReachedEnd = (
  responses: {
    page: number;
    pageSize: number;
    total: number;
    totalPages: number;
  }[],
  pageSize: number
) => {
  if (!responses || responses.length === 0) {
    return true;
  }

  const lastResponse = responses[responses.length - 1];

  return lastResponse.page === lastResponse.totalPages;
};

export const objectToFormData = (obj: any) => {
  const formData = new FormData();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const element = obj[key];
      if (element instanceof File) {
        formData.append(key, element, element.name);
      } else if (element instanceof Date) {
        formData.append(key, element.toISOString());
      } else if (element instanceof Object && !(element instanceof Date)) {
        for (const innerKey in element) {
          if (element.hasOwnProperty(innerKey)) {
            if (element[innerKey] !== undefined && element[innerKey] !== null)
              formData.append(`${key}[${innerKey}]`, element[innerKey]);
          }
        }
      } else if (element !== undefined && element !== null) {
        formData.append(key, element);
      }
    }
  }
  return formData;
};

const filterParams = (params = {}) => {
  const asArray = Object.entries(params);
  const filtered = asArray.filter(([_, value]) => {
    return Boolean(value);
  });
  return Object.fromEntries(filtered);
};

export const koraFetcher = async (url: string, params = {}) => {
  if (url.includes('undefined')) return;
  // await KoraApi.autoRefreshToken();
  const response = await api
    .get(BASE_URL + `${url}`, {
      params: filterParams(params),
      withCredentials: true // Enable cookies for SWR requests
    })
    .then(res => res.data);
  return response;
};

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true // Enable cookies for SWR requests
});

export const KoraApi = {
  autoRefreshToken: async () => {
    const exp = parseInt(localStorage.getItem(JWT_TOKEN_EXP) || '0');
    const now = Date.now();
    if (exp > now) {
      return;
    }
    try {
      const response = await api.post(
        '/auth/refresh',
        {},
        {
          withCredentials: true // Enable sending and receiving cookies
        }
      );

      if (response.data?.exp) {
        localStorage.setItem(JWT_TOKEN_EXP, response.data.exp);
      }
    } catch (error) {
      return;
    }
  },

  get: async function (
    path: string,
    params = {},
    options?: AxiosRequestConfig
  ): Promise<any> {
    try {
      const response = await api.get(path, {
        params: filterParams(params),
        withCredentials: true, // Enable sending and receiving cookies
        ...options
      });
      return response;
    } catch (error) {
      const data = (error as AxiosError).response?.data;
      const { detail } = data as { detail: string };
      throw new Error(detail);
    }
  },
  /**
   * Send an authenticated post request.
   * @param {string} path The path for the request tot the API
   * @param payload The payload for the put request.
   * @throws error if the http response was not 200/201
   */
  post: async function (
    path: string,
    payload: any,
    download: boolean = false
  ): Promise<any> {
    const extraData: AxiosRequestConfig = {
      withCredentials: true // Enable sending and receiving cookies
    };
    if (download) {
      extraData['responseType'] = 'blob';
    }
    const response = await api.post(path, payload, extraData);
    return response;
  },

  /**
   * Send an authenticated put request.
   * @param {string} path The path for the request tot the API
   * @param payload The payload for the put request.
   * @throws error if the http response was not 200/201
   */
  put: async function (
    path: string,
    payload: any,
    options?: AxiosRequestConfig
  ): Promise<any> {
    try {
      const response = await api.put(path, payload, {
        withCredentials: true, // Enable sending and receiving cookies
        ...options
      });
      return response;
    } catch (error) {
      const data = (error as AxiosError).response?.data;
      const { detail } = data as { detail: string };
      throw new Error(detail);
    }
  },
  patch: async function (
    path: string,
    payload: any,
    options?: AxiosRequestConfig
  ): Promise<any> {
    try {
      const response = await api.patch(path, payload, {
        withCredentials: true, // Enable sending and receiving cookies
        ...options
      });
      return response;
    } catch (error) {
      const data = (error as AxiosError).response?.data;
      const { detail } = data as { detail: string };
      throw new Error(detail);
    }
  },
  delete: async function (path: string) {
    await api.delete(path, {
      withCredentials: true // Enable sending and receiving cookies
    });
  }
};
