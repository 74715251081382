/**
 * This page should ask the API for an empty vacancy object, and then redirect to the vacancy edit page.
 */

import { useNewVacancy } from '@services/vacancies/use-new-vacancy';
import { Colors } from '@utils/ColorUtils';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const VacancyCreatePage = () => {
  const navigate = useNavigate();

  const getVacancy = async () => {
    const vacancy = await useNewVacancy();
    if (vacancy) {
      navigate(`/vacancies/${vacancy.id}/intake`);
    }
  };

  useEffect(() => {
    getVacancy();
  }, []);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <ClipLoader color={Colors.primary} size={50} />
    </div>
  );
};
