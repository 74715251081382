import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './VideoPlayer.css';

interface VideoPlayerProps {
  src: string;
  type?: string;
  autoplay?: boolean;
  controls?: boolean;
  fluid?: boolean;
  responsive?: boolean;
  width?: number;
  height?: number;
  poster?: string;
  className?: string;
  onReady?: (player: any) => void;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  src,
  type = 'video/mp4',
  autoplay = false,
  controls = true,
  fluid = true,
  responsive = true,
  width,
  height,
  poster,
  className,
  onReady
}) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<any>(null);

  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode
      const videoElement = document.createElement('video-js');
      videoElement.classList.add('vjs-big-play-centered');
      videoElement.classList.add('vjs-custom-theme');
      if (className) {
        videoElement.classList.add(className);
      }

      if (videoRef.current) {
        videoRef.current.appendChild(videoElement);
      }

      const options = {
        autoplay,
        controls,
        responsive,
        fluid,
        poster,
        sources: [
          {
            src,
            type
          }
        ]
      };

      // Initialize the player
      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log('player is ready');
        if (onReady) {
          onReady(player);
        }
      }));

      // Set dimensions if provided
      if (width) player.width(width);
      if (height) player.height(height);
    } else {
      // Update player if props change
      const player = playerRef.current;
      player.autoplay(autoplay);
      player.src([
        {
          src,
          type
        }
      ]);
    }
  }, [
    src,
    type,
    autoplay,
    controls,
    fluid,
    responsive,
    width,
    height,
    poster,
    className,
    onReady
  ]);

  // Dispose the Video.js player when the component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div
      data-vjs-player
      className="w-full overflow-hidden rounded-[20px] border-none shadow-[8px_8px_12px_8px_rgba(0,0,0,0.08)] outline-none"
    >
      <div ref={videoRef} className="h-full w-full" style={{ scale: '1.01' }} />
    </div>
  );
};

export default VideoPlayer;
