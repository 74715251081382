import * as Sentry from '@sentry/react';

const SentryErrorBoundary = Sentry.ErrorBoundary;
export const SentryProvider = ({ children }: { children: React.ReactNode }) => {
  if (import.meta.env.VITE_ENV === 'production') {
    return (
      <SentryErrorBoundary fallback={<p>An error has occurred</p>}>
        {children}
      </SentryErrorBoundary>
    );
  }
  return children;
};
