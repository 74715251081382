import { useToast } from '@elements/Toast';
import { KoraApi } from '@services/api';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useMemberCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { error: errorToast, success: successToast } = useToast();
  const { t } = useTranslation('settings', { keyPrefix: 'members' });

  const createMember = async (email: string) => {
    setIsLoading(true);
    try {
      await KoraApi.post('/employer/members', { email });
      successToast(t('invite_success'));
    } catch (error) {
      setError((error as Error).message);
      errorToast(t('invite_error'));
    } finally {
      setIsLoading(false);
    }
  };

  return { createMember, isLoading, error };
};
