import { useToast } from '@elements/Toast';
import { KoraApi } from '@services/api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';

export const useLogout = (silent = false) => {
  const navigate = useNavigate();
  const { t } = useTranslation('header');
  const { mutate: mutateAll } = useSWRConfig();

  const { success } = useToast();

  const clearCache = () =>
    mutateAll(() => true, undefined, { revalidate: false });

  const logout = async () => {
    try {
      await KoraApi.post('/auth/logout', {});
      await clearCache();
      if (!silent) {
        navigate('/');
        window.location.reload();
        success(t('logout_success'));
      }
    } catch (error) {
      console.error(error);
    }
  };
  // navigate('/');

  return logout;
};
