import { useToast } from '@elements/Toast';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';
import { KoraApi } from '../api';

export default function useLogin() {
  const toast = useToast();
  const { t } = useTranslation('login');
  const { mutate } = useAccountContext();

  const login = async (email: string, password: string) => {
    try {
      await KoraApi.post('/auth/login', { email, password });
      mutate();
    } catch (error) {
      toast.error(t('failed_login'));
    }
  };

  return { login };
}
