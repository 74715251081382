import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { Divider } from '@elements/Divider';
import { MarkdownViewer } from '@elements/MarkdownViewer';
import { PercentageBar } from '@elements/PercentageBar';
import { Typography } from '@elements/Typography';
import { MatchingStatus } from '@models/Match';
import { useFunnelItem } from '@services/employer/use-funnel-item';
import { usePatchEmployerMatch } from '@services/employer/use-patch-employer-match';
import { useResumeDownload } from '@services/resumes/use-resume-download';
import { useMarkdownContent } from '@services/use-markdown-content';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

export const MatchDetailPage = () => {
  const { matchId } = useParams();
  const { match, isLoading } = useFunnelItem(matchId!);
  const { t } = useTranslation('funnel');
  const { download } = useResumeDownload(matchId!);
  const { patchWithConfirm } = usePatchEmployerMatch(matchId!);
  const navigate = useNavigate();

  const SummaryCard = () => {
    const { markdownContent } = useMarkdownContent(
      match?.candidate?.summaryUrl!
    );

    return (
      <Card>
        <div className="flex flex-col gap-4">
          <Typography variant="h2" className="mt-4 text-dark">
            {t('summary_of', { name: match?.candidate?.firstName })}
          </Typography>
          <MarkdownViewer content={markdownContent} />
        </div>
      </Card>
    );
  };

  const AppliedCta = () => {
    return (
      <div className="flex gap-4">
        <Button
          variant="info"
          size="sm"
          disabled={
            match?.status !== MatchingStatus.APPLIED &&
            match?.status !== MatchingStatus.INVITED
          }
          className="!min-w-[150px]"
          onClick={() =>
            patchWithConfirm({
              status: MatchingStatus.REJECTED,
              title: t('reject_confirm_title'),
              description: t('reject_confirm_description', {
                name: match?.candidate?.firstName
              }),
              onSuccess: () => navigate(`/funnel`),
              onSuccessMessage: t('reject_success')
            })
          }
        >
          {t('reject')}
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="!min-w-[150px]"
          disabled={
            match?.status !== MatchingStatus.APPLIED &&
            match?.status !== MatchingStatus.REJECTED
          }
          onClick={() => navigate(`/matches/${matchId}/invite`)}
        >
          {t('invite')}
        </Button>
      </div>
    );
  };

  const inviteCta = () => {
    return (
      <div className="flex gap-4">
        <Button
          variant="info"
          size="sm"
          className="!min-w-[150px]"
          onClick={() =>
            patchWithConfirm({
              status: MatchingStatus.REJECTED,
              title: t('reject_confirm_title'),
              description: t('reject_confirm_description', {
                name: match?.candidate?.firstName
              }),
              onSuccess: () => navigate(`/funnel`),
              onSuccessMessage: t('reject_success')
            })
          }
        >
          {t('declined_invite')}
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="!min-w-[150px]"
          onClick={() => {
            patchWithConfirm({
              status: MatchingStatus.INTERVIEW,
              onSuccess: () => navigate(`/funnel`),
              onSuccessMessage: t('accepted_invite'),
              title: t('accepted_invite_confirm_title'),
              description: t('accepted_invite_confirm_description', {
                candidateName: match?.candidate?.firstName
              })
            });
          }}
        >
          {t('accepted_invite')}
        </Button>
      </div>
    );
  };

  const interviewCta = () => {
    return (
      <div className="flex gap-4">
        <Button
          variant="info"
          size="sm"
          className="!min-w-[150px]"
          onClick={() =>
            patchWithConfirm({
              status: MatchingStatus.REJECTED,
              title: t('reject_confirm_title'),
              description: t('reject_confirm_description', {
                name: match?.candidate?.firstName
              }),
              onSuccess: () => navigate(`/funnel`),
              onSuccessMessage: t('reject_success')
            })
          }
        >
          {t('didnt_go_for_it')}
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="!min-w-[150px]"
          onClick={() => {
            patchWithConfirm({
              status: MatchingStatus.JOB_TAKEN,
              onSuccess: () => navigate(`/funnel`),
              onSuccessMessage: t('accepted_offer'),
              title: t('accept_offer_confirm_title'),
              description: t('accept_offer_confirm_description', {
                candidateName: match?.candidate?.firstName
              })
            });
          }}
        >
          {t('accepted_offer')}
        </Button>
      </div>
    );
  };

  const MatchDescriptionCard = () => {
    return (
      <Card>
        <PercentageBar percentage={match?.percentage!} />
        <Typography variant="body1" className="my-5">
          {t('match_description', {
            vacancyTitle: match?.vacancy?.title,
            candidateName: match?.candidate?.firstName
          })}
        </Typography>
        <div className="flex flex-col gap-y-2">
          {match?.candidate?.preferredSalary && (
            <Typography variant="body1">
              💰{' '}
              {t('preferred_salary', {
                salary: match?.candidate?.preferredSalary
              })}
            </Typography>
          )}
          {match?.candidate?.address && (
            <Typography variant="body1">
              📍{' '}
              {t('candidate_address', {
                address: `${match?.candidate?.address?.city}, ${match?.candidate?.address?.country}`,
                candidateName: match?.candidate?.firstName
              })}
            </Typography>
          )}
        </div>
        <div className="mt-3 flex gap-4">
          {match?.candidate?.linkedinUrl && (
            <Link to={match?.candidate?.linkedinUrl} target="_blank">
              <Button variant="info" size="sm" className="bg-transparent">
                {t('view_linkedin')}
              </Button>
            </Link>
          )}
          <Button
            variant="info"
            size="sm"
            className="bg-transparent"
            onClick={download}
          >
            {t('download_resume')}
          </Button>
          <div>
            <Link to={`/matches/${matchId}/invite`}>
              <Button variant="info" size="sm">
                {t('contact_info')}
              </Button>
            </Link>
          </div>
        </div>
      </Card>
    );
  };

  const Body = () => {
    return (
      <>
        <div>
          <div className="flex w-full flex-col justify-between gap-8">
            <div>
              <Typography
                variant="h1"
                className={`line-clamp-2 text-xl font-bold leading-[3rem]`}
                enableTooltip
                tooltipPlacement="top"
              >
                {match?.candidate?.firstName} {match?.candidate?.lastName}
              </Typography>
              <Typography variant="h2" className="text-base text-light">
                {match?.vacancy?.title}
              </Typography>
            </div>

            {(match?.status === MatchingStatus.APPLIED ||
              match?.status === MatchingStatus.REJECTED) && <AppliedCta />}
            {match?.status === MatchingStatus.INVITED && inviteCta()}
            {match?.status === MatchingStatus.INTERVIEW && interviewCta()}
          </div>
          <Divider className="my-4 w-full" orientation="horizontal" />
          <div className="flex flex-col gap-y-4">
            <MatchDescriptionCard />
            <SummaryCard />
          </div>
        </div>
      </>
    );
  };

  return (
    <ContainerWithBackButton loading={isLoading}>
      <Body />
    </ContainerWithBackButton>
  );
};
