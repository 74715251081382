import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { useToast } from '@elements/Toast';
import { Typography } from '@elements/Typography';
import { Employer } from '@models/Employer';
import { useQueryParameters } from '@providers/QueryParametersProvider';
import { useAccountContext } from '@providers/UserContext';
import { useProfileSummaryUpdate } from '@services/account/use-profile-summary-update';
import { useEmployerSummaryGenerator } from '@services/employer/use-employer-summary-generator';
import { useMarkdownContent } from '@services/use-markdown-content';
import { Colors } from '@utils/ColorUtils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { CompanySummary } from '../CompanySummary';

export const EmployerIntakeSummary = () => {
  const { isGenerating, generate } = useEmployerSummaryGenerator();
  const { getQueryParam } = useQueryParameters();
  const redirectTo = getQueryParam('redirectTo') ?? '/vacancies/create';
  const { user } = useAccountContext<Employer>();
  const { t } = useTranslation('employer_intake', {
    keyPrefix: 'employer_intake_summary'
  });
  const { t: tIntake } = useTranslation('employer_intake');

  const { markdownContent: fetchedMarkdownContent, refetch } =
    useMarkdownContent(user?.accountInfo.summaryDraftUrl ?? '');

  const [_, setMarkdownContent] = useState(fetchedMarkdownContent);
  const [editorState, setEditorState] = useState(fetchedMarkdownContent);
  const [isSaving, setIsSaving] = useState(false);
  const { success: successToast } = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isGenerating && !user?.accountInfo.summaryDraftUrl) {
      generate();
    }
  }, [isGenerating, user?.accountInfo.summaryDraftUrl]);

  useEffect(() => {
    if (fetchedMarkdownContent) {
      setEditorState(fetchedMarkdownContent);
      setMarkdownContent(fetchedMarkdownContent);
    }
  }, [fetchedMarkdownContent]);

  const header = () => {
    if (isGenerating) {
      return (
        <Card className="!p-6">
          <Typography
            variant="h4"
            color="dark"
            className="text-center text-base"
          >
            {t('loading_title')}
          </Typography>
          <div className="mt-2 flex justify-center">
            <ClipLoader color={Colors.secondary} size={20} />
          </div>
        </Card>
      );
    } else {
      return (
        <Card className="!p-6">
          <Typography variant="h4" color="dark" className="text-base">
            {t('title')}
          </Typography>
          <Typography variant="body1" color="dark" className="text-sm">
            {t('description')}
          </Typography>
          <div className="mt-4 flex gap-4">
            <Button
              variant="info"
              size="sm"
              onClick={() => {
                generate();
              }}
            >
              {t('generate_again')}
            </Button>
            <Button
              variant="secondary"
              size="sm"
              onClick={async () => {
                setIsSaving(true);
                await useProfileSummaryUpdate({
                  summary: editorState
                });
                refetch();
                setIsSaving(false);
                successToast(tIntake('save_profile_success'));
                navigate(redirectTo);
              }}
            >
              {isSaving && (
                <ClipLoader color="dark" size={12} className="ml-2" />
              )}
              {t('save_description')}
            </Button>
          </div>
        </Card>
      );
    }
  };

  return (
    <>
      {header()}
      {!isGenerating && (
        <Card className="mt-4 !p-6">
          {user?.accountInfo && (
            <CompanySummary
              employer={user?.accountInfo}
              isDraft={true}
              hideVacancies={true}
            />
          )}
        </Card>
      )}
    </>
  );
};
