import { AccountTypeSelector } from '@components/auth/AccountTypeSelector';
import { GoogleLoginButton } from '@components/auth/GoogleLoginButton';
import { RoundedContainer } from '@components/RoundedContainer';
import { Button } from '@elements/Button';
import { Checkbox } from '@elements/Checkbox';
import { Container } from '@elements/Container';
import { Divider } from '@elements/Divider';
import { EmailInput } from '@elements/EmailInput';
import { Input } from '@elements/Input';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { useQueryParams } from '@hooks/use-query-params';
import { CheckCircle } from '@icons/index';
import { AccountType } from '@models/UserAccount';
import { KoraApi } from '@services/api';
import { useRegistration } from '@services/auth/use-registration';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useAccountContext } from 'src/providers/UserContext';
import { Colors } from 'src/utils/ColorUtils';
export const RegisterPage = () => {
  const { t } = useTranslation('register');
  const [searchParams] = useSearchParams();
  const { accountType: accountTypeFromContext } = useAccountContext();
  const accountTypeParam = searchParams.get('type');

  const [companyName, setCompanyName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [repeatPassword, setRepeatPassword] = useState<string>();
  const [repeatPasswordError, setRepeatPasswordError] = useState<string>();
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);
  const { referral } = useQueryParams();

  const [accountType, setAccountType] = useState<AccountType>(
    accountTypeFromContext || (accountTypeParam as AccountType)
  );
  const isMobile = useIsMobile();

  const { isLoggedIn } = useAccountContext();
  const navigate = useNavigate();

  const [passwordError, setPasswordError] = useState<string>();

  useEffect(() => {
    if (referral) {
      const getReferral = async () => {
        const { data } = await KoraApi.get(`/referrals/${referral}/open`);
        setCompanyName(data.companyName);
      };
      getReferral();
    }
  }, [referral]);

  useEffect(() => {
    if (accountTypeFromContext) {
      setAccountType(accountTypeFromContext);
    }
  }, [accountTypeFromContext]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/profile');
    }
  }, [isLoggedIn, navigate]);

  const { isFormValid, register, isLoading, isSuccess } = useRegistration({
    email,
    password,
    repeatPassword,
    acceptedTerms: termsAndConditions,
    companyName,
    accountType
  });

  const renderForm = () => {
    return (
      <RoundedContainer className={`${isMobile ? 'w-[90%]' : 'min-w-[400px]'}`}>
        <div className="mb-6 w-full text-center">
          <Typography variant="h1" className="text-xl">
            {t('form_title')}
          </Typography>
          <Typography
            variant="h2"
            className="mt-2 !text-sm !font-normal text-primary"
          >
            {t('form_subtitle')}
          </Typography>
        </div>
        <div className="mb-4 flex flex-col gap-4">
          {accountType === AccountType.EMPLOYER && (
            <Input
              type="text"
              value={companyName}
              placeholder={t('input.company_name')}
              onChange={e => {
                setCompanyName(e.target.value);
              }}
            />
          )}
          <EmailInput
            type="email"
            value={email}
            placeholder={t('input.email')}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
          <Input
            type="password"
            value={password}
            error={passwordError}
            placeholder={t('input.password')}
            onChange={e => {
              const newPassword = e.target.value;
              setPassword(newPassword);
              if (newPassword.length < 6) {
                setPasswordError(t('input.password_too_short'));
              } else {
                setPasswordError(undefined);
              }
              if (repeatPassword && newPassword !== repeatPassword) {
                setRepeatPasswordError(t('repeat_password_error'));
              } else {
                setRepeatPasswordError(undefined);
              }
            }}
          />
          <Input
            type="password"
            value={repeatPassword}
            error={repeatPasswordError}
            placeholder={t('input.repeat_password')}
            onChange={e => {
              setRepeatPassword(e.target.value);
              if (e.target.value !== password) {
                setRepeatPasswordError(t('repeat_password_error'));
              } else {
                setRepeatPasswordError(undefined);
              }
            }}
          />
          <Checkbox
            checked={termsAndConditions}
            onChange={() => setTermsAndConditions(!termsAndConditions)}
            label={
              <>
                I agree to the{' '}
                <Link to="/terms-and-conditions">terms and conditions</Link> and{' '}
                <Link to="/privacy">privacy policy</Link>
              </>
            }
          />
        </div>
        <div className="mb-5 flex flex-col gap-4">
          <Button disabled={!isFormValid} variant="primary" onClick={register}>
            {isLoading ? (
              <ClipLoader color="#fff" size={20} />
            ) : (
              t('register_button')
            )}
          </Button>
          <Divider />
          <GoogleLoginButton
            className="mx-auto flex w-full items-center justify-center"
            accountType={accountType}
            label={t('sign_up_with_google')}
          />
          {/* <LinkedInLoginButton
            accountType={accountType}
            label={t('sign_up_with_linkedin')}
          /> */}
        </div>
        <div className="text-center">
          <Typography
            variant="body1"
            className="mb-1 mt-2 !text-sm !font-normal !text-primary"
          >
            {t('already_have_account')}{' '}
          </Typography>
          <Link to="/login" className="text-sm font-semibold text-[#000000]">
            {t('login')}
          </Link>
        </div>
      </RoundedContainer>
    );
  };

  return (
    <>
      <MetaTags title="Register" />
      <Container className="mt-[-1%] flex h-full items-center justify-center">
        {accountType ? (
          <>
            {!isSuccess ? (
              renderForm()
            ) : (
              <div className="flex flex-col items-center gap-6">
                <CheckCircle
                  color={Colors.success}
                  width={'3rem'}
                  height={'3rem'}
                />
                <Typography variant="h2" className="!w-[70%] text-center">
                  {t('register_success')}
                </Typography>
              </div>
            )}
          </>
        ) : (
          <AccountTypeSelector onSelect={setAccountType} />
        )}
      </Container>
    </>
  );
};
