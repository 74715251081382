import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Typography } from '@elements/Typography';
import { Status } from '@models/Status';
import { useValidationPopup } from '@providers/ValidationPopupProvider';
import { useResumeUpload } from '@services/account/use-resume-upload';
import { useResumeDelete } from '@services/user/use-resume-delete';
import { useMemo, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export interface ResumeIntakeHeaderProps {
  status: Status | undefined;
  onNextClick: () => void;
  onDeleteResume: () => void;
  canSave: boolean;
  isSaving?: boolean;
}

export const ResumeIntakeHeader = ({
  status: _status,
  onNextClick,
  onDeleteResume,
  isSaving,
  canSave
}: ResumeIntakeHeaderProps) => {
  const { t } = useTranslation('candidate_intake', {
    keyPrefix: 'resume_intake'
  });
  const status = useMemo(() => _status, [_status]);
  const { t: tCommon } = useTranslation('common');
  const { t: tProfile } = useTranslation('profile', { keyPrefix: 'cta' });
  const { deleteResume } = useResumeDelete();
  const { upload, isLoading: isUploadingResume } = useResumeUpload();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { show } = useValidationPopup();

  const noResumeHeader = () => {
    return (
      <>
        <Typography variant="h5" color="dark" className="text-center">
          {t('title')}
        </Typography>
        <Typography
          variant="body1"
          color="dark"
          className="mt-5 px-4 text-center"
        >
          <Trans
            i18nKey={t('description')}
            components={{
              a: (
                <Link
                  className="text-secondary no-underline"
                  to="/blog/linkedin-resume"
                />
              )
            }}
          />
        </Typography>
        <div className="mt-8 flex justify-center gap-4">
          <div className="flex gap-4">
            <Link to="/blog/linkedin-resume">
              <Button variant="info" size="sm">
                {t('no_resume_to_upload')}
              </Button>
            </Link>
            <Button
              variant="secondary"
              className="flex items-center"
              size="sm"
              onClick={() => fileInputRef.current?.click()}
            >
              {isUploadingResume && (
                <ClipLoader color="white" size={16} className="mr-2" />
              )}
              {t('upload_resume')}
            </Button>
          </div>
        </div>
      </>
    );
  };

  const readingResumeHeader = () => {
    return (
      <>
        <Typography variant="h5" color="dark" className="text-center">
          {t('reading_resume_title')}
        </Typography>
        <div className="mt-4 flex justify-center">
          <Button
            variant="primary"
            size="sm"
            disabled={true}
            className="flex items-center"
          >
            <ClipLoader color="white" size={16} className="mr-2" />
            {t('reading')}
          </Button>
        </div>
      </>
    );
  };

  const resumeDoneHeader = () => {
    return (
      <>
        <Typography variant="h5" color="dark" className="text-base">
          {t('reading_resume_done_title')}
        </Typography>
        <Typography variant="body1" color="dark" className="mt-1 text-sm">
          {t('reading_resume_done_description')}
        </Typography>

        <div className="mt-4 flex gap-4">
          <Button
            variant="info"
            size="sm"
            onClick={() =>
              show({
                title: tProfile('resume_delete_confirm_title'),
                description: tProfile('resume_delete_confirm_description'),
                callback: async () => {
                  await deleteResume();
                  setTimeout(() => {
                    onDeleteResume();
                  }, 300);
                }
              })
            }
          >
            {t('remove_resume')}
          </Button>
          <Button
            variant="info"
            size="sm"
            className="flex items-center"
            onClick={() => fileInputRef.current?.click()}
          >
            {isUploadingResume && (
              <ClipLoader color="black" size={16} className="mr-2" />
            )}
            {t('upload_new')}
          </Button>
          <Button
            variant="secondary"
            className="flex items-center"
            size="sm"
            disabled={!canSave}
            onClick={onNextClick}
          >
            {isSaving && (
              <ClipLoader color="white" size={16} className="mr-2" />
            )}
            {tCommon('next')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <Card className="!p-6">
      {!status && noResumeHeader()}
      {(status === Status.BUSY || status === Status.PENDING) &&
        readingResumeHeader()}
      {(status === Status.DONE || status === Status.VERIFIED) &&
        resumeDoneHeader()}
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="application/pdf"
        onChange={e => upload(e.target.files?.[0] ?? null)}
      />
    </Card>
  );
};
