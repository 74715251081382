import countries from '@assets/countries.json';
import { ChangeEvent, useEffect, useState } from 'react';
import { IInput, Input } from './Input';
import { Select } from './Select';

interface PhoneInputProps extends IInput {
  onAutoSave?: (phone: string) => void;
}

export const PhoneInput = (inputProps: PhoneInputProps) => {
  const [error, setError] = useState<string | undefined>();
  const [localValue, setLocalValue] = useState<string>('');
  const [selectedPrefix, setSelectedPrefix] = useState<string>('+31');

  // Initialize values from props
  useEffect(() => {
    if (inputProps.value) {
      const value = inputProps.value.toString();
      // Only update prefix if the value actually contains a different prefix
      const newPrefix = countries.find(country =>
        value.startsWith(country.dial_code)
      )?.dial_code;

      if (newPrefix) {
        setSelectedPrefix(newPrefix);
        // Remove the prefix from the local value
        setLocalValue(value.replace(newPrefix, '').trim());
      } else {
        // If no prefix is found in the value, just update the local value
        setLocalValue(value.replace(selectedPrefix, '').trim());
      }
    } else {
      setLocalValue('');
    }
  }, [inputProps.value, selectedPrefix]);

  // Handle default value
  useEffect(() => {
    if (inputProps.defaultValue) {
      const value = inputProps.defaultValue.toString();
      const prefix =
        countries.find(country => value.startsWith(country.dial_code))
          ?.dial_code || '+31';

      setSelectedPrefix(prefix);
      setLocalValue(value.replace(prefix, '').trim());
    }
  }, [inputProps.defaultValue]);

  const validatePhone = (prefix: string, number: string) => {
    // Allow empty unless required
    if (!number && !inputProps.required) return true;

    // Remove spaces and special characters
    const fullNumber = `${prefix}${number}`.replace(/\s+/g, '');
    // Basic international phone number validation
    // Allows for country code + 6-15 digits
    const phoneRegex = /^\+\d{1,4}\d{6,15}$/;
    return phoneRegex.test(fullNumber);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = e.target.value.replace(/[^\d\s]/g, ''); // Only allow digits and spaces
    setLocalValue(newValue);

    const isValid = validatePhone(selectedPrefix, newValue);
    setError(
      isValid ? undefined : 'Please enter a valid international phone number'
    );

    // Call parent onChange with the full number (prefix + local value)
    if (inputProps.onChange || inputProps.onAutoSave) {
      const fullNumber = `${selectedPrefix} ${newValue}`.trim();
      const syntheticEvent = {
        ...e,
        target: {
          ...e.target,
          value: isValid ? fullNumber : e.target.value
        }
      };
      inputProps.onChange?.(syntheticEvent);
      inputProps.onAutoSave?.(fullNumber);
    }
  };

  const handlePrefixChange = (newPrefix: string | number | undefined) => {
    if (!newPrefix) return;
    console.log('handlePrefixChange', newPrefix);
    newPrefix = newPrefix?.toString();
    setSelectedPrefix(newPrefix);

    // Validate and update with new prefix
    const isValid = validatePhone(newPrefix, localValue);
    setError(
      isValid ? undefined : 'Please enter a valid international phone number'
    );

    // Always call parent onChange with the new full number
    const fullNumber = `${newPrefix} ${localValue}`.trim();
    if (inputProps.onChange || inputProps.onBlur) {
      const syntheticEvent = {
        target: {
          value: fullNumber,
          name: inputProps.name // Preserve the input name if present
        }
      } as ChangeEvent<HTMLInputElement>;
      inputProps.onChange?.(syntheticEvent);
    }
    inputProps.onAutoSave?.(fullNumber);
  };

  return (
    <div className="flex">
      <Select
        options={countries.map(country => ({
          label: country.dial_code,
          value: country.dial_code,
          icon: country.flag
        }))}
        inputProps={{
          className: 'rounded-r-none max-w-[120px] min-w-[100px] !pl-[30px]'
        }}
        value={selectedPrefix}
        onChange={handlePrefixChange}
      />
      <Input
        {...inputProps}
        className={`${inputProps.className} rounded-l-none`}
        value={localValue}
        onChange={handleChange}
        error={error || inputProps.error}
      />
    </div>
  );
};
