import { KoraApi } from '@services/api';
import { useState } from 'react';

export const useVerifyResume = () => {
  const [isLoading, setIsLoading] = useState(false);

  const verify = async () => {
    setIsLoading(true);
    await KoraApi.post('/candidate/resume/verify', {});
    setIsLoading(false);
  };

  return { verify, isLoading };
};
