import { KoraApi } from '@services/api';
import { useMemo, useState } from 'react';

interface VacancySource {
  file?: File;
  url?: string;
  chat?: boolean;
}

export const useSetVacancySource = (id: string) => {
  const [isLoading, setIsLoading] = useState(false);

  const setVacancySource = async (vacancySource: VacancySource) => {
    setIsLoading(true);
    if (vacancySource.file) {
      const formData = new FormData();
      formData.append('file', vacancySource.file);
      formData.append('source', 'UPLOAD');
      await KoraApi.put(`/vacancies/${id}/source`, formData);
    }

    if (vacancySource.url) {
      const formData = new FormData();
      formData.append('url', vacancySource.url);
      formData.append('source', 'URL');
      await KoraApi.put(`/vacancies/${id}/source`, formData);
    }

    if (vacancySource.chat) {
      const formData = new FormData();
      formData.append('source', 'CHAT');
      await KoraApi.put(`/vacancies/${id}/source`, formData);
    }
    setIsLoading(false);
  };

  return useMemo(() => ({ setVacancySource, isLoading }), [isLoading]);
};
