import { Vacancy } from '@models/Vacancy';
import useSWR from 'swr';

export const useEmployerVacancies = (args?: {
  employerId?: string;
  page?: number;
  pageSize?: number;
  onlyWithMatches?: boolean;
}) => {
  let url = args?.employerId
    ? `/public/employer/${args.employerId}/vacancies?`
    : '/vacancies?';
  if (args) {
    args.page && (url += `page=${args.page}&`);
    args.pageSize && (url += `pageSize=${args.pageSize}&`);
    args.onlyWithMatches && (url += `withMatches=${args.onlyWithMatches}`);
  }

  const { data, ...swrProps } = useSWR(url);
  if (!data) return { vacancies: [], ...swrProps };

  const vacancies: Vacancy[] = data?.map(Vacancy.fromJson) ?? [];
  return {
    vacancies,
    ...swrProps
  };
};
