import { Address } from './address';
export interface EmployerWebsites {
  home?: string;
  aboutUs?: string;
  careers?: string;
  other?: string;
}
export enum CompanySize {
  STARTUP = 'STARTUP',
  SCALEUP = 'SCALEUP',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  ENTERPRISE = 'ENTERPRISE',
  MULTINATIONAL = 'MULTINATIONAL'
}
export interface CompanyMember {
  id: string;
  email: string;
  role: string;
  state: string;
}

export class Employer {
  constructor(
    public id: string,
    public companyName?: string,
    public summaryUrl?: string,
    public summaryDraftUrl?: string,
    public userDescription?: string,
    public industry?: string,
    public websites?: EmployerWebsites,
    public linkedinUrl?: string,
    public phone?: string,
    public intakeProgress?: number,
    public address: Address | null = null,
    public hasPendingJobs: boolean = false,
    public foundingDate?: Date,
    public totalEmployees?: number,
    public dutchEmployees?: number,
    public companySize?: CompanySize,
    public indRegistration?: boolean,
    public createdAt: Date = new Date(),
    public updatedAt: Date = new Date(),
    public members?: CompanyMember[],
    public logoUrl?: string,
    public bannerUrl?: string
  ) {}

  public static fromJson(json: any): Employer {
    return new Employer(
      json.id,
      json.companyName,
      json.summaryUrl,
      json.summaryDraftUrl,
      json.userDescription,
      json.industry,
      json.website,
      json.linkedinUrl,
      json.phone,
      json.intakeProgress,
      json.address ? Address.fromJson(json.address) : null,
      json.hasPendingJobs,
      json.foundingDate ? new Date(json.foundingDate) : undefined,
      json.totalEmployees,
      json.dutchEmployees,
      json.companySize,
      json.indRegistration,
      new Date(json.createdAt),
      new Date(json.updatedAt),
      json.members,
      json.logoUrl,
      json.bannerUrl
    );
  }
}
