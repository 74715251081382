import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { MarkdownEditor } from '@elements/MarkdownEditor';
import { MarkdownViewer } from '@elements/MarkdownViewer';
import { Typography } from '@elements/Typography';
import { Candidate } from '@models/candidate';
import { useAccountContext } from '@providers/UserContext';
import { useProfileSummaryUpdate } from '@services/account/use-profile-summary-update';
import { useCandidateSummaryGenerator } from '@services/candidate/use-candidate-summary-generator';
import { useMarkdownContent } from '@services/use-markdown-content';
import { Colors } from '@utils/ColorUtils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
export const CandidateIntakeSummary = () => {
  const { isGenerating, generate } = useCandidateSummaryGenerator();
  const { user } = useAccountContext<Candidate>();
  const { t } = useTranslation('candidate_intake', {
    keyPrefix: 'candidate_intake_summary'
  });

  const { markdownContent: fetchedMarkdownContent, refetch } =
    useMarkdownContent(user?.accountInfo.summaryDraftUrl ?? '');

  const [markdownContent, setMarkdownContent] = useState(
    fetchedMarkdownContent
  );
  const [editorState, setEditorState] = useState(fetchedMarkdownContent);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isGenerating && !user?.accountInfo.summaryDraftUrl) {
      generate();
    }
  }, [isGenerating, user?.accountInfo.summaryDraftUrl]);

  useEffect(() => {
    if (fetchedMarkdownContent) {
      setEditorState(fetchedMarkdownContent);
      setMarkdownContent(fetchedMarkdownContent);
    }
  }, [fetchedMarkdownContent]);

  const header = () => {
    if (isGenerating) {
      return (
        <Card className="!p-6">
          <Typography
            variant="h4"
            color="dark"
            className="text-center text-base"
          >
            {t('loading_title')}
          </Typography>
          <div className="mt-2 flex justify-center">
            <ClipLoader color={Colors.secondary} size={20} />
          </div>
        </Card>
      );
    } else {
      return (
        <Card className="!p-6">
          <Typography variant="h4" color="dark" className="text-base">
            {t('title')}
          </Typography>
          <Typography variant="body1" color="dark" className="text-sm">
            {' '}
            {t('description')}
          </Typography>
          <div className="mt-4 flex gap-4">
            <Button
              variant="info"
              size="sm"
              onClick={() => {
                generate();
              }}
            >
              {t('generate_again')}
            </Button>
          </div>
        </Card>
      );
    }
  };

  return (
    <>
      {header()}
      {!isGenerating && (
        <Card className="mt-4 !p-6">
          <div className="w-full">
            <Typography variant="h2" className="mb-4 text-2xl text-secondary">
              {t('summary_of', { name: user?.firstName })}
            </Typography>
            {isEditing ? (
              <MarkdownEditor
                markdown={editorState}
                onChange={setEditorState}
              />
            ) : (
              <MarkdownViewer content={markdownContent} />
            )}
            <div className="mt-4 flex justify-end gap-2">
              {isEditing ? (
                <>
                  <Button onClick={() => setIsEditing(false)} size="sm">
                    {t('cancel_editing')}
                  </Button>
                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={async () => {
                      setMarkdownContent(editorState);
                      setIsEditing(false);
                    }}
                  >
                    {t('save_description')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => setIsEditing(true)}
                    variant="info"
                    size="sm"
                  >
                    {t('edit_description')}
                  </Button>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={async () => {
                      setIsSaving(true);
                      await useProfileSummaryUpdate({
                        summary: editorState
                      });
                      refetch();
                      setIsEditing(false);
                      setIsSaving(false);
                      navigate('/jobs');
                    }}
                  >
                    {isSaving ? (
                      <ClipLoader color="dark" size={12} className="ml-2" />
                    ) : (
                      t('search_relevant_jobs')
                    )}
                  </Button>
                </>
              )}
            </div>
          </div>
        </Card>
      )}
    </>
  );
};
