import { LocationPin } from '@icons/index';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlaceInput } from './PlaceInput';
import { Select } from './Select';

const distanceOptions = [
  { label: '< 10 km', value: 10 },
  { label: '< 20 km', value: 20 },
  { label: '< 50 km', value: 50 },
  { label: '< 100 km', value: 100 }
];
export interface LocationFilterProps {
  onChange: ({ lat, lng, distance }: LocationFilterSelection) => void;
}

interface LocationFilterSelectionDraft {
  lat?: number;
  lng?: number;
  distance?: number;
}

export interface LocationFilterSelection extends LocationFilterSelectionDraft {
  lat: number;
  lng: number;
  distance: number;
}

export const LocationFilter = ({ onChange }: LocationFilterProps) => {
  const { t } = useTranslation('common');
  const [selection, setSelection] = useState<
    LocationFilterSelectionDraft | undefined
  >({ distance: distanceOptions[0].value });

  const handleChange = ({
    lat: _lat,
    lng: _lng,
    distance: _distance
  }: LocationFilterSelectionDraft) => {
    const lat = _lat ?? selection?.lat;
    const lng = _lng ?? selection?.lng;
    const distance = _distance ?? selection?.distance;

    // Update state
    setSelection({ lat, lng, distance });

    if (lat && lng && distance) {
      onChange({ lat, lng, distance });
    }
  };

  return (
    <div className="flex max-w-[400px]">
      <PlaceInput
        inputProps={{
          variant: 'compact',
          label: t('location'),
          startAdornment: <LocationPin />,
          className: 'rounded-r-none'
        }}
        onSelect={value => {
          handleChange({ lat: value.latitude, lng: value.longitude });
        }}
      />
      <Select
        disableSearch={true}
        inputProps={{
          className: 'rounded-l-none !bg-white max-w-[150px]'
        }}
        options={distanceOptions}
        value={selection?.distance ?? distanceOptions[0].value}
        onChange={value => {
          handleChange({ distance: Number(value) });
        }}
      />
    </div>
  );
};
