import { ChangeEvent, useEffect, useState } from 'react';
import { IInput, Input } from './Input';

export const UrlInput = (inputProps: IInput) => {
  const [error, setError] = useState<string | undefined>();
  const [localValue, setLocalValue] = useState<string>(
    (inputProps.value as string) || ''
  );

  const validateUrl = (value: string) => {
    // Allow empty value unless required
    if (!value) return true;

    try {
      // Create URL object to validate
      new URL(value);
      // Additional check for common protocols
      return value.startsWith('http://') || value.startsWith('https://');
    } catch {
      return false;
    }
  };

  useEffect(() => {
    setLocalValue((inputProps.value as string) || '');
  }, [inputProps.value]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = e.target.value;
    setLocalValue(newValue);

    const isValid = validateUrl(newValue);
    setError(
      isValid
        ? undefined
        : 'Please enter a valid URL (must start with http:// or https://)'
    );

    // Only call parent onChange if the URL is valid or empty
    if (isValid && inputProps.onChange) {
      inputProps.onChange(e);
    }
  };

  return (
    <Input
      {...inputProps}
      value={localValue}
      onChange={handleChange}
      error={error || inputProps.error}
      placeholder={inputProps.placeholder}
      type="url"
    />
  );
};
