export enum EducationType {
  HIGH_SCHOOL_VMBO = 'HIGH_SCHOOL_VMBO',
  HIGH_SCHOOL_HAVO = 'HIGH_SCHOOL_HAVO',
  HIGH_SCHOOL_VWO = 'HIGH_SCHOOL_VWO',
  BACHELOR_MBO = 'BACHELOR_MBO',
  BACHELOR_HBO = 'BACHELOR_HBO',
  BACHELOR_WO = 'BACHELOR_WO',
  MASTER_HBO = 'MASTER_HBO',
  MASTER_WO = 'MASTER_WO',
  HIGH_SCHOOL = 'HIGH_SCHOOL',
  BACHELORS = 'BACHELORS',
  MASTERS = 'MASTERS',
  PHD = 'PHD',
  CERTIFICATE = 'CERTIFICATE',
  OTHER = 'OTHER'
}

export class Education {
  constructor(
    public id?: string,
    public educationType?: EducationType,
    public title?: string,
    public description?: string,
    public startDate?: Date,
    public endDate?: Date | null,
    public completed?: boolean
  ) {}

  public static fromJson(json: any): Education {
    return new Education(
      json.id,
      json.educationType,
      json.title,
      json.description,
      json.startDate ? new Date(json.startDate) : undefined,
      json.endDate ? new Date(json.endDate) : null,
      json.completed
    );
  }

  public isValid(): boolean {
    return Boolean(this.educationType && this.title && this.startDate);
  }
}
