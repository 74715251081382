import { Typography } from '@elements/Typography';
import { LocationPin, MailIcon } from '@icons/index';
import { AccountType } from '@models/UserAccount';
import { useAccountContext } from '@providers/UserContext';
import { Colors } from '@utils/ColorUtils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
export const Footer = () => {
  const { t } = useTranslation('footer');
  const { t: tHeader } = useTranslation('header');

  const { isLoggedIn, user } = useAccountContext();
  return (
    <div className="flex w-full rounded-[20px_20px_0px_0px] !bg-[#F8F9FF] p-[24px_40px]">
      <div
        className={`grid w-full grid-cols-1 gap-4 ${
          isLoggedIn ? 'md:grid-cols-5' : 'md:grid-cols-4'
        }`}
      >
        <div></div>
        {isLoggedIn && (
          <div className="flex justify-center">
            <div className="mb-8">
              <Typography
                variant="h3"
                color="dark"
                className="mb-4 !text-lg md:mb-8"
              >
                {t('menu')}
              </Typography>

              {user?.accountType === AccountType.CANDIDATE && (
                <div className="flex flex-col gap-3">
                  <Link to="/jobs" className="no-underline">
                    <Typography
                      variant="body1"
                      color="primary"
                      className="!text-sm font-semibold"
                    >
                      {tHeader('recommended_jobs')}
                    </Typography>
                  </Link>
                  <Link to="/funnel" className="no-underline">
                    <Typography
                      variant="body1"
                      color="primary"
                      className="!text-sm font-semibold"
                    >
                      {tHeader('funnel')}
                    </Typography>
                  </Link>
                </div>
              )}
              {user?.accountType === AccountType.EMPLOYER && (
                <div className="flex flex-col gap-3">
                  <Link to="/funnel" className="no-underline">
                    <Typography
                      variant="body1"
                      color="primary"
                      className="!text-sm font-semibold"
                    >
                      {tHeader('employer.hiring_funnel')}
                    </Typography>
                  </Link>
                  <Link to="/vacancies" className="no-underline">
                    <Typography
                      variant="body1"
                      color="primary"
                      className="!text-sm font-semibold"
                    >
                      {tHeader('employer.vacancies')}
                    </Typography>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="flex justify-center">
          <div className="mb-8">
            <Typography
              variant="h3"
              color="dark"
              className="mb-4 !text-lg md:mb-8"
            >
              {t('interesting_links')}
            </Typography>
            <div className="flex flex-col gap-3">
              <Link to="/terms-and-conditions" className="no-underline">
                <Typography
                  variant="body1"
                  color="primary"
                  className="!text-sm font-semibold"
                >
                  {t('terms_and_conditions')}
                </Typography>
              </Link>
              <Link to="/privacy" className="no-underline">
                <Typography
                  variant="body1"
                  color="primary"
                  className="!text-sm font-semibold"
                >
                  {t('privacy_policy')}
                </Typography>
              </Link>
              <Link to="/cookie-policy" className="no-underline">
                <Typography
                  variant="body1"
                  color="primary"
                  className="!text-sm font-semibold"
                >
                  {t('cookie_policy')}
                </Typography>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="mb-8">
            <Typography
              variant="h3"
              color="dark"
              className="mb-4 !text-lg md:mb-8"
            >
              {t('contact')}
            </Typography>
            <div className="mb-4 flex gap-2">
              <div className="flex w-[24px]">
                <LocationPin
                  fill={Colors.primary}
                  className="mt-1 text-primary"
                  width={20}
                  height={20}
                />
              </div>
              <Typography variant="body1" color="primary" className="!text-sm">
                Harry Banninkstraat 51 <br />
                1012 EH Amsterdam
              </Typography>
            </div>
            <Link to="mailto:hello@workwithkora.com" className="no-underline">
              <div className="flex items-center gap-2">
                <div className="flex w-[24px]">
                  <MailIcon
                    fill={Colors.primary}
                    className="text-primary"
                    width={16}
                    height={16}
                  />
                </div>
                <Typography
                  variant="body1"
                  color="primary"
                  className="!text-sm"
                >
                  hello@workwithkora.com
                </Typography>
              </div>
            </Link>
          </div>
        </div>
        <div className="mb-8 flex flex-col justify-end gap-4">
          {/* <img src={logo} alt="Kora" className="mb-8 w-[100px]" /> */}
        </div>
      </div>
    </div>
  );
};
