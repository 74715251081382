import { CandidateIntakeProfileInformation } from '@components/CandidateIntake';
import { CandidateIntakeChat } from '@components/CandidateIntake/CandidateIntakeChat';
import { CandidateIntakeSummary } from '@components/CandidateIntake/CandidateIntakeSummary';
import { ResumeIntake } from '@components/CandidateIntake/ResumeIntake';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { MetaTags } from '@elements/MetaTags';
import Wizard, { WizardStep } from '@elements/Wizard';
import { Candidate } from '@models/candidate';
import { Status } from '@models/Status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

export const CandidateIntakePage = () => {
  const { t } = useTranslation('candidate_intake');
  const { user } = useAccountContext<Candidate>();
  const [currentStep, setCurrentStep] = useState(1);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) return;
    let _step = 1;
    const fields = { ...user?.accountInfo, ...user };
    if (fields) {
      const {
        firstName,
        lastName,
        phone,
        birthDate,
        address,
        resume,
        countryCode,
        summaryDraftUrl
      } = fields;
      const reachedStep2 =
        firstName && lastName && phone && birthDate && address && countryCode;
      const reachedStep3 =
        resume && resume.parsedFilePath && resume.status === Status.VERIFIED;
      const reachedStep4 = summaryDraftUrl;

      if (reachedStep2) {
        _step = 2;
      }
      if (reachedStep2 && reachedStep3) {
        _step = 3;
      }
      if (reachedStep2 && reachedStep3 && reachedStep4) {
        _step = 4;
      }
    }
    setCurrentStep(_step);
    setInitialized(true);
  }, [user]);

  return (
    <ContainerWithBackButton>
      <MetaTags title={t('page_title')} />
      <div className="flex h-[calc(100vh-200px)] w-full flex-col">
        <Wizard
          activeStep={currentStep - 1}
          onStepChange={step => setCurrentStep(step + 1)}
        >
          <WizardStep title={t('progress_bar.step_1')}>
            <CandidateIntakeProfileInformation
              onNext={() => setCurrentStep(2)}
            />
          </WizardStep>
          <WizardStep title={t('progress_bar.step_2')}>
            <ResumeIntake onNext={() => setCurrentStep(3)} />
          </WizardStep>
          <WizardStep title={t('progress_bar.step_3')}>
            <CandidateIntakeChat onNext={() => setCurrentStep(4)} />
          </WizardStep>
          <WizardStep title={t('progress_bar.step_4')}>
            <CandidateIntakeSummary />
          </WizardStep>
        </Wizard>
      </div>
    </ContainerWithBackButton>
  );
};
