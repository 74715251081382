import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';

interface BackgroundContextType {
  setBackgroundEnabled: (enabled: boolean) => void;
}

const BackgroundContext = createContext<BackgroundContextType | undefined>(
  undefined
);

interface BackgroundProviderProps {
  children: ReactNode;
}

export const BackgroundProvider = ({ children }: BackgroundProviderProps) => {
  const [isEnabled, setIsEnabled] = useState(true);
  const location = useLocation();

  // Reset background when route changes
  useEffect(() => {
    setIsEnabled(true);
  }, [location.pathname]);

  return (
    <BackgroundContext.Provider value={{ setBackgroundEnabled: setIsEnabled }}>
      {children}
      {isEnabled && (
        <div className="absolute inset-0 -z-20">
          <div
            className="absolute left-0 top-0 h-full w-full"
            style={{
              background: 'linear-gradient(180deg, #ECECE3 0%, #F8F9FF 100%)'
            }}
          ></div>
        </div>
      )}
    </BackgroundContext.Provider>
  );
};

export const useBackground = (enabled: boolean) => {
  const context = useContext(BackgroundContext);
  if (!context) {
    throw new Error('useBackground must be used within a BackgroundProvider');
  }

  useEffect(() => {
    context.setBackgroundEnabled(enabled);
  }, [enabled, context]);
};
