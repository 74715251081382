import { useToast } from '@elements/Toast';
import { Candidate } from '@models/candidate';
import { Skill } from '@models/Skill';
import { Status } from '@models/Status';
import { useAccountContext } from '@providers/UserContext';
import { KoraApi } from '@services/api';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { v4 as uuidv4 } from 'uuid';
export const useCandidateSkills = () => {
  const { data, ...swr } = useSWR('/candidate/skills');

  const skills: Skill[] = data?.map((skill: any) => {
    return Skill.fromJson(skill);
  });

  return { skills, ...swr };
};

export const useUpdateCandidateSkills = () => {
  const { mutate, skills, ...swr } = useCandidateSkills();
  const { user } = useAccountContext<Candidate>();
  const [isSaving, setIsSaving] = useState(false);
  const { error: errorToast } = useToast();
  const { t } = useTranslation('candidate_intake', {
    keyPrefix: 'resume_intake.job_experience_editor'
  });
  const [isMounted, setIsMounted] = useState(false);
  const [changedSkills, setChangedSkills] = useState<Skill[]>([]);

  useEffect(() => {
    if (!isMounted && skills && skills.length > 0) {
      setChangedSkills(skills);
      setIsMounted(true);
    }
  }, [skills]);

  useEffect(() => {
    const status = user?.accountInfo?.resume?.status;
    if (status === Status.VERIFIED || status === Status.DONE) {
      setIsMounted(false);
      mutate();
    }
  }, [user]);

  const enhancedMutate = () => {
    setIsMounted(false);
    mutate();
  };

  const isValid = useMemo(() => {
    return changedSkills?.every(skill => {
      return skill.isValid();
    });
  }, [changedSkills]);

  const pushChanges = async () => {
    setIsSaving(true);
    try {
      await KoraApi.put('/candidate/skills', sanitize());
      setIsSaving(false);
      enhancedMutate();
    } catch (error) {
      setIsSaving(false);
      errorToast(t('error'));
      throw error;
    }
  };

  const sanitize = () => {
    const _skills = changedSkills.map(skill => {
      if (skill.id?.startsWith('temp-')) {
        skill.id = undefined;
      }
      return skill;
    });
    return _skills;
  };

  const onChange = (skill: Skill) => {
    const index = changedSkills.findIndex(s => s.id === skill.id);
    if (index !== -1) {
      const updated = [...changedSkills];
      updated[index] = skill;
      setChangedSkills(updated);
    }
  };

  const deleteSkill = (skill: Skill) => {
    const index = changedSkills.findIndex(s => s.id === skill.id);
    if (index !== -1) {
      const updated = [...changedSkills];
      updated.splice(index, 1);
      setChangedSkills(updated);
    }
  };
  const addSkill = () => {
    const newSkill = new Skill(`temp-${uuidv4()}`);
    setChangedSkills([...changedSkills, newSkill]);
  };

  const memoizedSkills = useMemo(() => {
    return changedSkills;
  }, [changedSkills]);

  return {
    pushChanges,
    mutate: enhancedMutate,
    skills: memoizedSkills,
    onChange,
    deleteSkill,
    addSkill,
    isSaving,
    isValid,
    ...swr
  };
};
