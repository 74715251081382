import { Header } from '@components/header';
import useIsMobile from '@hooks/is-mobile';
import * as Sentry from '@sentry/react';
import './App.css';
import { CookieConsent } from './components/CookieConsent';
import { Footer } from './components/footer';
import './i18n';
import { BackgroundProvider } from './providers/BackgroundProvider';
import { KoraRoutes } from './routes';
function App() {
  const isMobile = useIsMobile();
  return (
    <div className="flex h-[100vh] flex-col overflow-y-auto">
      <Header />
      <div className={`relative mt-[72px] flex w-full flex-1`}>
        <BackgroundProvider>
          <KoraRoutes />
        </BackgroundProvider>
      </div>
      <CookieConsent />
      <Footer />
    </div>
  );
}

export default Sentry.withProfiler(App);
