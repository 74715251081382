import { useToast } from '@elements/Toast';
import { Address } from '@models/address';
import { Candidate } from '@models/candidate';
import { KoraApi, objectToFormData } from '@services/api';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

interface CandidateInfo {
  email?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: Date;
  description?: string;
  phone?: string;
  address?: Address;
  profilePicture?: File;
  linkedinUrl?: string;
  countryCode?: string;
  indRegistered?: boolean;
}

export const useCandidatePatch = () => {
  const { user } = useAccountContext<Candidate>();
  const { t } = useTranslation('profile');
  const { error } = useToast();
  const [isSaving, setIsSaving] = useState(false);

  const canSave = (info: CandidateInfo) => {
    return (
      info.email !== user?.email ||
      info.firstName !== user?.firstName ||
      info.lastName !== user?.lastName ||
      info.birthDate?.toDateString() !==
        user?.accountInfo?.birthDate?.toDateString() ||
      info.address !== user?.accountInfo?.address ||
      info.phone !== user?.accountInfo?.phone ||
      info.profilePicture
    );
  };

  const patch = async (overrides?: CandidateInfo) => {
    setIsSaving(true);
    if (!overrides && !canSave) return;
    const _info = overrides ?? {};
    try {
      const jsonData = {
        ..._info,
        birthDate: _info.birthDate?.toISOString()
      };
      const formData = objectToFormData(jsonData);

      await KoraApi.patch('/account', formData);
    } catch (e) {
      error(t('update_failed'));
      throw e;
    }
    setIsSaving(false);
  };

  return useMemo(() => {
    return { patch, isSaving };
  }, [isSaving, user]);
};
