import { Employer } from '@models/Employer';
import useSWR from 'swr';

export const useEmployers = () => {
  const { data, isLoading, error } = useSWR('/public/employer');
  const employers: Employer[] = data?.map((employer: any) =>
    Employer.fromJson(employer)
  );
  return { employers, isLoading, error };
};
