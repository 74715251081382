import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Divider } from '@elements/Divider';
import { PercentageLabel } from '@elements/PercentageLabel';
import { Typography } from '@elements/Typography';
import { Match, MatchingStatus } from '@models/Match';
import { usePatchEmployerMatch } from '@services/employer/use-patch-employer-match';
import { formatDate } from '@utils/DateUtils';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

export interface EmployerFunnelCardProps {
  match: Match;
}

export const EmployerFunnelCard = ({ match }: EmployerFunnelCardProps) => {
  const { t } = useTranslation('funnel');
  const { vacancy, candidate } = match;
  const { patch, patchWithConfirm } = usePatchEmployerMatch(match.id);
  const navigate = useNavigate();

  const CTA = () => {
    return (
      <div className="mt-6 flex flex-col gap-4">
        {(match.status === MatchingStatus.REJECTED ||
          match.status === MatchingStatus.DECLINED) && (
          <Button
            variant="info"
            size="sm"
            className="w-full"
            onClick={async e => {
              e.preventDefault();
              e.stopPropagation();

              await patch({ archived: true });
              navigate('/funnel?tab=ARCHIVE');
            }}
          >
            {t('archive')}
          </Button>
        )}
        {match.status === MatchingStatus.APPLIED && (
          <>
            <Button
              variant="primary"
              size="sm"
              className="w-full"
              onClick={() => navigate(`/matches/${match.id}/invite`)}
            >
              {t('invite')}
            </Button>
            <Button
              variant="info"
              size="sm"
              className="w-full"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                patchWithConfirm({
                  status: MatchingStatus.REJECTED,
                  title: t('reject_confirm_title'),
                  description: t('reject_confirm_description', {
                    name: match?.candidate?.firstName
                  }),
                  onSuccessMessage: t('reject_success')
                });
              }}
            >
              {t('reject')}
            </Button>
          </>
        )}
        {match.status === MatchingStatus.INVITED && (
          <>
            <Button
              variant="info"
              size="sm"
              className="w-full"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                patchWithConfirm({
                  status: MatchingStatus.INTERVIEW,
                  onSuccessMessage: t('accepted_invite'),
                  title: t('accepted_invite_confirm_title'),
                  description: t('accepted_invite_confirm_description', {
                    candidateName: match?.candidate?.firstName
                  })
                });
              }}
            >
              {t('accepted_invite')}
            </Button>
            <Button
              variant="info"
              size="sm"
              className="w-full"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                patchWithConfirm({
                  status: MatchingStatus.REJECTED,
                  title: t('reject_confirm_title'),
                  description: t('reject_confirm_description', {
                    name: match?.candidate?.firstName
                  }),
                  onSuccessMessage: t('reject_success')
                });
              }}
            >
              {t('declined_invite')}
            </Button>
          </>
        )}

        {match.status === MatchingStatus.INTERVIEW && (
          <>
            <Button
              variant="info"
              size="sm"
              className="w-full"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                patchWithConfirm({
                  status: MatchingStatus.JOB_TAKEN,
                  onSuccessMessage: t('accepted_offer'),
                  title: t('accept_offer_confirm_title'),
                  description: t('accept_offer_confirm_description', {
                    candidateName: match?.candidate?.firstName
                  })
                });
              }}
            >
              {t('accepted_offer')}
            </Button>
            <Button
              variant="info"
              size="sm"
              className="w-full"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                patchWithConfirm({
                  status: MatchingStatus.REJECTED,
                  title: t('reject_confirm_title'),
                  description: t('reject_confirm_description', {
                    name: match?.candidate?.firstName
                  }),
                  onSuccessMessage: t('reject_success')
                });
              }}
            >
              {t('didnt_go_for_it')}
            </Button>
          </>
        )}
      </div>
    );
  };

  return (
    <Link
      to={`/matches/${match.id}`}
      className="no-underline hover:animate-raise"
    >
      <Card className="!p-[24px_16px]">
        <div className="flex flex-col">
          <Typography variant="h5" className="mb-4 line-clamp-2 text-base">
            {vacancy.title}
          </Typography>
          <Typography variant="body1" className="mb-4">
            {candidate?.firstName} {candidate?.lastName}
          </Typography>
          <PercentageLabel percentage={match.percentage} />
          <Divider orientation="horizontal" className="my-4" />
          <Typography variant="body1" className="text-sm text-light">
            {formatDate(match.updatedAt)}
          </Typography>
        </div>
        {match.archived ? (
          <div className="mt-6 flex flex-col gap-4">
            <Button
              variant="info"
              size="sm"
              className="w-full"
              onClick={async e => {
                e.preventDefault();
                e.stopPropagation();
                await patch({ archived: false });
                if (
                  match.status === MatchingStatus.REJECTED ||
                  match.status === MatchingStatus.DECLINED
                ) {
                  navigate('/funnel?tab=REJECTED');
                } else {
                  navigate('/funnel');
                }
              }}
            >
              {t('unarchive')}
            </Button>
          </div>
        ) : (
          <CTA />
        )}
      </Card>
    </Link>
  );
};
