import { ReactElement } from 'react';
import { Typography } from './Typography';

export interface ICheckbox extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactElement;
  className?: string;
  checked?: boolean;
  defaultChecked?: boolean; // Add defaultChecked for uncontrolled component
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // Modified `onChange` with a valid signature
}

export const Checkbox = ({
  label,
  className,
  checked,
  defaultChecked,
  onChange: propsOnChange,
  ...props
}: ICheckbox) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (propsOnChange) {
      propsOnChange(event);
    }
  };

  return (
    <label
      className="relative block cursor-pointer select-none pl-6 text-xs text-[#181818]"
      data-testid="checkbox-container"
    >
      <Typography variant="body1" className="text-sm">
        {label}
      </Typography>
      <input
        data-testid="checkbox"
        type="checkbox"
        checked={checked}
        defaultChecked={defaultChecked} // Handle uncontrolled checkbox case
        onChange={onChange} // React expects this when the `checked` prop is provided
        className="peer absolute h-0 w-0 cursor-pointer opacity-0"
        {...props}
      />
      <span className="bg-gray-200 peer-hover:bg-gray-300 absolute left-0 top-[2px] h-4 w-4 rounded-[4px] border peer-checked:bg-black"></span>
      <span className="absolute left-[3px] top-[0px] h-3 w-1 origin-bottom-left rotate-45 scale-0 transform border-b-2 border-r-2 border-white transition-transform duration-200 ease-in-out peer-checked:scale-100"></span>
    </label>
  );
};
