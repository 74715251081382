import PlaceholderCard from '@assets/placeholder-card.svg';
import { EmployerFunnelCard } from '@components/funnel/EmployerFunnelCard';
import { FunnelFilters } from '@components/funnel/FunnelFilters';
import { Container } from '@elements/Container';
import { PopupCTA } from '@elements/PopupCTA';
import { Tab, Tabs } from '@elements/Tabs';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { Employer } from '@models/Employer';
import { Match, MatchingStatus } from '@models/Match';
import { usePopup } from '@providers/PopupProvider';
import { useAccountContext } from '@providers/UserContext';
import { useEmployerFunnel } from '@services/employer/use-employer-funnel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EmployerFunnelPage = () => {
  const { t } = useTranslation('funnel');
  const { user } = useAccountContext<Employer>();

  const [search, setSearch] = useState<string | null>(null);
  const isMobile = useIsMobile();

  const { show } = usePopup();

  useEffect(() => {
    if (user?.accountInfo?.intakeProgress !== 100) {
      show({
        body: (
          <PopupCTA
            title={t('intake_incomplete')}
            buttonText={t('go_to_intake')}
            link="/intake"
          />
        ),
        closeButton: false
      });
    }
  }, [user?.accountInfo?.intakeProgress]);

  const FunnelColumn = ({
    title,
    children,
    showDivider = true
  }: {
    title: string;
    children: React.ReactNode;
    showDivider?: boolean;
  }) => {
    return (
      <div
        className={`flex h-full flex-col gap-4 pr-3 ${
          showDivider ? 'border-r border-gray' : ''
        }`}
      >
        <Typography variant="h5" className="px-1 pb-5">
          {title}
        </Typography>
        <div className="flex flex-col gap-6">{children}</div>
      </div>
    );
  };

  const Body = ({ tab }: { tab: 'APPLIED' | 'REJECTED' | 'ARCHIVE' }) => {
    const [selectedVacancyId, setSelectedVacancyId] = useState<string | null>(
      null
    );
    const allowedStates:
      | (
          | MatchingStatus.APPLIED
          | MatchingStatus.INVITED
          | MatchingStatus.INTERVIEW
          | MatchingStatus.REJECTED
          | MatchingStatus.DECLINED
        )[]
      | undefined =
      tab === 'APPLIED'
        ? [
            MatchingStatus.APPLIED,
            MatchingStatus.INVITED,
            MatchingStatus.INTERVIEW
          ]
        : tab === 'REJECTED'
          ? [MatchingStatus.REJECTED, MatchingStatus.DECLINED]
          : undefined;

    const { funnel, isLoading } = useEmployerFunnel({
      vacancyFilter: selectedVacancyId ? [selectedVacancyId] : undefined,
      search: search ?? undefined,
      stateFilter: allowedStates,
      archived: tab === 'ARCHIVE'
    });
    const hasItems = isLoading || funnel.total > 0;

    if (tab === 'ARCHIVE') {
      const archivedMatches: Match[] = [];
      for (const status in funnel) {
        const items = funnel[status as keyof typeof funnel];
        if (Array.isArray(items)) {
          archivedMatches.push(...items);
        }
      }
      return (
        <>
          <FunnelFilters
            selectedVacancyId={selectedVacancyId}
            onVacancyFilterChange={setSelectedVacancyId}
            onSearchChange={setSearch}
          />
          <div className="mt-10 grid h-[90%] grid-cols-1 gap-4 md:grid-cols-3">
            {archivedMatches.map(match => (
              <EmployerFunnelCard match={match} key={match.id} />
            ))}
          </div>
        </>
      );
    }

    return (
      <>
        <FunnelFilters
          selectedVacancyId={selectedVacancyId}
          onVacancyFilterChange={setSelectedVacancyId}
          onSearchChange={setSearch}
        />
        <div className="mt-10 grid h-[90%] grid-cols-1 gap-4 md:grid-cols-3">
          {allowedStates?.map((status, index) => (
            <FunnelColumn
              key={`${index}-${status}`}
              title={t(status.toLowerCase())}
              showDivider={index != allowedStates.length - 1}
            >
              {hasItems ? (
                funnel[status].map(match => (
                  <EmployerFunnelCard match={match} key={match.id} />
                ))
              ) : (
                <img src={PlaceholderCard} className="w-full" key={index} />
              )}
            </FunnelColumn>
          ))}
        </div>
      </>
    );
  };

  return (
    <Container className={`${!isMobile && 'py-10'} relative`}>
      <div className="flex flex-col justify-between gap-6 pb-4">
        <Typography variant="h1">{t('hiring_funnel')}</Typography>
      </div>

      <Tabs compact>
        <Tab title={t('applied')}>
          <Body tab={'APPLIED'} />
        </Tab>
        <Tab title={t('rejected')}>
          <Body tab={'REJECTED'} />
        </Tab>
        <Tab title={t('archive')}>
          <Body tab={'ARCHIVE'} />
        </Tab>
      </Tabs>
    </Container>
  );
};
