import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  MDXEditor,
  UndoRedo,
  headingsPlugin,
  listsPlugin,
  thematicBreakPlugin,
  toolbarPlugin
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';

interface MarkdownEditorProps {
  markdown: string;
  onChange: (markdown: string) => void;
  contentEditableClassName?: string;
}

export const MarkdownEditor = ({
  markdown,
  onChange,
  contentEditableClassName
}: MarkdownEditorProps) => {
  return (
    <div data-testid="markdown-editor">
      <MDXEditor
        markdown={markdown}
        onChange={onChange}
        className="rounded-[8px] border-[1px] border-solid border-[rgb(240,240,243)] bg-[rgba(240,240,243,0.3)]"
        contentEditableClassName={`
        markdown-editor
        p-3

        [&>*]:mb-4
        [&>*]:font-["Poppins"]

        [&_h1]:font-semibold
        [&_h1]:text-xl
        [&_h1]:text-secondary

        [&_h2]:font-semibold
        [&_h2]:text-lg
        [&_h2]:text-dark

        [&_h3]:font-semibold
        [&_h3]:text-base
        [&_h3]:text-dark

        [&_ul]:list-inside
        [&_ul]:list-disc
        [&_ul]:ml-2

        ${contentEditableClassName}
        `}
        plugins={[
          headingsPlugin({
            allowedHeadingLevels: [1, 2, 3]
          }),
          thematicBreakPlugin(),
          listsPlugin(),
          toolbarPlugin({
            toolbarContents: () => (
              <>
                <UndoRedo />
                <BlockTypeSelect />
                <BoldItalicUnderlineToggles />
              </>
            )
          })
        ]}
      />
    </div>
  );
};
