import { Card } from '@elements/Card';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { MarkdownViewer } from '@elements/MarkdownViewer';
import { Typography } from '@elements/Typography';
import { useMarkdownContent } from '@services/use-markdown-content';

export const CookiePolicyPage = () => {
  const { markdownContent } = useMarkdownContent('/policies/cookie.md');
  return (
    <ContainerWithBackButton className="flex flex-col gap-4 py-10">
      <Card>
        <MarkdownViewer
          content={markdownContent}
          components={{
            h1: ({ node, ...props }: any) => (
              <Typography
                variant="h3"
                className="mb-2 mt-3 text-center !text-2xl text-secondary"
                {...props}
              />
            ),
            h2: ({ node, ...props }: any) => (
              <Typography
                variant="h4"
                className="mb-3 mt-4 !text-xl text-primary"
                {...props}
              />
            )
          }}
          className="flex flex-col"
        />
      </Card>
    </ContainerWithBackButton>
  );
};
