import defaultBanner from '@assets/default-company-banner.png';
import { RoundedContainer } from '@components/RoundedContainer';
import { VacancyCard } from '@components/vacancies/VacancyCard';
import { Button } from '@elements/Button';
import { MarkdownEditor } from '@elements/MarkdownEditor';
import { MarkdownViewer } from '@elements/MarkdownViewer';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { Pencil } from '@icons/index';
import { Employer } from '@models/Employer';
import { useAccountContext } from '@providers/UserContext';
import { useProfileSummaryUpdate } from '@services/account/use-profile-summary-update';
import { useEmployerImageUpload } from '@services/employer/use-employer-image-upload';
import { useEmployerVacancies } from '@services/employer/use-employer-vacancies';
import { useMarkdownContent } from '@services/use-markdown-content';
import { Colors } from '@utils/ColorUtils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
export const CompanySummary = ({
  employer,
  hideVacancies = false,
  isDraft = false
}: {
  employer: Employer;
  hideVacancies?: boolean;
  isDraft?: boolean;
}) => {
  const { user, mutate: mutateUser } = useAccountContext<Employer>();
  const isEmployerAdmin = user?.accountInfo.id === employer.id;
  const { t } = useTranslation('company_detail');
  const isMobile = useIsMobile();
  const { vacancies } = useEmployerVacancies({
    employerId: employer.id
  });
  const { uploadImage } = useEmployerImageUpload(employer.id);
  const { markdownContent: about } = useMarkdownContent(
    isDraft ? (employer?.summaryDraftUrl ?? '') : (employer?.summaryUrl ?? ''),
    'about the company'
  );
  const { markdownContent: whatWeDo } = useMarkdownContent(
    isDraft ? (employer?.summaryDraftUrl ?? '') : (employer?.summaryUrl ?? ''),
    'what we do'
  );
  const { markdownContent: ourCulture } = useMarkdownContent(
    isDraft ? (employer?.summaryDraftUrl ?? '') : (employer?.summaryUrl ?? ''),
    'our culture'
  );

  const [bannerImage, setBannerImage] = useState<string | null>(
    employer?.bannerUrl ?? defaultBanner
  );
  const [logoImage, setLogoImage] = useState<string | null>(
    employer?.logoUrl ?? null
  );
  const bannerInputRef = useRef<HTMLInputElement | null>(null);
  const logoInputRef = useRef<HTMLInputElement | null>(null);

  const [bannerUploading, setBannerUploading] = useState(false);
  const [logoUploading, setLogoUploading] = useState(false);

  const handleSave = async (
    content: string,
    type: 'about' | 'whatWeDo' | 'ourCulture'
  ) => {
    let aboutContent = about;
    let whatWeDoContent = whatWeDo;
    let ourCultureContent = ourCulture;
    if (type === 'about') {
      aboutContent = content;
    } else if (type === 'whatWeDo') {
      whatWeDoContent = content;
    } else if (type === 'ourCulture') {
      ourCultureContent = content;
    }

    const summary = `## About the company\n${aboutContent}\n\n## What we do\n${whatWeDoContent}\n\n## Our culture\n${ourCultureContent}`;

    await useProfileSummaryUpdate({
      summary: summary
    });

    mutateUser();
  };

  const InfoCard = ({ children }: { children: React.ReactNode }) => {
    return (
      <div
        className={`flex items-center justify-center rounded-[16px_16px_4px_16px] bg-primary p-4 text-white ${
          isMobile ? 'h-[40px] w-full' : 'h-[100px] w-[20%]'
        }`}
      >
        {children}
      </div>
    );
  };
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: 'banner' | 'logo'
  ) => {
    if (type === 'banner') {
      setBannerUploading(true);
    } else {
      setLogoUploading(true);
    }
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        if (type === 'banner') {
          setBannerImage(reader.result as string);
        } else {
          setLogoImage(reader.result as string);
        }
      };
      reader.readAsDataURL(file);
    }

    if (file) {
      await uploadImage(file, type);
    }

    if (type === 'banner') {
      setBannerUploading(false);
    } else {
      setLogoUploading(false);
    }
  };

  return (
    <>
      {isEmployerAdmin && (
        <>
          <input
            type="file"
            ref={bannerInputRef}
            className="hidden"
            accept="image/*"
            onChange={event => handleFileChange(event, 'banner')}
          />
          <input
            type="file"
            ref={logoInputRef}
            className="hidden"
            accept="image/*"
            onChange={event => handleFileChange(event, 'logo')}
          />
        </>
      )}
      <div className="relative w-full">
        <img
          src={bannerImage ?? defaultBanner}
          alt={employer?.companyName}
          className="max-h-[250px] w-full rounded-[40px_40px_20px_40px] object-cover"
        />
        {isEmployerAdmin && (
          <div
            onClick={() => bannerInputRef.current?.click()}
            className="absolute inset-0 flex items-center justify-center rounded-[40px_40px_20px_40px] bg-black opacity-0 transition-opacity duration-300 hover:cursor-pointer hover:opacity-50"
          >
            <Pencil fill={Colors.white} width={32} height={32} />
          </div>
        )}
        {bannerUploading && (
          <div className="absolute inset-0 flex items-center justify-center rounded-[40px_40px_20px_40px] bg-black opacity-50">
            <ClipLoader color={Colors.white} size={32} />
          </div>
        )}
      </div>
      <div
        className={`flex w-full ${
          isMobile ? '' : 'mt-[-40px] justify-between px-10'
        } gap-2`}
      >
        <div className="flex flex-col items-center gap-4">
          <div
            className={`relative z-[1] flex h-[170px] w-[170px] items-center justify-center rounded-[16px] bg-white p-2`}
          >
            <img
              src={logoImage ?? undefined}
              alt={employer?.companyName}
              className="w-full"
            />
            {isEmployerAdmin && (
              <div
                onClick={() => logoInputRef.current?.click()}
                className="absolute inset-0 flex items-center justify-center rounded-[16px] bg-black opacity-0 transition-opacity duration-300 hover:cursor-pointer hover:opacity-50"
              >
                <Pencil fill={Colors.white} width={32} height={32} />
              </div>
            )}
            {logoUploading && (
              <div className="absolute inset-0 flex items-center justify-center rounded-[16px] bg-black opacity-50">
                <ClipLoader color={Colors.white} size={32} />
              </div>
            )}
          </div>
          <Typography variant="h1" color="dark" className="!text-2xl">
            {employer?.companyName}
          </Typography>
        </div>
        <div className="flex w-full flex-wrap items-center justify-end gap-2 md:gap-8">
          {employer?.industry && (
            <InfoCard>
              <Typography variant="body1">{employer?.industry}</Typography>
            </InfoCard>
          )}
          {employer?.totalEmployees ? (
            <InfoCard>
              <Typography variant="body1">
                {t('amount_employees', {
                  count: employer?.totalEmployees
                })}
              </Typography>
            </InfoCard>
          ) : (
            employer?.companySize !== undefined && (
              <InfoCard>
                <Typography variant="body1">
                  {t(`company_size.${employer?.companySize.toLowerCase()}`)}
                </Typography>
              </InfoCard>
            )
          )}
          {employer?.address?.city && (
            <InfoCard>
              <Typography variant="body1">{employer?.address?.city}</Typography>
            </InfoCard>
          )}
          {employer?.foundingDate && (
            <InfoCard>
              <Typography variant="body1">
                {t('founded_in', {
                  year: employer?.foundingDate.getFullYear()
                })}
              </Typography>
            </InfoCard>
          )}
        </div>
      </div>
      <div className="mt-4 flex w-full flex-col gap-6 px-1 md:gap-10 md:px-10">
        {about && (
          <CompanyDescriptionParagraph
            content={about}
            title={t('about_the_company')}
            onSave={content => handleSave(content, 'about')}
            isOwner={isEmployerAdmin}
          />
        )}
        {whatWeDo && (
          <CompanyDescriptionParagraph
            content={whatWeDo}
            title={t('what_we_do')}
            onSave={content => handleSave(content, 'whatWeDo')}
            isOwner={isEmployerAdmin}
          />
        )}
        {ourCulture && (
          <CompanyDescriptionParagraph
            content={ourCulture}
            title={t('our_culture')}
            onSave={content => handleSave(content, 'ourCulture')}
            isOwner={isEmployerAdmin}
          />
        )}
      </div>
      {!hideVacancies && (
        <div className="mt-4 flex w-full flex-col px-1 md:px-10">
          <Typography variant="h2" color="dark" className="mb-4 !text-xl">
            {t('vacancies_at', { companyName: employer?.companyName })}
          </Typography>
          <div className="grid grid-cols-3 gap-x-4">
            {vacancies.slice(0, 3).map(vacancy => (
              <VacancyCard
                key={vacancy.id}
                vacancy={vacancy}
                showPotentialCandidates={false}
                cta={
                  <Link to={`/vacancies/${vacancy.id}`} className="mt-4">
                    <Button variant="primary" size="sm">
                      {t('job_details')}
                    </Button>
                  </Link>
                }
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const CompanyDescriptionParagraph = ({
  content,
  title,
  isOwner = false,
  onSave
}: {
  content: string;
  title: string;
  isOwner?: boolean;
  onSave?: (content: string) => void;
}) => {
  const { t } = useTranslation('common');
  const [isEditing, setIsEditing] = useState(false);
  const [editorState, setEditorState] = useState(content);

  useEffect(() => {
    setEditorState(content);
  }, [content]);

  return (
    <RoundedContainer>
      <Typography variant="h2" color="dark" className="mb-3 !text-xl">
        {title}
      </Typography>
      {isEditing ? (
        <MarkdownEditor
          markdown={editorState}
          onChange={setEditorState}
          contentEditableClassName="[&_p]:text-sm "
        />
      ) : (
        <MarkdownViewer
          content={editorState}
          components={{
            p: ({ node, ...props }: any) => (
              <Typography
                variant="body2"
                className="mb-2 mt-3 !text-sm"
                {...props}
              />
            )
          }}
        />
      )}

      {isOwner && (
        <div className="mt-4 flex justify-end gap-2">
          {isEditing ? (
            <>
              <Button
                variant="info"
                size="sm"
                className="flex items-center gap-2"
                onClick={() => {
                  setIsEditing(false);
                  setEditorState(content);
                }}
              >
                {t('cancel')}
              </Button>
              <Button
                variant="secondary"
                size="sm"
                className="flex items-center gap-2"
                onClick={() => {
                  onSave?.(editorState);
                  setIsEditing(false);
                }}
              >
                {t('save')}
              </Button>
            </>
          ) : (
            <Button
              variant="info"
              size="sm"
              className="flex items-center gap-2"
              onClick={() => setIsEditing(true)}
            >
              <Pencil fill={Colors.black} width={16} height={16} />
              {t('edit')}
            </Button>
          )}
        </div>
      )}
    </RoundedContainer>
  );
};
