import { QueryParametersProvider } from '@providers/QueryParametersProvider.tsx';
import { SentryProvider } from '@providers/SentryProvider.tsx';
import { TranslationProvider } from '@providers/TranslationProvider.tsx';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/react';
import { koraFetcher } from '@services/api.ts';
import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SWRConfig } from 'swr';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import App from './App.tsx';
import i18n from './i18n';
import './index.css';
import { PopupProvider } from './providers/PopupProvider';
import { PreviousPathProvider } from './providers/PreviousPathProvider';
import { AccountContextProvider } from './providers/UserContext.tsx';
import { ValidationPopupProvider } from './providers/ValidationPopupProvider';

TagManager.initialize({ gtmId: import.meta.env.VITE_GOOGLE_TAG });
if (import.meta.env.VITE_ENV === 'production') {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration()
    ],
    tracesSampleRate: 1.0,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', import.meta.env.VITE_API_URL],
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <SentryProvider>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <SWRConfig value={{ fetcher: koraFetcher, revalidateOnFocus: false }}>
            <I18nextProvider i18n={i18n}>
              <TranslationProvider>
                <Suspense fallback="Loading...">
                  <GoogleOAuthProvider
                    clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID!}
                  >
                    <QueryParametersProvider>
                      <AccountContextProvider>
                        <PreviousPathProvider>
                          <ValidationPopupProvider>
                            <PopupProvider>
                              <App />
                            </PopupProvider>
                          </ValidationPopupProvider>
                        </PreviousPathProvider>
                      </AccountContextProvider>
                    </QueryParametersProvider>
                  </GoogleOAuthProvider>
                </Suspense>
              </TranslationProvider>
            </I18nextProvider>
          </SWRConfig>
        </QueryParamProvider>
        <ToastContainer />
      </BrowserRouter>
    </SentryProvider>
  </StrictMode>
);
