import { Employer } from '@models/Employer';
import useSWR from 'swr';

export const useEmployer = (employerId: string) => {
  const { data, isLoading, error } = useSWR(`/public/employer/${employerId}`);
  let employer = null;
  if (data) {
    employer = Employer.fromJson(data);
  }
  return { employer, isLoading, error };
};
