import linkedin1 from '@assets/blog/linkedin_1.png';
import linkedin2 from '@assets/blog/linkedin_2.png';
import linkedin3 from '@assets/blog/linkedin_3.png';
import linkedin4 from '@assets/blog/linkedin_4.png';
import { Card } from '@elements/Card';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import { Trans, useTranslation } from 'react-i18next';

export const LinkedInResumeBlogPage = () => {
  const { t } = useTranslation('blog', { keyPrefix: 'linkedin_resume' });
  return (
    <ContainerWithBackButton>
      <MetaTags title={t('page_title')} />
      <Card>
        <Typography variant="h1" color="dark" className="text-center !text-3xl">
          <Trans i18nKey={t('title')} />
        </Typography>
        <Typography
          variant="body1"
          color="text"
          className="mt-6 text-center leading-6"
        >
          <Trans
            components={{ b: <span className="font-bold" /> }}
            i18nKey={t('description')}
          />
        </Typography>
        <div className="flex justify-center">
          <div className="mx-auto mt-10 flex max-w-[800px] flex-col gap-8">
            <div>
              <div className="grid grid-cols-[30px_1fr] gap-2">
                <Typography
                  variant="h5"
                  className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-secondary text-base text-white"
                >
                  {1}
                </Typography>
                <Typography variant="body1" className="text-base">
                  <Trans
                    components={{ b: <span className="font-bold" /> }}
                    i18nKey={t('step_1')}
                  />
                </Typography>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="grid grid-cols-[30px_1fr] gap-2">
                <Typography
                  variant="h5"
                  className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-secondary text-base text-white"
                >
                  {2}
                </Typography>
                <Typography variant="body1" className="text-base">
                  <Trans
                    components={{ b: <span className="font-bold" /> }}
                    i18nKey={t('step_2')}
                  />
                </Typography>
              </div>
              <img
                src={linkedin1}
                alt="linkedin"
                className="ms-8 w-full max-w-[400px]"
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="grid grid-cols-[30px_1fr] gap-2">
                <Typography
                  variant="h5"
                  className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-secondary text-base text-white"
                >
                  {3}
                </Typography>
                <Typography variant="body1" className="text-base">
                  <Trans
                    components={{ b: <span className="font-bold" /> }}
                    i18nKey={t('step_3')}
                  />
                </Typography>
              </div>
              <img
                src={linkedin2}
                alt="linkedin"
                className="ms-8 w-full max-w-[400px]"
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="grid grid-cols-[30px_1fr] gap-2">
                <Typography
                  variant="h5"
                  className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-secondary text-base text-white"
                >
                  {4}
                </Typography>
                <Typography variant="body1" className="text-base">
                  <Trans
                    components={{ b: <span className="font-bold" /> }}
                    i18nKey={t('step_4')}
                  />
                </Typography>
              </div>
              <img
                src={linkedin3}
                alt="linkedin"
                className="ms-8 w-full max-w-[300px]"
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="grid grid-cols-[30px_1fr] gap-2">
                <Typography
                  variant="h5"
                  className="flex h-[24px] w-[24px] items-center justify-center rounded-full bg-secondary text-base text-white"
                >
                  {5}
                </Typography>
                <Typography variant="body1" className="text-base">
                  <Trans
                    components={{ b: <span className="font-bold" /> }}
                    i18nKey={t('step_5')}
                  />
                </Typography>
              </div>
              <img
                src={linkedin4}
                alt="linkedin"
                className="ms-8 w-full max-w-[200px]"
              />
            </div>
          </div>
        </div>
        <Typography
          variant="body1"
          className="mt-10 text-center text-base font-bold"
        >
          <Trans i18nKey={t('outro')} />
        </Typography>
      </Card>
    </ContainerWithBackButton>
  );
};
