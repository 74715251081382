import resumeSkeleton from '@assets/resume_skeleton.svg';
import { Button } from '@elements/Button';
import { CollapseCard } from '@elements/CollapseCard';
import { Divider } from '@elements/Divider';
import { AddIcon, Warning } from '@icons/index';
import { Candidate } from '@models/candidate';
import { Status } from '@models/Status';
import { useAccountContext } from '@providers/UserContext';
import { useUpdateCandidateEducations } from '@services/candidate/use-candidate-educations';
import { useUpdateCandidateExperiences } from '@services/candidate/use-candidate-experiences';
import { useUpdateCandidateSkills } from '@services/candidate/use-candidate-skills';
import { useVerifyResume } from '@services/candidate/use-resume-verify';
import { Colors } from '@utils/ColorUtils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EducationEditor } from './EducationEditor';
import { JobExperienceEditor } from './JobExperienceEditor';
import { ResumeIntakeHeader } from './ResumeIntakeHeader';
import { SkillEditor } from './SkillEditor';
export interface ResumeIntakeProps {
  onNext: () => void;
}
export const ResumeIntake = ({ onNext }: ResumeIntakeProps) => {
  const { user } = useAccountContext<Candidate>();
  const [status, setStatus] = useState<Status | undefined>(
    user?.accountInfo?.resume?.status
  );
  const {
    skills,
    mutate: mutateSkills,
    pushChanges: pushSkills,
    isValid: isValidSkills,
    addSkill,
    deleteSkill,
    onChange: onChangeSkills
  } = useUpdateCandidateSkills();
  const {
    experiences,
    pushChanges: pushExperiences,
    mutate: mutateExperiences,
    onChange: onChangeExperiences,
    isValid: isValidExperiences,
    addExperience,
    deleteExperience
  } = useUpdateCandidateExperiences();
  const {
    educations,
    addEducation,
    deleteEducation,
    mutate: mutateEducations,
    pushChanges: pushEducations,
    onChange: onChangeEducations,
    isValid: isValidEducations
  } = useUpdateCandidateEducations();

  useEffect(() => {
    if (user?.accountInfo?.resume?.status !== status) {
      setStatus(user?.accountInfo?.resume?.status);
    }
  }, [user]);

  const { verify } = useVerifyResume();

  const [isSaving, setIsSaving] = useState(false);

  const { t } = useTranslation('candidate_intake', {
    keyPrefix: 'resume_intake'
  });

  const AddButton = ({ onClick }: { onClick: () => void }) => {
    return (
      <div className="mt-4 flex justify-end">
        <Button variant="void" className="!p-0" onClick={onClick}>
          <AddIcon fill={Colors.secondary} width={24} height={24} />
        </Button>
      </div>
    );
  };

  const jobExperiences = () => {
    return (
      <CollapseCard
        title={
          <div className="flex w-full items-center justify-between gap-2">
            {t('job_experience')}
            {!isValidExperiences && (
              <Warning fill={Colors.secondary} width={24} height={24} />
            )}
          </div>
        }
        defaultOpen={true}
      >
        {experiences?.map((experience, index) => (
          <React.Fragment key={experience.id}>
            <JobExperienceEditor
              experience={experience}
              onDelete={() => deleteExperience(experience)}
              onChange={onChangeExperiences}
            />
            {index < experiences.length - 1 && <Divider className="my-6" />}
          </React.Fragment>
        ))}
        <AddButton onClick={addExperience} />
      </CollapseCard>
    );
  };

  const renderSkills = () => {
    return (
      <CollapseCard
        title={
          <div className="flex w-full items-center justify-between gap-2">
            {t('skills')}
            {!isValidSkills && (
              <Warning fill={Colors.secondary} width={24} height={24} />
            )}
          </div>
        }
      >
        <div className="flex flex-col gap-4">
          {skills?.map(skill => (
            <React.Fragment key={skill.id}>
              <SkillEditor
                key={skill.id}
                skill={skill}
                onDelete={() => deleteSkill(skill)}
                onChange={onChangeSkills}
              />
            </React.Fragment>
          ))}
          <AddButton onClick={addSkill} />
        </div>
      </CollapseCard>
    );
  };

  const renderEducations = () => {
    return (
      <CollapseCard
        title={
          <div className="flex w-full items-center justify-between gap-2">
            {t('educations')}
            {!isValidEducations && (
              <Warning fill={Colors.secondary} width={24} height={24} />
            )}
          </div>
        }
      >
        {educations?.map((education, index) => (
          <React.Fragment key={education.id}>
            <EducationEditor
              education={education}
              onDelete={() => deleteEducation(education)}
              onChange={onChangeEducations}
            />
            {index < educations.length - 1 && <Divider className="my-6" />}
          </React.Fragment>
        ))}
        <AddButton onClick={addEducation} />
      </CollapseCard>
    );
  };

  return (
    <>
      <ResumeIntakeHeader
        status={status}
        isSaving={isSaving}
        canSave={isValidExperiences && isValidSkills && isValidEducations}
        onDeleteResume={async () => {
          await mutateSkills();
          await mutateExperiences();
          await mutateEducations();
        }}
        onNextClick={async () => {
          setIsSaving(true);
          await pushExperiences();
          await pushSkills();
          await pushEducations();
          await verify();
          onNext();
          setIsSaving(false);
        }}
      />
      {!status ||
      status === Status.PENDING ||
      status === Status.BUSY ||
      status === Status.ERROR ? (
        <div className="mt-8 flex flex-col items-center justify-center gap-4">
          <img src={resumeSkeleton} alt="resume skeleton" />
          <img src={resumeSkeleton} alt="resume skeleton" />
          <img src={resumeSkeleton} alt="resume skeleton" />
          <img src={resumeSkeleton} alt="resume skeleton" />
        </div>
      ) : (
        <div className="flex flex-col gap-4 py-8">
          {jobExperiences()}
          {renderSkills()}
          {renderEducations()}
        </div>
      )}
    </>
  );
};
