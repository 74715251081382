import { Card } from '@elements/Card';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { Typography } from '@elements/Typography';
import { Trans, useTranslation } from 'react-i18next';

export const TermsAndConditions = () => {
  const { t } = useTranslation('terms_and_conditions');

  const sections = [
    'access',
    'accuracy',
    'password',
    'privacy',
    'ai_consent',
    'exclusive',
    'liability',
    'termination',
    'changes',
    'penalty',
    'governing_law',
    'contact',
    'agreement'
  ];

  return (
    <ContainerWithBackButton className="flex flex-col gap-4 py-10">
      <Card>
        <Typography variant="h1" color="dark" className="!text-2xl">
          {t('title')}
        </Typography>

        {sections.map(section => (
          <div key={section} className="mt-10 px-6">
            <Typography variant="h2" color="primary" className="mb-4 !text-lg">
              {t(`sections.${section}.title`)}
            </Typography>
            <ul className="list-disc ps-8">
              {Object.entries(t(`sections.${section}`, { returnObjects: true }))
                .filter(([key]) => key !== 'title')
                .map(([key, content]) => (
                  <li key={key}>
                    <Typography variant="body1" color="text" className="mb-4">
                      <Trans
                        components={{
                          a: (
                            <a
                              className="text-secondary no-underline"
                              href="mailto:contact@workwithkora.com"
                            />
                          )
                        }}
                      >
                        {content}
                      </Trans>
                    </Typography>
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </Card>
    </ContainerWithBackButton>
  );
};
