import { Candidate } from '@models/candidate';
import { useAccountContext } from '@providers/UserContext';
import { KoraApi } from '@services/api';
import { useEffect, useRef, useState } from 'react';

export const useCandidateSummaryGenerator = () => {
  const { user } = useAccountContext<Candidate>();
  const [isGenerating, setIsGenerating] = useState(
    user?.accountInfo.summaryDraftUrl === '<GENERATING>'
  );
  const lastGenerateTime = useRef<number>(0);
  const COOLDOWN_MS = 5000; // 5 seconds in milliseconds

  useEffect(() => {
    if (
      user?.accountInfo.summaryDraftUrl &&
      user?.accountInfo.summaryDraftUrl !== '<GENERATING>'
    ) {
      setIsGenerating(false);
    }
  }, [user?.accountInfo.summaryDraftUrl]);

  const generate = async () => {
    const now = Date.now();
    if (isGenerating || now - lastGenerateTime.current < COOLDOWN_MS) return;

    try {
      lastGenerateTime.current = now;
      setIsGenerating(true);
      await KoraApi.post('/candidate/generate-summary', {});
    } catch (error) {
      console.error(error);
    }
  };

  return { generate, isGenerating };
};
