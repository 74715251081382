import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Checkbox } from '@elements/Checkbox';
import { DateInput } from '@elements/DateInput';
import { Input } from '@elements/Input';
import { PlaceInput } from '@elements/PlaceInput';
import { Typography } from '@elements/Typography';
import { Employer } from '@models/Employer';
import { useAccountContext } from '@providers/UserContext';
import { useEmployerPatch } from '@services/user/use-employer-patch';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
export const CompanyInformationIntake = ({
  onNext
}: {
  onNext?: () => void;
}) => {
  const { t } = useTranslation('employer_intake', {
    keyPrefix: 'company_information_intake'
  });
  const { t: tCommon } = useTranslation('common');
  const { user } = useAccountContext<Employer>();
  const [companyName, setCompanyName] = useState(
    user?.accountInfo?.companyName
  );
  const [companyLocation, setCompanyLocation] = useState(
    user?.accountInfo?.address
  );
  const [foundingDate, setFoundingDate] = useState<Date | undefined>(
    user?.accountInfo?.foundingDate
      ? new Date(user.accountInfo.foundingDate)
      : undefined
  );
  const [industry, setIndustry] = useState(user?.accountInfo?.industry);
  const [totalEmployees, setTotalEmployees] = useState(
    user?.accountInfo?.totalEmployees
  );
  const [dutchEmployees, setDutchEmployees] = useState(
    user?.accountInfo?.dutchEmployees
  );
  const [indRegistration, setIndRegistration] = useState(
    user?.accountInfo?.indRegistration
  );

  const { patch } = useEmployerPatch();

  return (
    <Card className="!p-6">
      <Typography variant="h2" className="mb-4 text-lg !font-medium text-dark">
        {t('form_title')}
      </Typography>
      <div className="flex flex-col gap-4">
        <Input
          label={t('company_name')}
          variant="compact"
          value={companyName}
          onChange={e => setCompanyName(e.target.value)}
        />
        <PlaceInput
          inputProps={{
            label: t('company_location'),
            variant: 'compact'
          }}
          defaultValue={companyLocation ?? undefined}
          onSelect={_address => {
            setCompanyLocation(_address);
          }}
        />
        <DateInput
          label={t('company_founded_date')}
          variant="compact"
          value={foundingDate}
          onChange={date => setFoundingDate(date)}
        />
        <Input
          label={t('company_industry')}
          variant="compact"
          value={industry}
          onChange={e => setIndustry(e.target.value)}
        />
        <Input
          label={t('company_total_employees')}
          variant="compact"
          type="number"
          value={totalEmployees}
          onChange={e =>
            setTotalEmployees(
              e.target.value === '' ? undefined : Number(e.target.value)
            )
          }
        />
        <Input
          label={t('company_dutch_employees')}
          variant="compact"
          type="number"
          value={dutchEmployees}
          onChange={e =>
            setDutchEmployees(
              e.target.value === '' ? undefined : Number(e.target.value)
            )
          }
        />
        <div className="flex flex-col gap-2 ps-1">
          <Typography variant="body1">{t('ind_registered')}</Typography>
          <div className="flex items-center gap-4">
            <Checkbox
              checked={indRegistration}
              label={tCommon('yes')}
              onChange={() => setIndRegistration(true)}
            />
            <Checkbox
              checked={!indRegistration}
              label={tCommon('no')}
              onChange={() => setIndRegistration(false)}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          variant="primary"
          size="sm"
          disabled={
            !companyName ||
            !companyLocation ||
            !foundingDate ||
            !industry ||
            totalEmployees === undefined ||
            dutchEmployees === undefined
          }
          onClick={async () => {
            await patch({
              companyName,
              address: companyLocation ?? undefined,
              industry,
              foundingDate,
              totalEmployees,
              dutchEmployees,
              indRegistration
            });
            onNext?.();
          }}
        >
          {tCommon('next')}
        </Button>
      </div>
    </Card>
  );
};
