import { useToast } from '@elements/Toast';
import { MatchingStatus } from '@models/Match';
import { useValidationPopup } from '@providers/ValidationPopupProvider';
import { KoraApi } from '@services/api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEmployerFunnel } from './use-employer-funnel';
import { useFunnelItem } from './use-funnel-item';

interface PatchOptions {
  status?:
    | MatchingStatus.REJECTED
    | MatchingStatus.INVITED
    | MatchingStatus.INTERVIEW
    | MatchingStatus.JOB_TAKEN;
  onSuccess?: () => void;
  archived?: boolean;
  onSuccessMessage?: string;
  disableNavigate?: boolean;
}

interface PatchWithConfirmOptions {
  title?: string;
  description?: string;
  status?:
    | MatchingStatus.REJECTED
    | MatchingStatus.INVITED
    | MatchingStatus.INTERVIEW
    | MatchingStatus.JOB_TAKEN;
  archived?: boolean;
  onSuccess?: () => void;
  onSuccessMessage?: string;
  disableNavigate?: boolean;
}

export const usePatchEmployerMatch = (matchId: string) => {
  const { mutate: mutateFunnel } = useEmployerFunnel();
  const { success } = useToast();
  const { t } = useTranslation('funnel');
  const { show } = useValidationPopup();
  const navigate = useNavigate();
  const { mutate: mutateMatch } = useFunnelItem(matchId);

  const patch = async ({
    status,
    onSuccess,
    onSuccessMessage,
    archived,
    disableNavigate = true
  }: PatchOptions) => {
    await KoraApi.patch(`/employer/funnel/${matchId}`, {
      status,
      archived
    });
    mutateFunnel();
    mutateMatch();
    onSuccess?.();
    if (!disableNavigate) {
      if (status) {
        success(onSuccessMessage ?? t('reject_success'));
      }
      navigate('/funnel');
    }
  };

  const patchWithConfirm = ({
    status,
    title,
    description,
    onSuccessMessage,
    onSuccess
  }: PatchWithConfirmOptions) => {
    show({
      title: title ?? t('reject_confirm_title'),
      description: description ?? t('reject_confirm_description'),
      callback: async () => {
        await patch({ status, onSuccess, onSuccessMessage });
      }
    });
  };

  return { patch, patchWithConfirm };
};
