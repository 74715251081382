import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

interface QueryParametersContextType {
  getQueryParam: (key: string) => string | null;
  setQueryParam: (key: string, value: string) => void;
  setQueryParams: (params: Record<string, string>) => void;
  removeQueryParam: (key: string) => void;
}

const QueryParametersContext = createContext<
  QueryParametersContextType | undefined
>(undefined);

export function QueryParametersProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const getQueryParam = useCallback(
    (key: string) => {
      return searchParams.get(key);
    },
    [searchParams]
  );

  const setQueryParam = useCallback(
    (key: string, value: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, value);
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const setQueryParams = useCallback(
    (params: Record<string, string>) => {
      const newSearchParams = new URLSearchParams(searchParams);
      Object.entries(params).forEach(([key, value]) => {
        newSearchParams.set(key, value);
      });
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const removeQueryParam = useCallback(
    (key: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete(key);
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams]
  );

  const value = useMemo(
    () => ({
      getQueryParam,
      setQueryParam,
      setQueryParams,
      removeQueryParam
    }),
    [getQueryParam, setQueryParam, setQueryParams, removeQueryParam]
  );

  return (
    <QueryParametersContext.Provider value={value}>
      {children}
    </QueryParametersContext.Provider>
  );
}

export function useQueryParameters() {
  const context = useContext(QueryParametersContext);
  if (context === undefined) {
    throw new Error(
      'useQueryParameters must be used within a QueryParametersProvider'
    );
  }
  return context;
}
