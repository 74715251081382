import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Input } from '@elements/Input';
import { PhoneInput } from '@elements/PhoneInput';
import { Typography } from '@elements/Typography';
import { UrlInput } from '@elements/UrlInput';
import { Employer } from '@models/Employer';
import { useEmployerPatch } from '@services/user/use-employer-patch';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

interface IProfileInformation {
  onNext?: () => void;
}

export const EmployerProfileIntake = ({ onNext }: IProfileInformation) => {
  const { t } = useTranslation('employer_intake', {
    keyPrefix: 'profile_intake'
  });
  const { t: tForm } = useTranslation('settings', {
    keyPrefix: 'overview'
  });
  const { t: tCommon } = useTranslation('common');
  const { user } = useAccountContext<Employer>();

  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [email, setEmail] = useState(user?.email);
  const [linkedinUrl, setLinkedinUrl] = useState(
    user?.accountInfo?.linkedinUrl ?? undefined
  );
  const [phone, setPhone] = useState(user?.accountInfo?.phone ?? undefined);
  const { patch } = useEmployerPatch();

  return (
    <Card className="!p-6">
      <Typography variant="h2" className="mb-4 text-lg !font-medium text-dark">
        {t('form_title')}
      </Typography>
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4">
          <Input
            label={tForm('first_name')}
            variant="compact"
            defaultValue={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
          <Input
            label={tForm('last_name')}
            variant="compact"
            defaultValue={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </div>
        <Input
          label={tForm('email')}
          variant="compact"
          disabled
          defaultValue={email}
          onChange={e => setEmail(e.target.value)}
        />
        <PhoneInput
          label={tForm('phone')}
          variant="compact"
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
        <UrlInput
          label={tForm('linkedin_url')}
          variant="compact"
          placeholder=" "
          value={linkedinUrl ?? ''}
          onChange={e => setLinkedinUrl(e.target.value)}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          variant="primary"
          size="sm"
          disabled={!firstName || !lastName || !email || !phone}
          onClick={async () => {
            await patch({
              firstName,
              lastName,
              email,
              linkedinUrl,
              phone
            });
            onNext?.();
          }}
        >
          {tCommon('next')}
        </Button>
      </div>
    </Card>
  );
};
