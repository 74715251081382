import { VacancyCard } from '@components/vacancies/VacancyCard';
import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Typography } from '@elements/Typography';
import { Employer } from '@models/Employer';
import { useAccountContext } from '@providers/UserContext';
import { useEmployerVacancies } from '@services/employer/use-employer-vacancies';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const DashboardVacancies = () => {
  const { t } = useTranslation('vacancies');
  const { t: tLiteral } = useTranslation('literals');
  const { vacancies } = useEmployerVacancies({ pageSize: 2 });
  const { user } = useAccountContext<Employer>();

  const CreateCard = () => {
    return (
      <Card className="!p-6">
        <Typography
          variant="h5"
          color="default"
          className="mb-6 line-clamp-1 text-base"
        >
          {t('create_vacancy')}
        </Typography>
        <Typography variant="body1" color="default" className="text-sm">
          {t('create_vacancy_description')}
        </Typography>
        <div className="mt-4 flex w-full justify-end">
          <Link to="/vacancies/create" className="w-full">
            <Button variant="secondary" className="w-full">
              {tLiteral('create')}
            </Button>
          </Link>
        </div>
      </Card>
    );
  };

  const CreateFirstCard = () => {
    if (
      !user?.accountInfo.intakeProgress ||
      user?.accountInfo.intakeProgress < 100
    ) {
      return (
        <Card className="flex flex-col gap-4 text-center">
          <Typography
            variant="h5"
            color="primary"
            className="mb-6 line-clamp-1"
          >
            {t('must_complete_intake')}
          </Typography>

          <div className="mt-4 flex justify-center">
            <Link to="/intake">
              <Button variant="secondary" className="w-full">
                {t('complete_intake')}
              </Button>
            </Link>
          </div>
        </Card>
      );
    }
    return (
      <Card className="flex flex-col gap-4 text-center">
        <Typography variant="h5" color="primary" className="mb-6 line-clamp-1">
          {t('create_first_vacancy')}
        </Typography>

        <div className="mt-4 flex justify-center">
          <Link to="/vacancies/create">
            <Button variant="secondary" className="w-full">
              {t('create_vacancy')}
            </Button>
          </Link>
        </div>
      </Card>
    );
  };

  return (
    <>
      <Typography variant="h5" color="default" className="mb-6">
        {t('vacancies')}
      </Typography>
      {vacancies.length === 0 ? (
        <CreateFirstCard />
      ) : (
        <div className="grid grid-cols-3 gap-4">
          <CreateCard />
          {vacancies.map(vacancy => (
            <VacancyCard key={vacancy.id} vacancy={vacancy} />
          ))}
        </div>
      )}
      <Link to="/vacancies">
        <Button variant="info" className="mt-6">
          {t('view_all_vacancies')}
        </Button>
      </Link>
    </>
  );
};
