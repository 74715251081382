import { Button } from '@elements/Button';
import { Checkbox } from '@elements/Checkbox';
import { DateInput } from '@elements/DateInput';
import { Input } from '@elements/Input';
import { Select } from '@elements/Select';
import { Trash } from '@icons/index';
import { Education, EducationType } from '@models/Education';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
interface EducationEditorProps {
  education: Education;
  onDelete: () => void;
  onChange: (education: Education) => void;
}

export const EducationEditor = ({
  education,
  onDelete,
  onChange
}: EducationEditorProps) => {
  const { t } = useTranslation('candidate_intake', {
    keyPrefix: 'resume_intake.education_editor'
  });

  const educationTypeOptions = [
    { label: t('high_school_vmbo'), value: EducationType.HIGH_SCHOOL_VMBO },
    { label: t('high_school_havo'), value: EducationType.HIGH_SCHOOL_HAVO },
    { label: t('high_school_vwo'), value: EducationType.HIGH_SCHOOL_VWO },
    { label: t('bachelor_mbo'), value: EducationType.BACHELOR_MBO },
    { label: t('bachelor_hbo'), value: EducationType.BACHELOR_HBO },
    { label: t('bachelor_wo'), value: EducationType.BACHELOR_WO },
    { label: t('master_hbo'), value: EducationType.MASTER_HBO },
    { label: t('master_wo'), value: EducationType.MASTER_WO },
    { label: t('high_school'), value: EducationType.HIGH_SCHOOL },
    { label: t('bachelors'), value: EducationType.BACHELORS },
    { label: t('masters'), value: EducationType.MASTERS },
    { label: t('phd'), value: EducationType.PHD },
    { label: t('certificate'), value: EducationType.CERTIFICATE },
    { label: t('other'), value: EducationType.OTHER }
  ];

  const [title, setTitle] = useState(education.title);
  const [type, setType] = useState(education.educationType);
  const [startDate, setStartDate] = useState(education.startDate);
  const [endDate, setEndDate] = useState<Date | null>(
    education.endDate ?? null
  );
  const [completed, setCompleted] = useState(education.completed);
  const [description, setDescription] = useState(education.description);
  return (
    <div className="flex w-full gap-4">
      <div className="flex flex-1 flex-col gap-4">
        <Input
          label={t('title')}
          value={title}
          onChange={e => {
            setTitle(e.target.value);
            education.title = e.target.value;
            onChange(education);
          }}
          variant="compact"
        />
        <div className="grid grid-cols-[4fr_3fr] gap-4">
          <Select
            label={t('education_type')}
            options={educationTypeOptions}
            disableSearch={true}
            value={type}
            onChange={e => {
              if (!e) return;
              setType(e as EducationType);
              education.educationType = e as EducationType;
              onChange(education);
            }}
          />
          <div className="flex items-center gap-2">
            <DateInput
              label={t('start_date')}
              value={startDate}
              onChange={e => {
                if (!e) return;
                setStartDate(e);
                education.startDate = e;
                onChange(education);
              }}
              variant="compact"
              onlyMonthYear={true}
            />
            <DateInput
              label={t('end_date')}
              value={endDate ?? undefined}
              onChange={e => {
                setEndDate(e);
                education.endDate = e;
                onChange(education);
              }}
              variant="compact"
              onlyMonthYear={true}
              allowTodayButton={true}
            />
            <Checkbox
              label={t('completed')}
              checked={completed}
              onChange={e => {
                setCompleted(e.target.checked);
                education.completed = e.target.checked;
                onChange(education);
              }}
            />
          </div>
        </div>
        <Input
          label={t('description')}
          value={description ?? ''}
          variant="compact"
          autoGrow
          className="min-h-[100px]"
          onChange={e => {
            setDescription(e.target.value);
            education.description = e.target.value;
            onChange(education);
          }}
        />
      </div>
      <div>
        <Button variant="void" size="sm" className="!p-0" onClick={onDelete}>
          <Trash />
        </Button>
      </div>
    </div>
  );
};
