import PlaceholderCard from '@assets/placeholder-card.svg';
import { VacancyMatchCard } from '@components/funnel/VacancyMatchCard';
import { Container } from '@elements/Container';
import {
  LocationFilter,
  LocationFilterSelection
} from '@elements/LocationFilter';
import { MetaTags } from '@elements/MetaTags';
import { PopupCTA } from '@elements/PopupCTA';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { Candidate } from '@models/candidate';
import { usePopup } from '@providers/PopupProvider';
import { useCandidateMatches } from '@services/candidate/use-candidate-matches';
import { Colors } from '@utils/ColorUtils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BeatLoader } from 'react-spinners';
import { useAccountContext } from 'src/providers/UserContext';

export const RecommendedJobsPage = () => {
  const { t } = useTranslation('recommended_jobs');
  const { user } = useAccountContext<Candidate>();
  const isMobile = useIsMobile();
  const [locationFilter, setLocationFilter] = useState<
    LocationFilterSelection | undefined
  >(undefined);
  const { matches, isLoading } = useCandidateMatches({
    locationFilter
  });
  const { show, hide } = usePopup();
  const [beingMatched, setBeingMatched] = useState(false);
  const [hasMatches, setHasMatches] = useState(false);

  useEffect(() => {
    if (!hasMatches) {
      setHasMatches(matches?.length > 0);
    }
  }, [matches]);

  useEffect(() => {
    if (user?.accountInfo?.intakeProgress !== 100 && !beingMatched) {
      show({
        body: (
          <PopupCTA
            title={t('intake_incomplete')}
            buttonText={t('go_to_intake')}
            link="/intake"
          />
        ),
        closeButton: false
      });
    }
  }, [user?.accountInfo?.intakeProgress, beingMatched]);

  useEffect(() => {
    setBeingMatched(user?.accountInfo?.beingMatched ?? false);
    if (
      user?.accountInfo?.beingMatched ||
      user?.accountInfo?.intakeProgress === 100
    ) {
      hide();
    }
  }, [user?.accountInfo?.beingMatched]);

  const PlaceholderCards = () => (
    <>
      <img src={PlaceholderCard} className="w-full" />
      <img src={PlaceholderCard} className="w-full" />
      <img src={PlaceholderCard} className="w-full" />
    </>
  );

  return (
    <>
      <MetaTags title={t('title')} />
      <Container className={`${!isMobile && 'py-10'} relative`}>
        <div className="flex flex-col justify-between gap-6 pb-4">
          <Typography variant="h1">{t('title')}</Typography>
          <Typography variant="body1">{t('subtitle')}</Typography>
        </div>
        {hasMatches && <LocationFilter onChange={setLocationFilter} />}
        <div className="mt-10 grid grid-cols-1 gap-4 md:grid-cols-3">
          {(!matches || matches.length === 0) && !isLoading ? (
            <PlaceholderCards />
          ) : (
            matches?.map(match => (
              <VacancyMatchCard key={match.id} match={match} />
            ))
          )}
        </div>
        {beingMatched && (
          <div className="my-10 flex flex-col items-center justify-center gap-4">
            <BeatLoader color={Colors.primary} />
            <Typography variant="body1">
              {t('being_matched_subtitle')}
            </Typography>
          </div>
        )}
      </Container>
    </>
  );
};
