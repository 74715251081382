import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Typography } from '@elements/Typography';
import { UrlInput } from '@elements/UrlInput';
import { Employer } from '@models/Employer';
import { useAccountContext } from '@providers/UserContext';
import { useEmployerPatch } from '@services/user/use-employer-patch';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface CompanyWebsitesIntakeProps {
  onNext?: () => void;
}

export const CompanyWebsitesIntake = ({
  onNext
}: CompanyWebsitesIntakeProps) => {
  const { t } = useTranslation('employer_intake', {
    keyPrefix: 'company_websites_intake'
  });
  const { t: tCommon } = useTranslation('common');

  const { user } = useAccountContext<Employer>();
  const { patch } = useEmployerPatch();
  const [homepageUrl, setHomepageUrl] = useState(
    user?.accountInfo?.websites?.home
  );
  const [careersUrl, setCareersUrl] = useState(
    user?.accountInfo?.websites?.careers
  );
  const [aboutUrl, setAboutUrl] = useState(
    user?.accountInfo?.websites?.aboutUs
  );
  const [otherUrl, setOtherUrl] = useState(user?.accountInfo?.websites?.other);

  return (
    <Card className="!p-6">
      <Typography variant="h2" className="mb-4 text-lg !font-medium text-dark">
        {t('form_title')}
      </Typography>
      <div className="flex flex-col gap-4">
        <UrlInput
          label={t('homepage_url')}
          variant="compact"
          value={homepageUrl}
          onChange={e => {
            setHomepageUrl(e.target.value);
          }}
        />
        <UrlInput
          label={t('careers_url')}
          variant="compact"
          value={careersUrl}
          onChange={e => {
            setCareersUrl(e.target.value);
          }}
        />
        <UrlInput
          label={t('about_url')}
          variant="compact"
          value={aboutUrl}
          onChange={e => {
            setAboutUrl(e.target.value);
          }}
        />
        <UrlInput
          label={t('other_urls')}
          variant="compact"
          value={otherUrl}
          onChange={e => {
            setOtherUrl(e.target.value);
          }}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          variant="primary"
          size="sm"
          disabled={!homepageUrl}
          onClick={async () => {
            await patch({
              websites: {
                home: homepageUrl,
                careers: careersUrl,
                aboutUs: aboutUrl,
                other: otherUrl
              }
            });
            onNext?.();
          }}
        >
          {tCommon('next')}
        </Button>
      </div>
    </Card>
  );
};
