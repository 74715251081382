import { Address } from './address';
import { Employer } from './Employer';
import { Status } from './Status';
export interface VacancyFlags {
  readStatus?: Status;
  basicInfoValid: boolean;
  detailsValid: boolean;
  extraInfoValid: boolean;
}
export class Vacancy {
  constructor(
    public id: string,
    public flags: VacancyFlags,
    public employer?: Employer,
    public title?: string,
    public description?: string,
    public salaryMin?: number,
    public salaryMax?: number,
    public benefits?: string[],
    public originalUrl?: string,
    public requiredSkills?: string[],
    public requiredExperience?: string[],
    public requiredEducation?: string[],
    public requiredLanguages?: string[],
    public responsibilities?: string[],
    public requiredVisa?: string[],
    public homeworkDays?: number,
    public address?: Address,
    public intakeProgress?: number,
    public createdAt?: Date,
    public summaryUrl?: string,
    public amountPotentialCandidates?: number,
    public isScraped?: boolean,
    public sourceType?: 'PDF' | 'URL',
    public minHoursPerWeek?: number,
    public markdownSummary?: string
  ) {}

  static fromJson(json: any): Vacancy {
    const employer = json.employer
      ? Employer.fromJson(json.employer)
      : undefined;
    const address = json.address
      ? Address.fromJson(json.address)
      : json.employer?.address
        ? Address.fromJson(json.employer.address)
        : undefined;

    return new Vacancy(
      json.id,
      json.flags as VacancyFlags,
      employer,
      json.title,
      json.description,
      json.salaryMin,
      json.salaryMax,
      json.benefits,
      json.originalUrl,
      json.requiredSkills,
      json.requiredExperience,
      json.requiredEducation,
      json.requiredLanguages,
      json.responsibilities,
      json.requiredVisa,
      json.homeworkDays,
      address,
      json.intakeProgress,
      json.createdAt ? new Date(json.createdAt) : undefined,
      json.summaryUrl,
      json.amountPotentialCandidates ?? 0,
      json.isScraped ?? false,
      json.source,
      json.minHoursPerWeek,
      json.markdownSummary
    );
  }
}
