import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { Divider } from '@elements/Divider';
import { Typography } from '@elements/Typography';
import { MatchingStatus } from '@models/Match';
import { useFunnelItem } from '@services/employer/use-funnel-item';
import { usePatchEmployerMatch } from '@services/employer/use-patch-employer-match';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
const allowedStates = [
  MatchingStatus.DECLINED,
  MatchingStatus.DECLINED_INTERVIEW_BY_CANDIDATE,
  MatchingStatus.DECLINED_INTERVIEW_BY_EMPLOYER,
  MatchingStatus.APPLIED
];
export const InviteCandidatePage = () => {
  const { matchId } = useParams();
  const { match } = useFunnelItem(matchId!);
  const { t } = useTranslation('funnel');
  const { t: tLiterals } = useTranslation('literals');
  const { patch } = usePatchEmployerMatch(matchId!);
  const [initialized, setInitialized] = useState(false);

  // Create template with explicit CRLF line breaks
  const plainTextTemplate =
    `<html><body>Hi ${match?.candidate?.firstName},<br/><br/>` +
    `We're excited to move forward with our first interview and have a few possible time slots available next week:<br/>` +
    `[Option 1: Day, Date, Time]<br/>` +
    `[Option 2: Day, Date, Time]<br/>` +
    `[Option 3: Day, Date, Time]<br/><br/>` +
    `Our first interview is always online or at the office.<br/><br/>` +
    `If online, I'll send you a video call link as soon as the dates line up.<br/><br/>` +
    `If at the office, our address is: ${match?.vacancy?.employer?.address?.street}, ${match?.vacancy?.employer?.address?.city}<br/><br/>` +
    `Please let me know which option works best for you—or feel free to suggest alternate dates and times if none of the above are convenient.<br/><br/>` +
    `Thank you, and I look forward to hearing from you soon.</body></html>`;

  const mailTemplate = encodeURIComponent(plainTextTemplate);
  const mailToUrl = `mailto:${match?.candidate?.email}?subject=Timeslot%20Invitation%20for%20${encodeURIComponent(match?.vacancy?.title!)}&body=${mailTemplate}`;

  const setInvited = async (navigate = true) => {
    if (!match?.status || !allowedStates.includes(match?.status)) {
      return;
    }
    await patch({
      status: MatchingStatus.INVITED,
      onSuccessMessage: t('invite_success'),
      disableNavigate: !navigate
    });
  };

  useEffect(() => {
    if (match?.status && !allowedStates.includes(match?.status)) {
      setInitialized(true);
      return;
    }
    if (
      !initialized &&
      match?.status &&
      allowedStates.includes(match?.status)
    ) {
      setInvited(false);
      setInitialized(true);
    }
  }, []);

  return (
    <ContainerWithBackButton loading={!match}>
      {match?.status && allowedStates.includes(match?.status) && (
        <Typography
          variant="h1"
          className="my-8 text-center !text-[1.5rem] text-dark"
        >
          {t('invite_candidate_title', {
            candidateName: match?.candidate?.firstName
          })}
        </Typography>
      )}
      <div className="flex justify-center">
        <Card className="flex w-full max-w-[500px] flex-col justify-center gap-y-4 !p-6">
          <Typography variant="h2" className="mb-2 text-center text-xl">
            {t('contact_details')}
          </Typography>
          <div className="flex flex-col gap-y-4">
            {match?.candidate?.phone && (
              <Link to={`tel:${match?.candidate?.phone}`}>
                <Button
                  variant="info"
                  size="sm"
                  className="w-full !text-lg"
                  onClick={() => setInvited(true)}
                >
                  {tLiterals('phone')}: {match?.candidate?.phone}
                </Button>
              </Link>
            )}
            {match?.candidate?.email && (
              <a href={mailToUrl}>
                <Button
                  variant="info"
                  size="sm"
                  className="w-full !text-lg"
                  onClick={() => setInvited(true)}
                >
                  {tLiterals('email')}: {match?.candidate?.email}
                </Button>
              </a>
            )}
          </div>
          <Divider />
          <Typography variant="body1" className="mb-2 font-medium leading-5">
            {t('invite_candidate_description')}
          </Typography>
          <a href={mailToUrl}>
            <Button
              variant="primary"
              size="sm"
              className="w-full !text-lg"
              onClick={() => setInvited(true)}
            >
              {t('send_timeslots')}
            </Button>
          </a>
        </Card>
      </div>
    </ContainerWithBackButton>
  );
};
