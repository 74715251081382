import logo from '@assets/logo.svg';
import menu from '@assets/menu.svg';
import { Button } from '@elements/Button';
import { Divider } from '@elements/Divider';
import { Typography } from '@elements/Typography';
import { ChevronDown, ChevronUp } from '@icons/index';
import { AccountType } from '@models/UserAccount';
import { useAccountContext } from '@providers/UserContext';
import { useLogout } from '@services/auth/use-logout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { NotificationBell } from './NotificationBell';

export const MobileHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation('header');
  const { isLoggedIn, user, accountType, setAccountType } = useAccountContext();
  const logout = useLogout();

  const MobileMenuItem = ({
    title,
    to,
    className,
    color = 'dark',
    children,
    onClick
  }: {
    title: string;
    to?: string;
    className?: string;
    children?: React.ReactNode;
    color?: string;
    onClick?: () => void;
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const handleClick = () => {
      if (children) {
        setIsOpen(!isOpen);
      } else if (to) {
        navigate(to);
        setIsMenuOpen(false);
      } else if (onClick) {
        onClick();
        setIsMenuOpen(false);
      }
    };
    return (
      <Button
        variant="void"
        className={`w-full !px-0 no-underline ${className}`}
        onClick={handleClick}
      >
        <div className="flex items-center justify-between">
          <Typography
            variant="body1"
            color={color}
            className="text-lg font-semibold leading-6"
          >
            {title}
          </Typography>
          {children && (
            <Typography
              variant="body2"
              color={color}
              className="text-sm font-normal leading-5"
            >
              {isOpen ? <ChevronUp /> : <ChevronDown />}
            </Typography>
          )}
        </div>
        {children && isOpen && <div className="ms-4 mt-2">{children}</div>}
      </Button>
    );
  };

  const defaultMenuItems = () => {
    return (
      <>
        <MobileMenuItem title={t('create_account')} to="/register" />
        <MobileMenuItem title={t('login')} to="/login" />
      </>
    );
  };

  const loggedInMenuItems = () => {
    const firstSection =
      user?.accountType === AccountType.CANDIDATE ? (
        <>
          <MobileMenuItem title={t('recommended_jobs')} to="/jobs" />
          <MobileMenuItem title={t('funnel')} to="/funnel" />
        </>
      ) : (
        <>
          <MobileMenuItem title={t('employer.hiring_funnel')} to="/funnel" />
          <MobileMenuItem title={t('employer.vacancies')} to="/vacancies" />
        </>
      );

    return (
      <>
        {firstSection}
        <MobileMenuItem title={t('account')}>
          {user?.accountType === AccountType.CANDIDATE ? (
            <>
              <MobileMenuItem
                title={t('profile')}
                to="/profile"
                color="primary"
              />
            </>
          ) : (
            <MobileMenuItem
              title={t('settings')}
              to="/settings"
              color="primary"
            />
          )}
          <MobileMenuItem
            title={t('logout')}
            onClick={logout}
            color="primary"
          />
        </MobileMenuItem>
      </>
    );
  };

  return (
    <div className="fixed left-0 right-0 top-0 z-[99] flex w-full flex-col rounded-[0px_0px_20px_20px] !bg-[#F5F5F0] p-[16px_16px] shadow-[0px_25px_30px_0px_rgba(0,0,0,0.04)]">
      <div className="flex w-full justify-between">
        <Link to="/">
          <img src={logo} className="h-[40px]" alt="Logo" />
        </Link>
        <div className="flex items-center justify-end gap-4">
          {isLoggedIn ? (
            <NotificationBell />
          ) : (
            <Button
              variant="void"
              size="sm"
              className="text-secondary"
              onClick={() =>
                setAccountType(
                  accountType === AccountType.CANDIDATE
                    ? AccountType.EMPLOYER
                    : AccountType.CANDIDATE
                )
              }
            >
              {accountType === AccountType.CANDIDATE
                ? t('switch_to_employer')
                : t('switch_to_candidate')}
            </Button>
          )}
          <Button
            variant="void"
            className="!p-0"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <img src={menu} alt="Menu" />
          </Button>
        </div>
      </div>
      <div className="overflow-hidden">
        <div
          className={`flex h-full w-full flex-col items-center gap-3 pt-4 no-underline ${
            isMenuOpen ? 'animate-slide-down' : 'animate-slide-up'
          }`}
        >
          <Divider />
          {isLoggedIn ? loggedInMenuItems() : defaultMenuItems()}
        </div>
      </div>
    </div>
  );
};
