import { CandidateProfileForm } from '@components/forms/CandidateProfileForm';
import {
  ProfileCTASection,
  ProfilePictureUpload,
  ProgressCircle
} from '@components/profile';
import { ProfileSummaryCard } from '@components/profile/ProfileSummaryCard';
import { Card } from '@elements/Card';
import { Container } from '@elements/Container';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import { Candidate } from '@models/candidate';
import { AccountType, UserAccount } from '@models/UserAccount';
import { useCandidatePatch } from '@services/user/use-candidate-patch';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';

export const ProfilePage = () => {
  const { user } = useAccountContext();

  const navigate = useNavigate();
  const accountInfo = user?.accountInfo
    ? (user.accountInfo as Candidate)
    : null;

  const { patch } = useCandidatePatch();
  const { t } = useTranslation('profile');

  useEffect(() => {
    if (user?.accountType === AccountType.EMPLOYER) {
      navigate('/settings');
    }
  }, [user]);

  if (!user) return null;

  return (
    <Container>
      <MetaTags title="Profile" />
      <div className="grid grid-cols-[1fr,3fr] items-start gap-[60px] py-[60px]">
        <Card className="flex flex-col justify-between !p-4">
          <div>
            <ProfilePictureUpload
              initialImage={accountInfo?.profilePicture}
              onImageUpload={file => {
                patch({ profilePicture: file });
              }}
            />
            <ProfileCTASection />
          </div>
        </Card>
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-[2fr,1fr] items-start gap-6">
            <Card>
              <Typography variant="h1" className="mb-6 text-dark">
                {t('welcome')} {user?.firstName}
              </Typography>
              <CandidateProfileForm />
            </Card>
            <ProgressCircle
              size="lg"
              percentage={accountInfo?.intakeProgress ?? 0}
            />
            <ProfileSummaryCard
              user={user as UserAccount<Candidate>}
              className="p-[40px_20px]"
            />
          </div>
        </div>
        <div className="flex flex-col gap-6"></div>
      </div>
    </Container>
  );
};
