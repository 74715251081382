import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Typography } from '@elements/Typography';
import { UrlInput } from '@elements/UrlInput';
import { Pdf as PdfIcon, Trash } from '@icons/index';
import { Status } from '@models/Status';
import { Vacancy } from '@models/Vacancy';
import { useSetVacancySource } from '@services/vacancies/set-vacancy-source';
import { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

export interface UploadVacancyProps {
  vacancy: Vacancy;
  onNext: () => void;
}
export const UploadVacancy = ({ vacancy, onNext }: UploadVacancyProps) => {
  const { t } = useTranslation('vacancy_intake', {
    keyPrefix: 'upload_vacancy'
  });
  const { t: tCommon } = useTranslation('common');
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { setVacancySource, isLoading } = useSetVacancySource(vacancy.id);
  const [url, setUrl] = useState(
    vacancy.sourceType === 'URL' ? vacancy.originalUrl : ''
  );
  const [file, setFile] = useState<File | null>();
  const [isChanged, setIsChanged] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (vacancy.sourceType === 'URL') {
      setUrl(vacancy.originalUrl ?? '');
    }
  }, [vacancy]);

  useEffect(() => {
    if (isSaving && vacancy.flags?.readStatus === Status.DONE) {
      onNext();
    }

    if (vacancy.flags?.readStatus === Status.BUSY) {
      setIsSaving(true);
    } else {
      setIsSaving(false);
    }
  }, [vacancy.flags?.readStatus]);

  return (
    <Card className="!p-6">
      <div>
        <Typography variant="h2" color="dark" className="!text-lg">
          {t('title')}
        </Typography>
        <Typography variant="body1" color="dark" className="mt-3">
          {t('description')}
        </Typography>
      </div>

      <div>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          accept="application/pdf"
          onChange={e => setFile(e.target.files?.[0] ?? undefined)}
        />
        <div className="mt-8 flex h-full items-center gap-4">
          <Button
            variant="info"
            size="xs"
            className="flex min-w-[200px] items-center font-bold"
            onClick={() => {
              setIsChanged(true);
              if (file) {
                setFile(null);
              } else {
                fileInputRef.current?.click();
                setUrl('');
              }
            }}
          >
            {file ? (
              <>
                <Trash className="mr-2 h-4 w-4 fill-error" />
                {t('upload_pdf_button_remove')}
              </>
            ) : (
              <>
                <PdfIcon className="mr-2 h-4 w-4" />
                {t('upload_pdf_button')}
              </>
            )}
          </Button>
          <Typography variant="body1" color="dark" className="">
            {t('or')}
          </Typography>
          <UrlInput
            placeholder={t('url_placeholder')}
            disabled={Boolean(file)}
            value={url}
            onChange={e => {
              setUrl(e.target.value);
              setIsChanged(e.target.value !== vacancy.originalUrl);
            }}
          />
        </div>
        {vacancy.flags?.readStatus === Status.ERROR && (
          <div className="mt-8 gap-4">
            <Typography variant="body1" color="error" className="!text-sm">
              <Trans i18nKey="vacancy_intake:upload_vacancy:read_error_message" />
            </Typography>
          </div>
        )}
        <div className="mt-8 flex h-full justify-end gap-4">
          <Button
            variant="secondary"
            size="sm"
            className="flex items-center font-bold"
            disabled={
              isLoading ||
              isSaving ||
              (!file && !url) ||
              (vacancy.flags?.readStatus === Status.ERROR && !isChanged)
            }
            onClick={() => {
              if (isChanged) {
                setIsSaving(true);
                setIsChanged(false);
                setVacancySource({
                  file: file ?? undefined,
                  url: url ?? undefined
                });
              } else if (vacancy.flags?.readStatus !== Status.ERROR) {
                onNext();
              }
            }}
          >
            {isLoading || isSaving ? (
              <>
                <ClipLoader size={12} color="#fff" className="mr-2" />
                {tCommon('reading')}
              </>
            ) : (
              <>{tCommon('next')}</>
            )}
          </Button>
        </div>
      </div>
    </Card>
  );
};
