import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { CollapseCard } from '@elements/CollapseCard';
import { Input } from '@elements/Input';
import { Typography } from '@elements/Typography';
import { AddIcon, Trash } from '@icons/index';
import { Status } from '@models/Status';
import { Vacancy } from '@models/Vacancy';
import { useVacancyPatch } from '@services/vacancies/patch-vacancy';
import { Colors } from '@utils/ColorUtils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
export interface VerifyVacancyDetailsProps {
  vacancy: Vacancy;
  onPrevious: () => void;
  onNext: () => void;
}

export const VerifyVacancyDetails = ({
  vacancy,
  onPrevious,
  onNext
}: VerifyVacancyDetailsProps) => {
  const { t } = useTranslation('vacancy_intake', {
    keyPrefix: 'verify_vacancy_details'
  });
  const { t: tCommon } = useTranslation('common');
  const [isSaving, setIsSaving] = useState(false);
  const { patch, loading } = useVacancyPatch(vacancy.id);

  const [requiredSkills, setRequiredSkills] = useState<string[]>(
    vacancy.requiredSkills || []
  );
  const [responsibilities, setResponsibilities] = useState<string[]>(
    vacancy.responsibilities || []
  );
  const [minSalary, setMinSalary] = useState(vacancy.salaryMin);
  const [maxSalary, setMaxSalary] = useState(vacancy.salaryMax);
  const [benefits, setBenefits] = useState<string[]>(vacancy.benefits || []);
  const [description, setDescription] = useState(vacancy.description);

  useEffect(() => {
    if (vacancy.flags?.readStatus == Status.ERROR) {
      onPrevious();
    }
  }, [vacancy]);

  const renderRequiredSkills = () => {
    return (
      <CollapseCard title={t('skills_and_tech_stack')}>
        <div className="flex flex-col gap-4">
          <ValidateList
            items={requiredSkills}
            onChange={(index, value) => {
              const newSkills = [...requiredSkills];
              newSkills[index] = value;
              setRequiredSkills(newSkills);
            }}
            onAdd={() => {
              const newSkills = [...requiredSkills];
              newSkills.push('');
              setRequiredSkills(newSkills);
            }}
            onRemove={index => {
              const newSkills = [...requiredSkills];
              newSkills.splice(index, 1);
              setRequiredSkills(newSkills);
            }}
          />
        </div>
      </CollapseCard>
    );
  };

  const renderResponsibilities = () => {
    return (
      <CollapseCard title={t('responsibilities')}>
        <div className="flex flex-col gap-4">
          <ValidateList
            items={responsibilities}
            onChange={(index, value) => {
              const newResponsibilities = [...responsibilities];
              newResponsibilities[index] = value;
              setResponsibilities(newResponsibilities);
            }}
            onAdd={() => {
              const newResponsibilities = [...responsibilities];
              newResponsibilities.push('');
              setResponsibilities(newResponsibilities);
            }}
            onRemove={index => {
              const newResponsibilities = [...responsibilities];
              newResponsibilities.splice(index, 1);
              setResponsibilities(newResponsibilities);
            }}
          />
        </div>
      </CollapseCard>
    );
  };

  const header = () => {
    return (
      <Card className="flex flex-col gap-2 py-8">
        <Typography variant="h5" color="dark" className="text-base">
          {t('reading_vacancy_done_title')}
        </Typography>
        <Typography variant="body1" color="dark" className="text-sm">
          {t('reading_vacancy_done_description')}
        </Typography>

        <div className="mt-3 flex justify-end gap-4">
          <Button
            variant="secondary"
            className="flex items-center"
            size="sm"
            onClick={async () => {
              setIsSaving(true);
              await patch({
                requiredSkills,
                responsibilities,
                salaryMin: minSalary,
                salaryMax: maxSalary,
                benefits,
                description,
                flags: {
                  ...vacancy.flags,
                  detailsValid: true
                }
              });
              onNext();
            }}
          >
            {isSaving && (
              <ClipLoader color={Colors.white} size={16} className="mr-2" />
            )}
            {tCommon('next')}
          </Button>
        </div>
      </Card>
    );
  };

  const renderSalaryAndBenefits = () => {
    return (
      <CollapseCard title={t('salary_and_benefits')}>
        <Typography variant="body1" color="dark" className="mb-4 text-sm">
          {t('salary_description')}
        </Typography>
        <div className="flex gap-4">
          <Input
            variant="compact"
            type="number"
            label={t('salary_min')}
            value={minSalary}
            onChange={e => setMinSalary(Number(e.target.value))}
          />
          <Input
            variant="compact"
            label={t('salary_max')}
            type="number"
            value={maxSalary}
            onChange={e => setMaxSalary(Number(e.target.value))}
          />
        </div>
        <div className="mt-8">
          <Typography variant="body1" color="dark" className="mb-4 text-sm">
            {t('other_benefits')}:
          </Typography>
          <ValidateList
            items={benefits}
            onChange={(index, value) => {
              const newBenefits = [...benefits];
              newBenefits[index] = value;
              setBenefits(newBenefits);
            }}
            onAdd={() => {
              const newBenefits = [...benefits];
              newBenefits.push('');
              setBenefits(newBenefits);
            }}
            onRemove={index => {
              const newBenefits = [...benefits];
              newBenefits.splice(index, 1);
              setBenefits(newBenefits);
            }}
          />
        </div>
      </CollapseCard>
    );
  };

  const renderDescription = () => {
    return (
      <CollapseCard title={t('vacancy_intro')} defaultOpen={true}>
        <Input
          variant="compact"
          label={t('vacancy_intro')}
          autoGrow
          defaultValue={description}
          rows={4}
          onChange={e => setDescription(e.target.value)}
        />
      </CollapseCard>
    );
  };

  return (
    <div className="flex flex-col gap-4 py-8">
      {header()}
      {renderDescription()}
      {renderRequiredSkills()}
      {renderResponsibilities()}
      {renderSalaryAndBenefits()}
    </div>
  );
};

const ValidateList = ({
  items,
  onChange,
  onAdd,
  onRemove
}: {
  items: string[];
  onChange: (index: number, value: string) => void;
  onAdd: () => void;
  onRemove: (index: number) => void;
}) => {
  return (
    <div className="flex flex-col gap-2">
      {items.map((item, index) => (
        <div key={index} className="flex items-center gap-2">
          <Input
            variant="compact"
            value={item}
            onChange={e => onChange(index, e.target.value)}
          />
          <Button
            variant="void"
            className="!p-0"
            onClick={() => onRemove(index)}
          >
            <Trash />
          </Button>
        </div>
      ))}
      <div className="mt-3 flex items-center justify-end gap-2">
        <Button variant="void" className="!p-0" onClick={onAdd}>
          <AddIcon color={Colors.secondary} width={24} height={24} />
        </Button>
      </div>
    </div>
  );
};
