import { Typography } from '@elements/Typography';
import {
  Bell,
  ChalkboardIcon,
  ChatIcon,
  MoneyIcon,
  ToolsIcon
} from '@icons/index';
import { useTranslation } from 'react-i18next';

export const FeaturesSection = () => {
  const { t } = useTranslation('home', {
    keyPrefix: 'features'
  });

  const features = [
    {
      title: t('notifications_title'),
      description: t('notifications_description'),
      icon: (
        <Bell
          className="text-secondary"
          fill="currentColor"
          width={20}
          height={20}
        />
      )
    },
    {
      title: t('resume_builder_title'),
      description: t('resume_builder_description'),
      icon: (
        <ToolsIcon
          className="text-secondary"
          fill="currentColor"
          width={20}
          height={20}
        />
      )
    },
    {
      title: t('salary_check_title'),
      description: t('salary_check_description'),
      icon: (
        <MoneyIcon
          className="text-secondary"
          fill="currentColor"
          width={20}
          height={20}
        />
      )
    },
    {
      title: t('job_coach_title'),
      description: t('job_coach_description'),
      icon: (
        <ChalkboardIcon
          className="text-secondary"
          fill="currentColor"
          width={20}
          height={20}
        />
      )
    },
    {
      title: t('interview_prep_title'),
      description: t('interview_prep_description'),
      icon: (
        <ChatIcon
          className="text-secondary"
          fill="currentColor"
          width={20}
          height={20}
        />
      )
    }
  ];
  return (
    <div>
      <Typography variant="h2" className="mb-10 text-center" color="dark">
        {t('title')}
      </Typography>
      <div className="grid grid-cols-1 justify-center gap-6 md:grid-cols-2 lg:grid-cols-5">
        {features.map(feature => (
          <div
            key={feature.title}
            className="flex w-full flex-col items-center justify-center rounded-[20px] bg-white px-4 py-6 shadow-[2px_4px_16px_0px_rgba(0,0,0,0.12)] md:px-8 md:py-10"
          >
            <div className="bg-light-blue mb-4 flex h-[50px] w-[50px] items-center justify-center rounded-full">
              {feature.icon}
            </div>
            <Typography
              variant="h5"
              color="dark"
              className="mb-5 h-[40px] text-center !font-[500]"
            >
              {feature.title}
            </Typography>
            <Typography
              variant="body1"
              className="line-clamp-3 h-[72px] text-center"
            >
              {feature.description}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
