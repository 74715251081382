import { CheckCircle } from '@icons/index';
import { Colors } from '@utils/ColorUtils';
import { formatDate } from '@utils/DateUtils';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import { Button } from './Button';
import { Calendar } from './Calendar';
import { Input } from './Input';
import OutsideAlerter from './OutsideAlerter';
export interface IDateInput {
  label?: string;
  defaultValue?: Date;
  className?: string;
  value?: Date;
  onAutoSave?: (date: Date) => Promise<void> | void;
  onChange?: (e: Date | null) => void;
  variant?: 'default' | 'compact';
  placeholder?: string;
  allowTodayButton?: boolean;
  onlyMonthYear?: boolean;
}

export const DateInput = ({
  label,
  className,
  onChange,
  value: propsValue,
  defaultValue,
  variant = 'default',
  onAutoSave,
  placeholder,
  onlyMonthYear = false,
  allowTodayButton = false,
  ...props
}: IDateInput) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('common');
  const [value, setValue] = useState(
    defaultValue
      ? onlyMonthYear
        ? moment(defaultValue).format('MMM YYYY')
        : formatDate(defaultValue, i18n.language)
      : ''
  );

  const [isFocused, setIsFocused] = useState(false);

  const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>(
    'bottom'
  );

  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [isAutoSaved, setIsAutoSaved] = useState(false);
  const [isAutoSavingTimeout, setIsAutoSavingTimeout] =
    useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (allowTodayButton && !propsValue && !value) {
      setValue(
        onlyMonthYear
          ? moment().format('MMM YYYY')
          : formatDate(moment().toDate(), i18n.language)
      );
    }
    if (propsValue && !value) {
      setValue(
        onlyMonthYear
          ? moment(propsValue).format('MMM YYYY')
          : formatDate(propsValue, i18n.language)
      );
    }
  }, [propsValue]);

  useEffect(() => {
    if (isFocused) {
      const input = document.querySelector(
        '[data-testid="date-input"]'
      ) as HTMLElement;
      if (input) {
        const inputRect = input.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const spaceBelow = viewportHeight - inputRect.bottom;
        const requiredSpace = 420; // height of calendar

        setDropdownPosition(spaceBelow >= requiredSpace ? 'bottom' : 'top');
      }
    }
  }, [isFocused]);

  const validChars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '/',
    '-'
  ];

  const isValidDate = (date: string) => {
    return (
      moment(date, 'DD-MM-YYYY', true).isValid() ||
      moment(date, 'MM-DD-YYYY', true).isValid() ||
      moment(date, 'DD/MM/YYYY', true).isValid() ||
      moment(date, 'MM/DD/YYYY', true).isValid()
    );
  };

  const handleAutoSave = async (date: Date) => {
    if (!onAutoSave) return;

    setIsAutoSaving(true);
    await onAutoSave(date);
    setIsAutoSaving(false);
    setIsAutoSaved(true);

    if (isAutoSavingTimeout) {
      clearTimeout(isAutoSavingTimeout);
    }
    const timeout = setTimeout(() => {
      setIsAutoSaved(false);
    }, 1000);
    setIsAutoSavingTimeout(timeout);
  };

  const handleChange = (date: Date | null) => {
    if (date) {
      setValue(
        onlyMonthYear
          ? moment(date).format('MMM YYYY')
          : formatDate(date, i18n.language)
      );
      if (onAutoSave) {
        handleAutoSave(date);
      }
      onChange && onChange(date);
    } else {
      setValue(
        onlyMonthYear
          ? moment().format('MMM YYYY')
          : formatDate(moment().toDate(), i18n.language)
      );
      onChange && onChange(null);
    }
  };

  const stringToDate = (date: string) => {
    if (onlyMonthYear) {
      const _date = moment(date, 'MMM YYYY', true).toDate();
      return _date;
    }
    date = date.replaceAll('/', '-');
    return moment(date, ['DD-MM-YYYY', 'MM-DD-YYYY'], true).toDate();
  };

  const autoSaveIndicator = useMemo(() => {
    if (isAutoSaving) {
      return (
        <div className="absolute right-4 top-1/2 -translate-y-1/2">
          <ClipLoader color={Colors.primary} size={16} />
        </div>
      );
    }

    if (isAutoSaved) {
      return (
        <div className="absolute right-4 top-1/2 -translate-y-1/2">
          <CheckCircle color={Colors.success} width={16} height={16} />
        </div>
      );
    }
  }, [isAutoSaving, isAutoSaved]);

  const calendarDefaultValue = useMemo(() => {
    if (onlyMonthYear) {
      return value ? moment(value, 'MMM YYYY', true).toDate() : undefined;
    }
    return value && isValidDate(value) ? stringToDate(value) : undefined;
  }, [onlyMonthYear, value]);

  const todaySelected = useMemo(() => {
    if (onlyMonthYear) {
      const startOfMonth = moment().startOf('month').toDate();
      const setValueDate = value ? stringToDate(value) : undefined;
      return (
        setValueDate &&
        setValueDate.toDateString() === startOfMonth.toDateString()
      );
    } else {
      const today = new Date();
      const setValueDate = value ? stringToDate(value) : undefined;
      return today.toDateString() === setValueDate?.toDateString();
    }
  }, [value]);

  const inputTextValue = useMemo(() => {
    if (allowTodayButton && todaySelected) {
      return t('to_date');
    }
    return value;
  }, [allowTodayButton, todaySelected, value]);
  return (
    <OutsideAlerter onClickOutside={() => setIsFocused(false)}>
      <div
        className={`relative ${onlyMonthYear ? 'hover:cursor-pointer' : ''}`}
        onClick={() => {
          if (onlyMonthYear) {
            setIsFocused(true);
          }
        }}
      >
        <Input
          label={label}
          data-testid="date-input"
          placeholder={placeholder}
          disabled={onlyMonthYear}
          className={`!bg-white ${className} `}
          onChange={e => {
            setValue(e.target.value);
            if (isValidDate(e.target.value)) {
              const date = stringToDate(e.target.value);
              setValue(formatDate(date, i18n.language));
              handleChange(date);
            }
          }}
          allowedChars={validChars}
          onFocus={() => setIsFocused(true)}
          value={inputTextValue}
          variant={variant}
          {...props}
        />
        {autoSaveIndicator}
        {isFocused && (
          <div
            className={`absolute z-[9999] ${
              allowTodayButton ? 'max-h-[400px]' : 'max-h-[340px]'
            } min-w-[300px] rounded-xl bg-white shadow-lg transition duration-200 ${
              dropdownPosition === 'top' ? 'bottom-full mb-1' : 'top-full mt-1'
            }`}
          >
            <Calendar
              data-testid="date-picker"
              locale={i18n.language}
              defaultValue={calendarDefaultValue ?? new Date()}
              onlyMonthYear={onlyMonthYear}
              onChange={e => {
                const formattedDate = formatDate(e, i18n.language);
                setValue(formattedDate);
                setIsFocused(false);
                handleChange(e);
              }}
            />
            {allowTodayButton && (
              <div className="mb-2 flex justify-center">
                <Button
                  variant={todaySelected ? 'secondary' : 'info'}
                  size="xs"
                  onClick={() => {
                    handleChange(null);
                    setIsFocused(false);
                  }}
                >
                  {t('to_date')}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </OutsideAlerter>
  );
};
