import { KoraApi } from '@services/api';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const useVerifyCallback = (secret?: string) => {
  const [previousSecret, setPreviousSecret] = useState<string | undefined>(
    undefined
  );
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  const verifyCallback = useCallback(async () => {
    setLoading(true);
    try {
      const resp = await KoraApi.post('/auth/verify', {
        secret
      });
      setData(resp.data);
      setSuccess(true);
      setError(false);
    } catch (error) {
      if (!success) {
        setSuccess(false);
        setError(true);
      }
    } finally {
      setPreviousSecret(secret);
      setLoading(false);
    }
  }, [secret]);

  useEffect(() => {
    if (secret && secret !== previousSecret) {
      verifyCallback();
    }
  }, [secret, previousSecret, verifyCallback]);

  return useMemo(() => {
    return {
      isSuccess: success,
      isLoading: loading,
      isError: error,
      data
    };
  }, [success, loading, error, data]);
};
