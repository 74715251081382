import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';

let socketInstance: Socket | null = null;

const COOLDOWN_MS = 1000; // 1 second cooldown

export const useSocket = (roomId: string, onMessage?: () => void) => {
  const messageHandlerRef = useRef(onMessage);
  const lastCallTimeRef = useRef<number>(0);

  useEffect(() => {
    messageHandlerRef.current = onMessage;
  }, [onMessage]);

  useEffect(() => {
    if (!socketInstance) {
      socketInstance = io(import.meta.env.VITE_API_URL, {
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        timeout: 20000
      });
    }

    // Setup reconnection handlers
    const handleDisconnect = (reason: string) => {
      console.log(`Socket disconnected: ${reason}`);
      // If the server disconnected us, we need to manually reconnect
      if (reason === 'io server disconnect') {
        socketInstance?.connect();
      }
    };

    const handleReconnect = (attemptNumber: number) => {
      console.log(`Socket reconnected after ${attemptNumber} attempts`);
      // Rejoin the room after reconnection
      if (roomId) {
        socketInstance?.emit('join', roomId);
      }
    };

    socketInstance.on('disconnect', handleDisconnect);
    socketInstance.on('reconnect', handleReconnect);

    if (roomId) {
      socketInstance.emit('join', roomId);
    }

    if (onMessage) {
      const handler = () => {
        const now = Date.now();
        if (now - lastCallTimeRef.current >= COOLDOWN_MS) {
          messageHandlerRef.current?.();
          lastCallTimeRef.current = now;
        }
      };

      socketInstance.on('message', handler);

      return () => {
        socketInstance?.off('message', handler);
        socketInstance?.off('disconnect', handleDisconnect);
        socketInstance?.off('reconnect', handleReconnect);
        if (roomId) {
          socketInstance?.emit('leave', roomId);
        }
      };
    }

    return () => {
      socketInstance?.off('disconnect', handleDisconnect);
      socketInstance?.off('reconnect', handleReconnect);
      if (roomId) {
        socketInstance?.emit('leave', roomId);
      }
    };
  }, [roomId]);

  return socketInstance;
};
