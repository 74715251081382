import countries from '@assets/countries.json';
import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Checkbox } from '@elements/Checkbox';
import { DateInput } from '@elements/DateInput';
import { EmailInput } from '@elements/EmailInput';
import { Input } from '@elements/Input';
import { PhoneInput } from '@elements/PhoneInput';
import { PlaceInput } from '@elements/PlaceInput';
import { Select } from '@elements/Select';
import { Typography } from '@elements/Typography';
import { UrlInput } from '@elements/UrlInput';
import { Candidate } from '@models/candidate';
import { useCandidatePatch } from '@services/user/use-candidate-patch';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

interface IProfileInformation {
  onNext?: () => void;
}

export const CandidateIntakeProfileInformation = ({
  onNext
}: IProfileInformation) => {
  const { user } = useAccountContext<Candidate>();
  const { t: tDisclaimer } = useTranslation('candidate_intake', {
    keyPrefix: 'profile_information'
  });
  const { t } = useTranslation('profile');
  const { t: tCommon } = useTranslation('common');
  const { patch } = useCandidatePatch();

  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [birthDate, setBirthDate] = useState(
    user?.accountInfo?.birthDate ?? undefined
  );
  const [address, setAddress] = useState(
    user?.accountInfo?.address ?? undefined
  );
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.accountInfo?.phone ?? undefined);
  const [linkedinProfile, setLinkedinProfile] = useState(
    user?.accountInfo?.linkedinUrl ?? undefined
  );
  const [nationality, setNationality] = useState<string | undefined>(
    user?.accountInfo?.countryCode ?? undefined
  );
  const [indRegistered, setIndRegistered] = useState(
    user?.accountInfo?.indRegistered ?? false
  );

  const isValid = useMemo(() => {
    return (
      firstName &&
      lastName &&
      birthDate &&
      email &&
      phone &&
      address &&
      nationality
    );
  }, [firstName, lastName, birthDate, email, address, nationality]);
  return (
    <div>
      <Typography
        variant="h4"
        color="dark"
        className="my-4 flex flex-col gap-1 text-center text-base font-bold"
      >
        <Trans>{tDisclaimer('disclaimer')}</Trans>
      </Typography>
      <Card className="p-4">
        <div className="grid grid-cols-2 gap-4">
          <Input
            label={t('first_name')}
            value={firstName}
            variant="compact"
            onChange={e => setFirstName(e.target.value)}
          />
          <Input
            label={t('last_name')}
            value={lastName}
            variant="compact"
            onChange={e => setLastName(e.target.value)}
          />
        </div>
        <div className="mt-4 flex flex-col gap-4">
          <DateInput
            label={t('date_of_birth')}
            value={birthDate}
            variant="compact"
            onChange={e => setBirthDate(e ?? undefined)}
          />
          <EmailInput
            label={t('email')}
            value={email}
            disabled={true}
            variant="compact"
            onChange={e => setEmail(e.target.value)}
          />
          <PhoneInput
            label={t('phone')}
            variant="compact"
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
          <UrlInput
            placeholder=" "
            label={t('linkedin_profile')}
            variant="compact"
            value={linkedinProfile}
            onChange={e => setLinkedinProfile(e.target.value)}
          />
          <PlaceInput
            defaultValue={address}
            inputProps={{ variant: 'compact', label: t('city') }}
            onSelect={_address => {
              setAddress(_address);
            }}
          />
          <Select
            label={t('nationality')}
            options={countries.map(country => ({
              label: country.nationality,
              value: country.alpha_2_code
            }))}
            value={nationality}
            onChange={value => {
              setNationality(value as string);
            }}
            direction="up"
          />
          {nationality && nationality !== 'NL' && (
            <div className="flex flex-col gap-2">
              <Typography variant="body1">{t('ind_registered')}</Typography>
              <div className="flex items-center gap-4">
                <Checkbox
                  checked={indRegistered}
                  label={tCommon('yes')}
                  onChange={() => setIndRegistered(true)}
                />
                <Checkbox
                  checked={!indRegistered}
                  label={tCommon('no')}
                  onChange={() => setIndRegistered(false)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="primary"
            size="sm"
            disabled={!isValid}
            onClick={async () => {
              await patch({
                firstName,
                lastName,
                birthDate,
                email,
                phone,
                linkedinUrl: linkedinProfile,
                address,
                countryCode: nationality,
                indRegistered
              });
              onNext?.();
            }}
          >
            {tCommon('next')}
          </Button>
        </div>
      </Card>
    </div>
  );
};
