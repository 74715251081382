import { Button } from '@elements/Button';
import { DateInput } from '@elements/DateInput';
import { Input } from '@elements/Input';
import { Trash } from '@icons/index';
import { WorkExperience } from '@models/WorkExperience';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface JobExperienceEditorProps {
  experience: WorkExperience;
  onDelete: () => void;
  onChange: (experience: WorkExperience) => void;
}

export const JobExperienceEditor = ({
  experience,
  onDelete,
  onChange
}: JobExperienceEditorProps) => {
  const { t } = useTranslation('candidate_intake', {
    keyPrefix: 'resume_intake.job_experience_editor'
  });

  const [company, setCompany] = useState(experience.company ?? '');
  const [title, setTitle] = useState(experience.title ?? '');
  const [startDate, setStartDate] = useState(experience.startDate);
  const [endDate, setEndDate] = useState<Date | null>(
    experience.endDate ?? null
  );
  const [description, setDescription] = useState(experience.description ?? '');

  return (
    <div className="flex w-full gap-4">
      <div className="flex flex-1 flex-col gap-4">
        <div className="mb-4 grid grid-cols-2 gap-4">
          <Input
            label={t('company_name')}
            value={company}
            variant="compact"
            onChange={e => {
              setCompany(e.target.value);
              experience.company = e.target.value;
              onChange(experience);
            }}
          />
          <Input
            label={t('job_title')}
            value={title}
            variant="compact"
            onChange={e => {
              setTitle(e.target.value);
              experience.title = e.target.value;
              onChange(experience);
            }}
          />
          <DateInput
            label={t('start_date')}
            value={startDate}
            variant="compact"
            onChange={e => {
              if (!e) return;
              setStartDate(e);
              experience.startDate = e;
              onChange(experience);
            }}
          />
          <DateInput
            label={t('end_date')}
            value={endDate ?? undefined}
            variant="compact"
            onChange={e => {
              setEndDate(e);
              experience.endDate = e;
              onChange(experience);
            }}
            allowTodayButton={true}
          />
        </div>
        <Input
          label={t('description')}
          value={description ?? ''}
          variant="compact"
          className="min-h-[100px]"
          autoGrow
          onChange={e => {
            setDescription(e.target.value);
            experience.description = e.target.value;
            onChange(experience);
          }}
        />
      </div>
      <div>
        <Button variant="void" size="sm" className="!p-0" onClick={onDelete}>
          <Trash />
        </Button>
      </div>
    </div>
  );
};
