import { ChatBubble } from '@components/chat';
import { Container } from '@elements/Container';
import { useBackground } from '@providers/BackgroundProvider';
import { About, useChat } from '@services/chat/use-chat';
import { ReactNode, useEffect, useRef } from 'react';
import { BeatLoader } from 'react-spinners';
import { ChatInput } from './ChatInput';

interface ChatBoxProps {
  about: About;
  className?: string;
  cta?: ReactNode;
}

export const ChatBox = ({ about, className, cta }: ChatBoxProps) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  useBackground(false);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

    //focus on the input
    inputRef.current?.focus();
  };

  const { messages, sendMessage, isAnswering } = useChat({
    onMessage: scrollToBottom,
    about
  });
  // get latest message by createdAt
  const latestMessage = messages?.sort(
    (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
  )[0];

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Container className={`flex-1 overflow-hidden ${className} !mt-0`}>
      <div className="flex h-full flex-col">
        <div className="flex flex-1 flex-col-reverse gap-4 overflow-auto pb-3">
          <div ref={messagesEndRef} />
          {isAnswering && (
            <div className="flex">
              <div className="max-w-[60%] rounded-[20px] bg-[#fff] p-[16px_24px] text-sm tracking-[0.6px] shadow-[2px_4px_4px_0px_rgba(0,0,0,0.08)]">
                <BeatLoader color="#171BAF" />
              </div>
            </div>
          )}
          {messages.map(message => (
            <ChatBubble
              key={message.id}
              messageId={message.id}
              message={message.message}
              translatable={message.translatable}
              quickActions={message.quickActions}
              isUser={message.senderType === 'USER'}
              isLast={message.id === latestMessage?.id}
              sendMessage={sendMessage}
            />
          ))}
        </div>
        {cta && <div className="mt-5">{cta}</div>}
        <div className="mt-5">
          <ChatInput
            inputRef={inputRef}
            isAnswering={isAnswering}
            onSend={sendMessage}
          />
        </div>
      </div>
    </Container>
  );
};
