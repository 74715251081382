import Markdown from 'react-markdown';
import { Typography } from './Typography';

export interface IMarkdownViewer {
  content: string;
  components?: any;
  className?: string;
}
export const MarkdownViewer = ({
  content,
  components: propsComponents,
  className
}: IMarkdownViewer) => {
  const components = {
    h1: ({ node, ...props }: any) => (
      <Typography
        variant="h3"
        className="mb-2 mt-3 !text-xl text-secondary"
        {...props}
      />
    ),
    h2: ({ node, ...props }: any) => (
      <Typography variant="h4" className="mt-3 !text-lg text-dark" {...props} />
    ),
    h3: ({ node, ...props }: any) => (
      <Typography
        variant="h5"
        className="mt-2 text-base text-dark"
        {...props}
      />
    ),
    ul: ({ node, ...props }: any) => (
      <ul className="mb-2 ml-4 list-disc" {...props} />
    ),
    li: ({ node, ...props }: any) => (
      <li className="my-1 leading-8" {...props} />
    ),
    pre: ({ node, ...props }: any) => (
      <div className="bg-gray-100 rounded-md p-2" {...props} />
    ),
    code: ({ node, ...props }: any) => <p className="" {...props} />,
    p: ({ node, ...props }: any) => (
      <Typography variant="body1" className="text-base" {...props} />
    )
  };
  return (
    <>
      <Markdown
        components={{ ...components, ...propsComponents }}
        className={`leading-5 ${className}`}
      >
        {content}
      </Markdown>
    </>
  );
};
