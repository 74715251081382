import { KoraApi } from '@services/api';

let lastCallTimestamp = 0;
const COOLDOWN_MS = 2000; // 2 seconds cooldown

export const useNewVacancy = async () => {
  const now = Date.now();
  if (now - lastCallTimestamp < COOLDOWN_MS) {
    return null;
  }

  lastCallTimestamp = now;
  const response = await KoraApi.get('/vacancies/new');
  return response.data;
};
