import { KoraApi } from '@services/api';
import { Colors } from '@utils/ColorUtils';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
export const RefreshTranslations = () => {
  const [searchParams] = useSearchParams();
  const apiKey = searchParams.get('apiKey');
  const navigate = useNavigate();
  if (!apiKey) {
    navigate('/');
  }
  useEffect(() => {
    const refreshTranslations = async () => {
      await KoraApi.get(
        '/admin/refresh-translations',
        {},
        {
          headers: {
            'x-api-key': apiKey
          }
        }
      );
      setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    };
    refreshTranslations();
  }, [apiKey]);

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <ClipLoader color={Colors.primary} size={100} />
    </div>
  );
};
