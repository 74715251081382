import { VacancySummary } from '@components/vacancies/VacancySummary';
import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { Divider } from '@elements/Divider';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import { Match, MatchingStatus } from '@models/Match';
import { useAnalyticsRecordCreate } from '@services/analytics/use-analytics-record-create';
import { useCandidateMatches } from '@services/candidate/use-candidate-matches';
import { usePatchCandidateMatch } from '@services/candidate/use-patch-candidate-match';
import { useVacancy } from '@services/vacancies/use-vacancy';
import { useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

export const VacancyDetailPage = () => {
  const { vacancyId } = useParams();
  const { matches, mutate } = useCandidateMatches({ vacancyId: vacancyId! });
  const match = matches?.[0];
  const { vacancy } = useVacancy(vacancyId!);
  const navigate = useNavigate();

  const { t } = useTranslation('recommended_jobs');
  const { create: createAnalyticsRecord } = useAnalyticsRecordCreate();

  useEffect(() => {
    if (vacancy && !vacancy.summaryUrl) {
      navigate(`/jobs`);
    }
  }, [vacancy]);

  const renderCTA = useMemo(() => {
    if (!match) return null;

    let cta = null;

    switch (match.status) {
      case MatchingStatus.MATCHED:
      case MatchingStatus.DECLINED:
      case MatchingStatus.APPLIED:
        cta = <MatchedCTA match={match} mutate={mutate} />;
        break;
      case MatchingStatus.INVITED:
        cta = <InvitedCTA match={match} mutate={mutate} />;
        break;
    }

    return cta;
  }, [match, mutate]);

  const Body = () => {
    const address = vacancy?.address ?? vacancy?.employer?.address;
    return (
      <>
        <div className="flex gap-8">
          <div className="flex w-full flex-col justify-between gap-8">
            <div className="grid grid-cols-[auto_250px] items-center gap-2">
              <Typography
                variant="h1"
                className={`mb-0 line-clamp-2 text-xl font-bold leading-[3rem] ${
                  vacancy?.title ? '' : 'italic'
                }`}
                enableTooltip
                tooltipPlacement="top"
              >
                {vacancy?.title}
              </Typography>
              {vacancy?.originalUrl && vacancy?.isScraped && (
                <Link
                  to={vacancy.originalUrl.trim()}
                  target="_blank"
                  className="min-w-[100px] justify-self-end"
                >
                  <Button
                    variant="info"
                    size="sm"
                    onClick={() =>
                      createAnalyticsRecord('VISIT_ORIGINAL_VACANCY', {
                        vacancyId: vacancy.id
                      })
                    }
                  >
                    {t('visit_original_website')}
                  </Button>
                </Link>
              )}
            </div>
            <div>
              <Link
                to={`/companies/${vacancy?.employer?.id}`}
                className="no-underline"
              >
                <Typography variant="h2" className="text-secondary">
                  {vacancy?.employer?.companyName}
                </Typography>
              </Link>
              {address && (
                <Typography variant="h4" className="text-base font-normal">
                  {address?.city}, {address?.country}
                </Typography>
              )}
            </div>
          </div>
        </div>
        <Divider className="mb-6 mt-2 w-full" />
        {renderCTA}
        <div className="mb-4 flex"></div>
        <Card>{vacancy && <VacancySummary vacancy={vacancy} />}</Card>
      </>
    );
  };

  return (
    <ContainerWithBackButton loading={!vacancy}>
      <MetaTags title={vacancy?.title ?? 'Vacancy Detail'} />
      <Body />
    </ContainerWithBackButton>
  );
};

const InvitedCTA = ({
  match,
  mutate
}: {
  match: Match;
  mutate: () => void;
}) => {
  const { t } = useTranslation('recommended_jobs');
  const { patchWithConfirm } = usePatchCandidateMatch(match.id!);
  const navigate = useNavigate();

  return (
    <div>
      <Trans>
        {t('interview_disclaimer', {
          employerName: match.vacancy?.employer?.companyName
        })}
      </Trans>
      <div className="mt-4 flex gap-3">
        <Button
          variant="secondary"
          size="sm"
          onClick={async () => {
            await patchWithConfirm({
              status: MatchingStatus.INTERVIEW,
              title: t('accepted_interview'),
              description: t('accept_interview_confirm_description'),
              onSuccess: () => {
                mutate();
                navigate(`/funnel`);
              }
            });
          }}
        >
          {t('accepted_interview')}
        </Button>
        <Button
          variant="info"
          size="sm"
          disabled={match.status === MatchingStatus.DECLINED}
          onClick={async () => {
            await patchWithConfirm({
              status: MatchingStatus.DECLINED_INTERVIEW_BY_CANDIDATE,
              title: t('declined_interview'),
              description: t('decline_confirm_description'),
              onSuccess: () => mutate()
            });
          }}
        >
          {t('declined_interview')}
        </Button>
      </div>
    </div>
  );
};

const MatchedCTA = ({
  match,
  mutate
}: {
  match: Match;
  mutate: () => void;
}) => {
  const { t } = useTranslation('vacancies');
  const { t: tRecommendedJobs } = useTranslation('recommended_jobs');
  const { patchWithConfirm } = usePatchCandidateMatch(match.id!);
  const navigate = useNavigate();
  const { create: createAnalyticsRecord } = useAnalyticsRecordCreate();
  const applyButton = () => {
    return (
      <Button
        variant="secondary"
        size="sm"
        disabled={
          match.status != MatchingStatus.MATCHED &&
          match.status != MatchingStatus.DECLINED
        }
        onClick={async () => {
          await patchWithConfirm({
            status: MatchingStatus.APPLIED,
            title: match.vacancy.isScraped
              ? tRecommendedJobs('apply_check_title')
              : tRecommendedJobs('apply_confirm_title'),
            description: match.vacancy.isScraped
              ? tRecommendedJobs('apply_check_description')
              : tRecommendedJobs('apply_confirm_description'),
            onSuccess: () => {
              mutate();
              navigate(`/funnel`);
            }
          });
        }}
      >
        {t('apply')}
      </Button>
    );
  };
  return (
    <div className="flex gap-3">
      <Button
        variant="info"
        size="sm"
        disabled={match.status === MatchingStatus.DECLINED}
        onClick={async () => {
          await patchWithConfirm({
            status: MatchingStatus.DECLINED,
            title: tRecommendedJobs('decline_confirm_title'),
            description: tRecommendedJobs('decline_confirm_description'),
            onSuccess: () => mutate()
          });
        }}
      >
        {t('decline')}
      </Button>
      {match.vacancy.isScraped && match.vacancy.originalUrl ? (
        <Link
          to={match.vacancy.originalUrl}
          target="_blank"
          onClick={() =>
            createAnalyticsRecord('VISIT_ORIGINAL_VACANCY', {
              vacancyId: match.vacancy.id
            })
          }
        >
          {applyButton()}
        </Link>
      ) : (
        applyButton()
      )}
    </div>
  );
};
