import { Match, MatchingStatus } from '@models/Match';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

interface Funnel {
  [MatchingStatus.APPLIED]: Match[];
  [MatchingStatus.DECLINED]: Match[];
  [MatchingStatus.INVITED]: Match[];
  [MatchingStatus.REJECTED]: Match[];
  [MatchingStatus.INTERVIEW]: Match[];
  total: number;
}

export const CandidateFunnelStatuses = [
  MatchingStatus.APPLIED,
  MatchingStatus.DECLINED,
  MatchingStatus.INVITED,
  MatchingStatus.REJECTED,
  MatchingStatus.INTERVIEW
] as const;

export type CandidateFunnelStatus = (typeof CandidateFunnelStatuses)[number];
export interface CandidateFunnelArgs {
  vacancyFilter?: string[];
  search?: string;
  stateFilter?: MatchingStatus[];
  archived?: boolean;
}
export const useCandidateFunnel = (args?: CandidateFunnelArgs) => {
  const [url, setUrl] = useState(`/candidate/funnel`);
  const { data, ...swr } = useSWR(url);

  useEffect(() => {
    let url = `/candidate/funnel?`;
    if (args?.vacancyFilter && args.vacancyFilter.length > 0) {
      url += `vacancyIds=${args.vacancyFilter.join(',')}`;
    }
    if (args?.search && args.search.length > 0) {
      url += `&search=${args.search}`;
    }
    if (args?.stateFilter && args.stateFilter.length > 0) {
      url += `&states=${args.stateFilter.join(',')}`;
    }
    if (args?.archived) {
      url += `&archived=${args.archived}`;
    }
    setUrl(url);
  }, [args?.vacancyFilter, args?.search, args?.stateFilter, args?.archived]);

  const matches: Match[] = data?.map((match: any) => {
    return Match.fromJson(match);
  });

  const funnel: Funnel = {
    [MatchingStatus.APPLIED]: [],
    [MatchingStatus.DECLINED]: [],
    [MatchingStatus.INVITED]: [],
    [MatchingStatus.REJECTED]: [],
    [MatchingStatus.INTERVIEW]: [],
    total: matches?.length || 0
  };

  matches?.forEach(match => {
    let status = match.status;
    if (status === MatchingStatus.DECLINED_INTERVIEW_BY_CANDIDATE) {
      status = MatchingStatus.DECLINED;
    }
    if (status === MatchingStatus.DECLINED_INTERVIEW_BY_EMPLOYER) {
      status = MatchingStatus.REJECTED;
    }
    if (
      status !== MatchingStatus.MATCHED &&
      status !== MatchingStatus.JOB_TAKEN
    ) {
      funnel[status].push(match);
    }
  });

  return {
    funnel,
    ...swr
  };
};
