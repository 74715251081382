import { ChevronDown, ChevronUp } from '@icons/index';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { Divider } from './Divider';
import OutsideAlerter from './OutsideAlerter';

export interface SelectProps {
  options: { label: string; value: string }[];
  placeholder?: string;
  selected?: string;
  onSelect?: (option: { label: string; value: string } | null) => void;
}

export const DropdownButton = ({
  options,
  placeholder,
  selected: selectedValue,
  onSelect
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const currentLabel =
    selectedOption?.label ?? placeholder ?? options?.[0]?.label ?? 'All';
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('common');
  useEffect(() => {
    if (selectedValue) {
      const option = options.find(option => option.value === selectedValue);
      if (option) {
        setSelectedOption(option);
      }
    }
  }, [selectedValue]);

  const handleSelectOption = (option: { label: string; value: string }) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect?.(option);
  };

  return (
    <div className="relative">
      <Button
        variant="info"
        size="sm"
        className="flex items-center gap-2 px-3"
        onClick={() => {
          setTimeout(() => {
            if (!isOpen) {
              setIsOpen(true);
            }
          }, 100);
        }}
      >
        <p className="max-w-[400px] truncate">{currentLabel}</p>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </Button>
      {isOpen && (
        <OutsideAlerter
          onClickOutside={() => {
            setIsOpen(false);
          }}
        >
          <div
            onClick={() => setIsOpen(false)}
            className="absolute left-[-5px] top-[40px] w-auto min-w-full rounded-[20px] bg-white p-[16px_24px] shadow-[0px_4px_8px_0px_rgba(0,0,0,0.08),0px_-2px_8px_0px_rgba(0,0,0,0.08)]"
          >
            {options.map(option => (
              <React.Fragment key={option.value}>
                <Button
                  variant="info"
                  size="sm"
                  className="w-full whitespace-nowrap border-none bg-transparent !p-0 text-left"
                  key={option.value}
                  onClick={() => handleSelectOption(option)}
                >
                  {option.label}
                </Button>
                <Divider className="my-2" />
              </React.Fragment>
            ))}
            <div className="">
              <Button
                variant="info"
                size="sm"
                className="mb-3 w-full border-none bg-transparent !p-0 text-left uppercase text-primary"
                onClick={() => {
                  setSelectedOption(null);
                  setIsOpen(false);
                  onSelect?.(null);
                }}
              >
                {t('clear_filter')}
              </Button>
            </div>
          </div>
        </OutsideAlerter>
      )}
    </div>
  );
};
