import { RoundedContainer } from '@components/RoundedContainer';
import { useEffect, useState } from 'react';
import { Button } from './Button';
import OutsideAlerter from './OutsideAlerter';

interface PopupProps {
  children: React.ReactNode;
  isOpen: boolean;
  closeButton?: boolean;
  onClose?: () => void;
}

export const Popup = ({
  children,
  isOpen: propsIsOpen,
  closeButton = true,
  onClose
}: PopupProps) => {
  const [isOpen, setIsOpen] = useState(propsIsOpen);

  useEffect(() => {
    setIsOpen(propsIsOpen);
  }, [propsIsOpen]);

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  return (
    <>
      {isOpen && (
        <div
          className="fixed left-0 top-0 z-[99] flex h-full w-full items-center justify-center bg-black/50"
          data-testid="popup-cta"
          style={{ zIndex: 9999 }}
        >
          <OutsideAlerter onClickOutside={handleClose}>
            <RoundedContainer className="max-w-full animate-fade-in bg-white p-[40px] text-center">
              {closeButton && (
                <Button
                  onClick={handleClose}
                  className="absolute right-0 top-0 border-none !bg-transparent"
                >
                  X
                </Button>
              )}
              {children}
            </RoundedContainer>
          </OutsideAlerter>
        </div>
      )}
    </>
  );
};
