import { CountdownBar } from '@elements/CountdownBar';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import { CheckCircle, ErrorCircle } from '@icons/index';
import { useAccountContext } from '@providers/UserContext';
import { useVerifyCallback } from '@services/auth/use-verify-callback';
import { Colors } from '@utils/ColorUtils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const VerifyPage = () => {
  const { secret } = useParams();
  const { mutate } = useAccountContext();
  const { isSuccess, isLoading, isError, data } = useVerifyCallback(secret);
  const { t } = useTranslation('register');
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const navigate = useNavigate();
  const timeout = 3000;

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    if (isSuccess && data) {
      const _timer = setTimeout(async () => {
        await mutate();
        navigate('/');
        window.location.reload();
      }, timeout);
      setTimer(_timer);
    }
  }, [isSuccess, data]);

  return (
    <>
      <MetaTags title="Verify" />
      <div className="mt-[-2%] flex h-screen w-full flex-col items-center justify-center">
        {isLoading && <ClipLoader color={Colors.PRIMARY} />}
        {isSuccess && (
          <div className="flex flex-col items-center gap-6">
            <CheckCircle
              color={Colors.success}
              width={'3rem'}
              height={'3rem'}
            />
            <Typography variant="h2" className="!w-[70%] text-center">
              {t('verification_success')}
            </Typography>
            <CountdownBar duration={timeout} className="mt-2" />
          </div>
        )}
        {isError && (
          <div className="flex flex-col items-center gap-6">
            <ErrorCircle color={Colors.error} width={'3rem'} height={'3rem'} />
            <Typography variant="h2" className="!w-[70%] text-center">
              {t('verification_failed')}
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};
