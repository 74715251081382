import { Input } from '@elements/Input';

import { DateInput } from '@elements/DateInput';
import { EmailInput } from '@elements/EmailInput';
import { PhoneInput } from '@elements/PhoneInput';
import { PlaceInput } from '@elements/PlaceInput';
import { Candidate } from '@models/candidate';
import { useAccountContext } from '@providers/UserContext';
import { useCandidatePatch } from '@services/user/use-candidate-patch';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CandidateProfileForm = () => {
  const { user } = useAccountContext<Candidate>();
  const { t } = useTranslation('profile');
  const { patch } = useCandidatePatch();
  const [phone, setPhone] = useState(user?.accountInfo?.phone ?? undefined);
  return (
    <>
      <div className="grid grid-cols-[1fr,1fr] gap-[24px] py-[24px]">
        <Input
          label={t('first_name')}
          variant="compact"
          onBlur={e => {
            patch({ firstName: e.target.value });
          }}
          defaultValue={user?.firstName}
        />
        <Input
          label={t('last_name')}
          variant="compact"
          defaultValue={user?.lastName}
          onBlur={e => {
            patch({ lastName: e.target.value });
          }}
        />
        <EmailInput
          label={t('email')}
          disabled={user?.authProvider !== 'email'}
          variant="compact"
          defaultValue={user?.email}
          onBlur={e => {
            patch({ email: e.target.value });
          }}
        />
        <PhoneInput
          label={t('phone')}
          variant="compact"
          value={phone}
          onAutoSave={phone => {
            console.log('onAutoSave', phone);
            patch({ phone });
          }}
          onChange={e => {
            setPhone(e.target.value);
          }}
        />
        <DateInput
          variant="compact"
          label={t('date_of_birth')}
          defaultValue={user?.accountInfo?.birthDate ?? undefined}
          onAutoSave={date => {
            patch({ birthDate: date });
          }}
        />
        <PlaceInput
          defaultValue={user?.accountInfo?.address ?? undefined}
          inputProps={{ variant: 'compact', label: t('city') }}
          onAutoSave={_address => {
            patch({ address: _address });
          }}
        />
      </div>
    </>
  );
};
