export enum SkillType {
  PROGRAMMING = 'PROGRAMMING',
  DATABASES = 'DATABASES',
  ANALYTICS = 'ANALYTICS',
  DATA_SCIENCE = 'DATA_SCIENCE',
  TESTING = 'TESTING',
  SYSTEM_ADMINISTRATION = 'SYSTEM_ADMINISTRATION',
  TOOLS_AND_FRAMEWORKS = 'TOOLS_AND_FRAMEWORKS',
  NETWORKING = 'NETWORKING',
  DEVOPS = 'DEVOPS',
  OPERATING_SYSTEMS = 'OPERATING_SYSTEMS',
  LANGUAGES = 'LANGUAGES',
  OTHER = 'OTHER'
}

export class Skill {
  constructor(
    public id?: string,
    public type?: SkillType,
    public stack?: string,
    public yearsOfExperience?: number
  ) {}

  public static fromJson(json: any): Skill {
    return new Skill(json.id, json.type, json.stack, json.yearsOfExperience);
  }

  public isValid(): boolean {
    return Boolean(
      this.type &&
        this.stack &&
        this.yearsOfExperience !== undefined &&
        this.yearsOfExperience !== null
    );
  }
}
