import { AccountType } from '@models/UserAccount';
import { HomePage } from '@pages/Home';
import { LoadingPage } from '@pages/Loading';
import { NotFoundPage } from '@pages/NotFound';
import { NotificationsPage } from '@pages/Notifications';
import { PrivacyPage } from '@pages/Privacy';
import { ReferPage as DefaultReferPage } from '@pages/Refer';
import { RefreshTranslations } from '@pages/RefreshTranslations';
import { SignUpsPage } from '@pages/SignUps';
import { LoginPage, RegisterPage, VerifyPage } from '@pages/auth';
import { ActivateAccountPage } from '@pages/auth/ActivateAccountPage';
import { ForgotPasswordPage } from '@pages/auth/ForgotPassword';
import { ResetPasswordPage } from '@pages/auth/ResetPasswordPage';
import { LinkedInResumeBlogPage } from '@pages/blog/LinkedInResumeBlog';
import {
  CandidateFunnelPage,
  CandidateVacancyDetailPage,
  ProfilePage,
  RecommendedJobsPage
} from '@pages/candidate';
import { CandidateIntakePage } from '@pages/candidate/CandidateIntake';
import {
  EmployerDashboardPage,
  EmployerFunnelPage,
  EmployerIntakePage,
  ReferPage as EmployerReferPage,
  InviteCandidatePage,
  MatchDetailPage,
  SettingsPage,
  VacanciesPage,
  VacancyCreatePage,
  VacancyDetailPage,
  VacancyIntakePage
} from '@pages/employer';
import { CompanyDetailPage } from '@pages/employer/CompanyDetail';
import React from 'react';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import { Route, Routes } from 'react-router-dom';
import { CookiePolicyPage } from './pages/CookiePolicy';
import { TermsAndConditions } from './pages/TermsAndConditions';
import { useAccountContext } from './providers/UserContext';
export const KoraRoutes = () => {
  const { user, isLoading } = useAccountContext();

  const employerRoutes = () => {
    return (
      <React.Fragment>
        <Route path="/" element={<EmployerDashboardPage />} />
        <Route path="/intake" element={<EmployerIntakePage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/vacancies" element={<VacanciesPage />} />
        <Route path="/vacancies/create" element={<VacancyCreatePage />} />
        <Route
          path="/vacancies/:vacancyId/intake"
          element={<VacancyIntakePage />}
        />
        <Route path="/vacancies/:vacancyId" element={<VacancyDetailPage />} />
        <Route path="/refer" element={<EmployerReferPage />} />
        <Route path="/funnel" element={<EmployerFunnelPage />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/matches/:matchId" element={<MatchDetailPage />} />
        <Route
          path="/matches/:matchId/invite"
          element={<InviteCandidatePage />}
        />
      </React.Fragment>
    );
  };

  const candidateRoutes = () => {
    return (
      <React.Fragment>
        <Route path="/intake" element={<CandidateIntakePage />} />
        <Route path="/jobs" element={<RecommendedJobsPage />} />
        <Route
          path="/jobs/:vacancyId"
          element={<CandidateVacancyDetailPage />}
        />
        <Route path="/funnel" element={<CandidateFunnelPage />} />
        <Route path="/notifications" element={<NotificationsPage />} />
        <Route path="/" element={<CandidateFunnelPage />} />
      </React.Fragment>
    );
  };
  if (isLoading)
    return (
      <Routes>
        <Route path="*" element={<LoadingPage />} />
      </Routes>
    );

  return (
    <Routes>
      {!user && <Route path="/" element={<HomePage />} />}
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/register/linkedin" element={<LinkedInCallback />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route
        path="/activate-account/:secret"
        element={<ActivateAccountPage />}
      />
      <Route path="/reset-password/:secret" element={<ResetPasswordPage />} />
      <Route path="/verify/:secret" element={<VerifyPage />} />
      <Route path="/profile" element={<ProfilePage />} />

      {user?.accountType === AccountType.EMPLOYER && employerRoutes()}
      {user?.accountType === AccountType.CANDIDATE && candidateRoutes()}
      <Route path="*" element={<NotFoundPage />} />
      <Route path="/refer" element={<DefaultReferPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/signups" element={<SignUpsPage />} />
      <Route path="/refresh-translations" element={<RefreshTranslations />} />
      <Route
        path="/blog/linkedin-resume"
        element={<LinkedInResumeBlogPage />}
      />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/cookie-policy" element={<CookiePolicyPage />} />
      <Route
        path="/vacancies/:vacancyId"
        element={<CandidateVacancyDetailPage />}
      />
      <Route path="/companies/:companyId" element={<CompanyDetailPage />} />
    </Routes>
  );
};
