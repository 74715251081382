/** @type {import('tailwindcss').Config} */
export default {
  content: ['./src/**/*.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      keyframes: {
        'fade-in': {
          '0%': { opacity: '0', transform: 'translateY(50px)' },
          '100%': { opacity: '1', transform: 'translateY(0)' }
        },
        gelatine: {
          'from, to': { transform: 'scale(1, 1)' },
          '25%': { transform: 'scale(0.9, 1.1)' },
          '75%': { transform: 'scale(0.95, 1.05)' }
        },
        'kora-pulse': {
          from: { transform: 'scale(1)' },
          to: { transform: 'scale(1.15)' }
        },
        raise: {
          '0%': { transform: 'translateY(0)' },
          '100%': { transform: 'translateY(-10px)' }
        },
        'slide-down': {
          '0%': { marginTop: '-100%' },
          '100%': { marginTop: '0' }
        },
        'slide-up': {
          '0%': { marginTop: '0' },
          '100%': { marginTop: '-100%' }
        },
        wheel: {
          to: {
            opacity: 0,
            top: '70%'
          }
        }
      },
      animation: {
        'fade-in': 'fade-in 2s ease 0s 1 forwards',
        gelatine: 'gelatine 2s infinite',
        wheel: 'wheel 2s infinite',
        'kora-pulse': 'kora-pulse 0.5s infinite ease-in-out alternate',
        raise: 'raise 0.3s ease-in-out forwards',
        'slide-down': 'slide-down 0.5s ease-in-out forwards',
        'slide-up': 'slide-up 0.5s ease-in-out forwards'
      },
      colors: {
        primary: '#171BAF',
        secondary: '#3B55F6',
        success: '#04b419',
        error: '#ED4337',
        placeholder: '#444',
        gray: '#c5c5c5',
        'dark-gray': '#363636',
        dark: '#016',
        default: '#121212',
        light: '#00000080',
        'light-blue': '#E7ECFD',
        'light-gray': '#D9D9D9'
      },
      fontSize: {
        '4xl': {
          sm: '2rem',
          md: '2.5rem'
        }
      }
    }
  },
  plugins: []
};
