import { UserAccount } from '@models/UserAccount';
import { KoraApi } from '@services/api';
import useSWR from 'swr';

const meFetcher = async (url: string) => {
  await KoraApi.autoRefreshToken();
  const response = await KoraApi.get(url);
  return response.data;
};

const useAccount = () => {
  const { data, error, isLoading, mutate } = useSWR('/account', meFetcher, {
    refreshInterval: 10000,
    shouldRetryOnError: false // Prevent retrying on error responses
  });
  if (!data) return { user: null, error, isLoading, mutate };

  return { user: UserAccount.fromJson(data), error, isLoading, mutate };
};

export default useAccount;
