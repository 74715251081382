import { useToast } from '@elements/Toast';
import { Candidate } from '@models/candidate';
import { Education } from '@models/Education';
import { Status } from '@models/Status';
import { useAccountContext } from '@providers/UserContext';
import { KoraApi } from '@services/api';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { v4 as uuidv4 } from 'uuid';
export const useCandidateEducations = () => {
  const { data, ...swr } = useSWR('/candidate/educations');

  const educations: Education[] = data?.map((education: any) =>
    Education.fromJson(education)
  );

  return {
    educations,
    ...swr
  };
};

export const useUpdateCandidateEducations = () => {
  const { mutate, educations, ...swr } = useCandidateEducations();
  const { user } = useAccountContext<Candidate>();
  const [isSaving, setIsSaving] = useState(false);
  const { error: errorToast } = useToast();
  const { t } = useTranslation('candidate_intake', {
    keyPrefix: 'resume_intake.education_editor'
  });
  const [changedEducations, setChangedEducations] = useState<Education[]>([]);
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    if (!isMounted && educations && educations.length > 0) {
      setChangedEducations(educations);
      setIsMounted(true);
    }
  }, [educations]);

  useEffect(() => {
    const status = user?.accountInfo?.resume?.status;
    if (status === Status.VERIFIED || status === Status.DONE) {
      setIsMounted(false);
      mutate();
    }
  }, [user]);

  const enhancedMutate = () => {
    mutate();
    setChangedEducations([]);
  };

  const pushChanges = async () => {
    setIsSaving(true);
    try {
      await KoraApi.put('/candidate/educations', sanitize());
      setIsSaving(false);
      enhancedMutate();
    } catch (error) {
      setIsSaving(false);
      errorToast(t('error'));
      throw error;
    }
  };

  const sanitize = () => {
    const _educations = changedEducations.map(education => {
      if (education.id?.startsWith('temp-')) {
        education.id = undefined;
      }
      return education;
    });

    return _educations;
  };

  const isValid = useMemo(() => {
    return changedEducations?.every(education => education.isValid());
  }, [changedEducations]);

  const memoizedEducations = useMemo(() => {
    return changedEducations;
  }, [changedEducations]);

  const addEducation = () => {
    const newEducation = new Education(`temp-${uuidv4()}`);
    setChangedEducations([...changedEducations, newEducation]);
  };

  const deleteEducation = (education: Education) => {
    const index = changedEducations.findIndex(e => e.id === education.id);
    if (index !== -1) {
      const updated = [...changedEducations];
      updated.splice(index, 1);
      setChangedEducations(updated);
    }
  };

  const onChange = (education: Education) => {
    const index = changedEducations.findIndex(e => e.id === education.id);
    if (index !== -1) {
      const updated = [...changedEducations];
      updated[index] = education;
      setChangedEducations(updated);
    } else {
      setChangedEducations([...changedEducations, education]);
    }
  };
  return {
    pushChanges,
    mutate: enhancedMutate,
    educations: memoizedEducations,
    setChangedEducations,
    isSaving,
    isValid,
    addEducation,
    deleteEducation,
    onChange,
    ...swr
  };
};
