import { BackButton } from '@elements/BackButton';
import { Button } from '@elements/Button';
import { Divider } from '@elements/Divider';
import { EmailInput } from '@elements/EmailInput';
import { Select } from '@elements/Select';
import { Typography } from '@elements/Typography';
import { Employer } from '@models/Employer';
import { useAccountContext } from '@providers/UserContext';
import { useMemberCreate } from '@services/employer/use-employer-member-create';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
export const SettingsMembers = () => {
  const { user } = useAccountContext<Employer>();
  const employer = user?.accountInfo;
  const { t } = useTranslation('settings', { keyPrefix: 'members' });
  const [isAddingMember, setIsAddingMember] = useState(false);

  const MembersTable = () => {
    return (
      <>
        <div className="grid grid-cols-3 gap-x-4 gap-y-2">
          <Typography variant="body1" className="font-semibold">
            {t('user')}
          </Typography>
          <Typography variant="body1" className="font-semibold">
            {t('role')}
          </Typography>
          <Typography variant="body1" className="font-semibold">
            {t('state')}
          </Typography>
          <Divider className="col-span-3" />
          {employer?.members?.map(member => (
            <React.Fragment key={member.id}>
              <Typography variant="body1">{member.email}</Typography>
              <Typography variant="body1">
                {t(member.role.toLowerCase())}
              </Typography>
              <Typography variant="body1">
                {t(member.state.toLowerCase())}
              </Typography>
            </React.Fragment>
          ))}
        </div>
        <div className="mt-auto flex justify-end">
          <Button
            variant="info"
            size="sm"
            onClick={() => setIsAddingMember(true)}
          >
            {t('invite_new')}
          </Button>
        </div>
      </>
    );
  };

  const AddMemberForm = () => {
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('admin');
    const { createMember, isLoading } = useMemberCreate();
    const { t } = useTranslation('settings', { keyPrefix: 'members' });

    return (
      <div className="flex flex-col gap-4">
        <BackButton onClick={() => setIsAddingMember(false)} />
        <div className="flex flex-col gap-4">
          <EmailInput
            variant="compact"
            value={email}
            onChange={e => setEmail(e.target.value)}
            label={t('email')}
          />
          <Select
            value={role}
            disabled
            onChange={e => e && setRole(e.toString())}
            options={[
              { label: 'Admin', value: 'admin' },
              { label: 'Member', value: 'member' }
            ]}
          />
        </div>
        <div className="mt-auto flex justify-end">
          <Button
            variant="info"
            className="flex items-center gap-2"
            size="sm"
            disabled={!email}
            onClick={() => createMember(email)}
          >
            {isLoading && <ClipLoader size={16} />}
            {t('invite')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="flex min-h-[300px] flex-col">
      {isAddingMember ? <AddMemberForm /> : <MembersTable />}
    </div>
  );
};
