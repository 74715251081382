import { Vacancy } from '@models/Vacancy';
import { KoraApi } from '@services/api';
import { useMemo, useState } from 'react';

export const useVacancyPatch = (vacancyId: string) => {
  const [loading, setLoading] = useState(false);
  const patch = async (data: Partial<Vacancy>) => {
    setLoading(true);
    if (!vacancyId) {
      throw new Error('Vacancy ID is required');
    }

    const response = await KoraApi.patch(`/vacancies/${vacancyId}`, data);
    setLoading(false);
    return response.data;
  };

  return useMemo(() => ({ patch, loading }), [vacancyId, loading, patch]);
};
