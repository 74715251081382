import { CompanySummary } from '@components/employer/CompanySummary';
import { Container } from '@elements/Container';
import { MetaTags } from '@elements/MetaTags';
import { useEmployer } from '@services/employer/use-employer';
import { Colors } from '@utils/ColorUtils';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const CompanyDetailPage = () => {
  const { companyId } = useParams();
  const { employer, isLoading } = useEmployer(companyId!);

  if (isLoading) {
    return (
      <Container className="flex h-full flex-col items-center gap-4">
        <ClipLoader color={Colors.primary} size={100} />
      </Container>
    );
  }

  return (
    <Container className="flex flex-col items-center gap-4 pb-24">
      <MetaTags title={employer?.companyName ?? 'Company Detail'} />
      {employer && <CompanySummary employer={employer} />}
    </Container>
  );
};
