import { LocationFilterSelection } from '@elements/LocationFilter';
import { Candidate } from '@models/candidate';
import { Match } from '@models/Match';
import { useAccountContext } from '@providers/UserContext';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
export const useCandidateMatches = (props?: {
  vacancyId?: string;
  locationFilter?: LocationFilterSelection;
}) => {
  const { user } = useAccountContext<Candidate>();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  let url = `/candidate/matches?`;
  if (props?.vacancyId) {
    url = `${url}&vacancyId=${props.vacancyId}`;
  }
  if (props?.locationFilter) {
    url = `${url}&lat=${props.locationFilter.lat}&lng=${props.locationFilter.lng}&distance=${props.locationFilter.distance}`;
  }

  useEffect(() => {
    if (user?.accountInfo?.beingMatched) {
      setShouldRefresh(true);
    }
  }, [user?.accountInfo?.beingMatched]);

  const { data, ...swr } = useSWR(url, {
    keepPreviousData: true,
    refreshInterval: shouldRefresh ? 10000 : undefined
  });

  const matches: Match[] = data?.map((match: any) => {
    return Match.fromJson(match);
  });

  return {
    matches,
    ...swr
  };
};
