import { DropdownButton } from '@elements/DropdownButton';
import { SearchInput } from '@elements/SearchInput';
import { useEmployerVacancies } from '@services/employer/use-employer-vacancies';
import { useTranslation } from 'react-i18next';

interface FunnelFiltersProps {
  onVacancyFilterChange: (vacancyId: string | null) => void;
  onSearchChange: (value: string) => void;
  selectedVacancyId: string | null;
}

export const FunnelFilters = ({
  onVacancyFilterChange,
  onSearchChange,
  selectedVacancyId
}: FunnelFiltersProps) => {
  const { t } = useTranslation('funnel');
  const { vacancies } = useEmployerVacancies({ onlyWithMatches: true });
  return (
    <div className="flex gap-4">
      <DropdownButton
        placeholder={t('all_vacancies')}
        selected={selectedVacancyId ?? undefined}
        options={vacancies.map(vacancy => ({
          label: vacancy.title!,
          value: vacancy.id
        }))}
        onSelect={option => onVacancyFilterChange(option?.value ?? null)}
      />
      <SearchInput onChange={onSearchChange} />
    </div>
  );
};
