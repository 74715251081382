import { KoraApi } from '@services/api';
import { useState } from 'react';

export const useEmployerImageUpload = (employerId: string) => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const uploadImage = async (file: File, type: 'banner' | 'logo') => {
    setIsUploading(true);
    setError(null);
    setUploadProgress(0);

    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', type);

    try {
      const response = await KoraApi.put(
        `/employer/${employerId}/image`,
        formData
      );
      return response.data;
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
    } finally {
      setIsUploading(false);
    }
  };

  return {
    isUploading,
    error,
    uploadProgress,
    uploadImage
  };
};
