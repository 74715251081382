import { Notification, NotificationType } from '@models/Notification';
import { defaultPageSize, useSWRPaginated } from '@services/api';

export const useNotifications = ({
  pageSize = defaultPageSize
}: { pageSize?: number } = {}) => {
  const { data, isLoading, hasReachedEnd, handleLoadMore, mutate } =
    useSWRPaginated(`/notifications`, pageSize);

  const notifications: Notification<NotificationType>[] = data?.map(
    Notification.fromJson
  );

  // only unique ids
  const uniqueNotifications = notifications.filter(
    (notification, index, self) =>
      index === self.findIndex(t => t.id === notification.id)
  );

  return {
    notifications: uniqueNotifications,
    isLoading,
    hasReachedEnd,
    handleLoadMore,
    mutate
  };
};
