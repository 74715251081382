import { useToast } from '@elements/Toast';
import { Candidate } from '@models/candidate';
import { Status } from '@models/Status';
import { WorkExperience } from '@models/WorkExperience';
import { useAccountContext } from '@providers/UserContext';
import { KoraApi } from '@services/api';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { v4 as uuidv4 } from 'uuid';

export const useCandidateExperiences = () => {
  const { data, ...swr } = useSWR('/candidate/work-experiences');

  const experiences: WorkExperience[] = data?.map((experience: any) =>
    WorkExperience.fromJson(experience)
  );

  return {
    experiences,
    ...swr
  };
};

export const useUpdateCandidateExperiences = () => {
  const { mutate, experiences, ...swr } = useCandidateExperiences();
  const { user } = useAccountContext<Candidate>();
  const [isSaving, setIsSaving] = useState(false);
  const { error: errorToast } = useToast();
  const { t } = useTranslation('candidate_intake', {
    keyPrefix: 'resume_intake.job_experience_editor'
  });
  const [isMounted, setIsMounted] = useState(false);

  const [changedExperiences, setChangedExperiences] = useState<
    WorkExperience[]
  >([]);

  useEffect(() => {
    if (!isMounted && experiences && experiences.length > 0) {
      setChangedExperiences(experiences);
      setIsMounted(true);
    }
  }, [experiences]);

  useEffect(() => {
    const status = user?.accountInfo?.resume?.status;
    if (status === Status.VERIFIED || status === Status.DONE) {
      setIsMounted(false);
      mutate();
    }
  }, [user]);

  const isValid = useMemo(() => {
    return changedExperiences?.every(experience => experience.isValid());
  }, [changedExperiences]);

  const pushChanges = async () => {
    setIsSaving(true);
    try {
      await KoraApi.put('/candidate/work-experiences', sanitize());
      setIsSaving(false);
      mutate();
    } catch (error) {
      setIsSaving(false);
      errorToast(t('error'));
      throw error;
    }
  };

  const sanitize = () => {
    const _experiences = changedExperiences.map(experience => {
      if (experience.id?.startsWith('temp-')) {
        experience.id = undefined;
      }
      return experience;
    });
    return _experiences;
  };

  const onChange = (experience: WorkExperience) => {
    const index = changedExperiences.findIndex(e => e.id === experience.id);
    if (index !== -1) {
      const updated = [...changedExperiences];
      updated[index] = experience;
      setChangedExperiences(updated);
    } else {
      setChangedExperiences([...changedExperiences, experience]);
    }
  };

  const deleteExperience = (experience: WorkExperience) => {
    const index = changedExperiences.findIndex(e => e.id === experience.id);
    if (index !== -1) {
      const updated = [...changedExperiences];
      updated.splice(index, 1);
      setChangedExperiences(updated);
    }
  };

  const addExperience = () => {
    const newExperience = new WorkExperience(`temp-${uuidv4()}`);
    setChangedExperiences([...changedExperiences, newExperience]);
  };

  const enhancedMutate = () => {
    setIsMounted(false);
    mutate();
  };

  const memoizedExperiences = useMemo(() => {
    return changedExperiences;
  }, [changedExperiences]);

  return {
    pushChanges,
    mutate: enhancedMutate,
    addExperience,
    experiences: memoizedExperiences,
    onChange,
    isSaving,
    isValid,
    deleteExperience,
    ...swr
  };
};
