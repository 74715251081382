import { CompanyInformationIntake } from '@components/employer/EmployerIntake/CompanyInformationIntake';
import { CompanyWebsitesIntake } from '@components/employer/EmployerIntake/CompanyWebsitesIntake';
import { EmployerIntakeSummary } from '@components/employer/EmployerIntake/EmployerIntakeSummary';
import { EmployerProfileIntake } from '@components/employer/EmployerIntake/EmployerProfileIntake';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { MetaTags } from '@elements/MetaTags';
import Wizard, { WizardStep } from '@elements/Wizard';
import { Employer } from '@models/Employer';
import { useAccountContext } from '@providers/UserContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EmployerIntakePage = () => {
  const { t } = useTranslation('employer_intake');
  const [currentStep, setCurrentStep] = useState(1);
  const { user } = useAccountContext<Employer>();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!user || initialized) return;
    const {
      firstName,
      lastName,
      address,
      industry,
      totalEmployees,
      dutchEmployees,
      foundingDate,
      websites
    } = { ...user.accountInfo, ...user };
    let step = 1;
    if (firstName && lastName) {
      step = 2;
    }
    if (
      step === 2 &&
      address &&
      industry &&
      foundingDate &&
      totalEmployees !== undefined &&
      dutchEmployees !== undefined
    ) {
      step = 3;
    }
    if (step === 3 && websites && websites.home) {
      step = 4;
    }

    setCurrentStep(step);
    setInitialized(true);
  }, [user]);

  return (
    <ContainerWithBackButton>
      <MetaTags title={t('page_title')} />
      <div className="flex h-[calc(100vh-200px)] w-full flex-col">
        <Wizard
          activeStep={currentStep - 1}
          onStepChange={step => setCurrentStep(step + 1)}
        >
          <WizardStep title={t('progress_bar.step_1')}>
            <EmployerProfileIntake onNext={() => setCurrentStep(2)} />
          </WizardStep>
          <WizardStep title={t('progress_bar.step_2')}>
            <CompanyInformationIntake onNext={() => setCurrentStep(3)} />
          </WizardStep>
          <WizardStep title={t('progress_bar.step_3')}>
            <CompanyWebsitesIntake onNext={() => setCurrentStep(4)} />
          </WizardStep>
          <WizardStep title={t('progress_bar.step_4')}>
            <EmployerIntakeSummary />
          </WizardStep>
        </Wizard>
      </div>
    </ContainerWithBackButton>
  );
};
