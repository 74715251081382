import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Divider } from '@elements/Divider';
import PercentageSlider from '@elements/PercentageSlider';
import { Typography } from '@elements/Typography';
import { Match, MatchingStatus } from '@models/Match';
import { useCandidateMatches } from '@services/candidate/use-candidate-matches';
import { usePatchCandidateMatch } from '@services/candidate/use-patch-candidate-match';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

export interface CandidateFunnelCardProps {
  match: Match;
}

export const CandidateFunnelCard = ({ match }: CandidateFunnelCardProps) => {
  const { t } = useTranslation('recommended_jobs');
  const vacancy = match.vacancy;
  const navigate = useNavigate();

  const { mutate } = useCandidateMatches({ vacancyId: vacancy.id });
  const { patch, patchWithConfirm } = usePatchCandidateMatch(match.id!);

  const CTA = () => {
    return (
      <div className="mt-6 flex flex-col gap-4">
        {match.status === MatchingStatus.INVITED && (
          <Typography variant="body1" className="text-sm">
            {t('interview_accepted_verification_label')}
          </Typography>
        )}
        <Link to={`/jobs/${match.vacancy.id}`}>
          <Button variant="primary" size="sm" className="w-full">
            {t('job_details')}
          </Button>
        </Link>
        {match.status === MatchingStatus.INVITED && (
          <>
            <Button
              variant="info"
              size="sm"
              onClick={async () => {
                await patchWithConfirm({
                  status: MatchingStatus.INTERVIEW,
                  title: t('accepted_interview'),
                  description: t('accept_interview_confirm_description'),
                  onSuccess: () => {
                    mutate();
                  }
                });
              }}
            >
              {t('accepted_interview')}
            </Button>
            <Button
              variant="info"
              size="sm"
              onClick={async () => {
                await patchWithConfirm({
                  status: MatchingStatus.DECLINED_INTERVIEW_BY_CANDIDATE,
                  title: t('declined_interview'),
                  description: t('decline_confirm_description'),
                  onSuccess: () => mutate()
                });
              }}
            >
              {t('declined_interview')}
            </Button>
          </>
        )}
        {(match.status === MatchingStatus.DECLINED ||
          match.status === MatchingStatus.REJECTED) && (
          <Button
            variant="info"
            size="sm"
            className="w-full"
            onClick={async () => {
              await patch({
                archived: true
              });
              await navigate('/funnel?tab=ARCHIVE');
            }}
          >
            {t('archive')}
          </Button>
        )}
      </div>
    );
  };
  return (
    <Card className="!p-[24px_16px]">
      <div className="flex flex-col">
        <div className="mb-6 flex flex-col gap-2">
          <Typography variant="h5" className="line-clamp-2 text-base">
            {vacancy.title}
          </Typography>
          <Typography variant="body1" className="text-sm text-light">
            {vacancy.employer?.companyName}
          </Typography>
          {match.status === MatchingStatus.INVITED && (
            <Typography
              variant="body1"
              className="pt-3 text-sm text-light"
              color="primary"
            >
              <Trans>{t('interview_disclaimer')}</Trans>
            </Typography>
          )}
        </div>
        {match.status === MatchingStatus.INVITED ? (
          <Divider />
        ) : (
          <PercentageSlider size="sm" percentage={match.percentage} />
        )}
        {!match.archived ? (
          <CTA />
        ) : (
          <div className="mt-6 flex flex-col gap-4">
            <Button
              variant="info"
              size="sm"
              className="w-full"
              onClick={async () => {
                await patch({ archived: false });
                if (
                  match.status === MatchingStatus.REJECTED ||
                  match.status === MatchingStatus.DECLINED
                ) {
                  navigate('/funnel?tab=REJECTED');
                } else {
                  navigate('/funnel');
                }
              }}
            >
              {t('unarchive')}
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};
