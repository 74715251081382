import useSWR, { mutate } from 'swr';

const fetcher = async (url: string) => {
  const res = await fetch(url, { cache: 'no-store' });
  return res.text();
};

export const useMarkdownContent = (url: string, section?: string) => {
  const {
    data,
    error,
    isLoading,
    mutate: refetch
  } = useSWR(url === '<GENERATING>' ? null : url, fetcher);

  let markdownContent = data && !error ? data : '';

  if (section && markdownContent) {
    // Find the section header level and position
    const lines = markdownContent.split('\n');
    let headerLevel = 0;
    let startIndex = -1;

    // Find the matching header and its level
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const match = line.match(/^(#{1,6})\s+(.*)$/);

      if (match && match[2].toLowerCase() === section.toLowerCase()) {
        headerLevel = match[1].length;
        startIndex = i;
        break;
      }
    }

    if (startIndex !== -1) {
      // Find the end of the section (next header of same or higher level)
      let endIndex = lines.length;
      for (let i = startIndex + 1; i < lines.length; i++) {
        const match = lines[i].match(/^(#{1,6})\s+/);
        if (match && match[1].length <= headerLevel) {
          endIndex = i;
          break;
        }
      }

      // Extract the content between start and end, excluding the header
      markdownContent = lines
        .slice(startIndex + 1, endIndex)
        .join('\n')
        .trim();
    } else {
      markdownContent = '';
    }
  }

  return {
    markdownContent,
    setMarkdownContent: (content: string) => mutate(url, content, false),
    isLoading,
    error,
    refetch
  };
};
