import { CheckCircle, ErrorCircle } from '@icons/index';
import { Colors } from '@utils/ColorUtils';
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { ClipLoader } from 'react-spinners';
import { Tooltip } from './Tooltip';

export type InputVariant = 'default' | 'compact';

export interface IInput
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'onBlur' | 'label'
  > {
  label?: string | React.ReactNode;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  allowedChars?: string[];
  rows?: number;
  enableAutoSave?: boolean;
  onClick?: () => void;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  variant?: InputVariant;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  error?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  autoGrow?: boolean;
  'data-testid'?: string;
}

export const Input = forwardRef<HTMLInputElement, IInput>(
  (
    {
      label,
      className = '',
      type = 'text',
      onChange,
      disabled,
      value,
      defaultValue,
      variant = 'default',
      allowedChars,
      placeholder,
      onFocus,
      onBlur,
      endAdornment,
      startAdornment,
      error,
      onKeyDown,
      inputRef,
      autoGrow,
      onClick,
      'data-testid': dataTestId,
      enableAutoSave = true,
      rows = 1
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const [isAutoSaving, setIsAutoSaving] = useState(false);
    const [isAutoSavingTimeout, setIsAutoSavingTimeout] =
      useState<NodeJS.Timeout | null>(null);
    const [isAutoSaved, setIsAutoSaved] = useState(false);
    const [autoSaveError, setAutoSaveError] = useState<string | null>(null);

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const isControlled = value !== undefined;
    const inputValue = isControlled
      ? value
      : defaultValue !== undefined
        ? defaultValue
        : '';

    useEffect(() => {
      if (autoGrow && textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, [inputValue, autoGrow]);

    const mustMoveLabel =
      isFocused || (inputValue != null && inputValue.toString().length > 0);

    const handleBlur = async (
      e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setIsAutoSaved(false);
      setIsFocused(false);
      if (!onBlur) return;
      setIsAutoSaving(true);

      try {
        await onBlur?.(e);
      } catch (e) {
        setAutoSaveError(e as string);
        setIsAutoSaving(false);
        return;
      }
      setIsAutoSaving(false);

      if (!enableAutoSave) return;

      setIsAutoSaved(true);
      if (isAutoSavingTimeout) {
        clearTimeout(isAutoSavingTimeout);
      }
      const timeout = setTimeout(() => {
        setIsAutoSaved(false);
      }, 1000);
      setIsAutoSavingTimeout(timeout);
    };

    const handleChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      let newValue = e.target.value;

      // Handle empty number input
      if (type === 'number' && newValue === '') {
        onChange?.(e);
        return;
      }

      if (!newValue) newValue = '';

      if (allowedChars) {
        const regex = new RegExp(`^[${allowedChars.join('')}]*$`);
        if (!regex.test(newValue)) {
          if (isControlled) {
            e.target.value = value?.toString() ?? '';
          } else {
            e.target.value = e.target.defaultValue;
          }
          return;
        }
      }

      onChange?.(e);
    };

    const handleFocus = (
      e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setIsFocused(true);
      onFocus?.(e as FocusEvent<HTMLInputElement>);
    };

    const handleKeyDown = (
      e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      onKeyDown?.(e);
    };

    const inputClassName = `text-sm ${
      disabled ? '!bg-[#e9e9e9]' : ''
    } w-full rounded-[8px] border-[1px] ${
      error ? 'border-error' : 'border-[#CDCDCD]'
    } bg-white ${
      variant === 'compact' ? 'p-[14px_12px_4px_12px]' : 'p-[9px_12px]'
    } font-normal placeholder-[#12121299] ${className}`;

    const renderInput = useCallback(() => {
      const commonProps = {
        onChange: handleChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onKeyDown: handleKeyDown,
        disabled,
        ...(isControlled
          ? { value: value?.toString() ?? '' }
          : { defaultValue: defaultValue?.toString() ?? '' }),
        className: inputClassName
      };

      if (autoGrow) {
        return (
          <textarea
            ref={textareaRef}
            rows={rows}
            style={{
              resize: 'none',
              overflow: 'hidden'
            }}
            {...commonProps}
          />
        );
      }

      return (
        <input
          ref={inputRef || ref}
          type={type}
          {...commonProps}
          placeholder={placeholder}
          style={{
            paddingLeft: startAdornment ? '40px' : undefined
          }}
          data-testid={dataTestId ?? `input-${variant}`}
        />
      );
    }, [
      inputClassName,
      handleChange,
      handleFocus,
      handleBlur,
      handleKeyDown,
      disabled,
      isControlled,
      value,
      defaultValue,
      type,
      placeholder,
      dataTestId,
      variant
    ]);

    const renderLabel = () => {
      if (!label) return null;
      let top = '12px';
      let fontSize = '16px';
      const labelLeft = startAdornment ? 'left-[40px]' : 'left-[13px]';

      top = mustMoveLabel ? '3px' : '13px';
      fontSize = mustMoveLabel ? '10px' : '14px';
      return (
        <label
          style={{
            top,
            fontSize
          }}
          className={`pointer-events-none absolute ${labelLeft} text-left text-placeholder transition-all duration-300 ease-in-out`}
        >
          {label}
        </label>
      );
    };

    const renderError = () => {
      if (autoSaveError) {
        return (
          <div
            className={`absolute right-4 top-1/2 flex -translate-y-1/2 items-center gap-1`}
          >
            <Tooltip content={error} placement="right">
              <ErrorCircle color={Colors.error} />
            </Tooltip>
          </div>
        );
      }

      if (error) {
        return (
          <div
            className={`absolute right-4 top-1/2 flex -translate-y-1/2 items-center gap-1`}
          >
            <Tooltip content={error} placement="right">
              <ErrorCircle color={Colors.error} />
            </Tooltip>
          </div>
        );
      }
    };

    const autoSaveIndicator = useMemo(() => {
      if (!enableAutoSave) return null;
      if (isAutoSaving) {
        return (
          <div className="absolute right-4 top-1/2 -translate-y-1/2">
            <ClipLoader color={Colors.primary} size={16} />
          </div>
        );
      }

      if (isAutoSaved) {
        return (
          <div className="absolute right-4 top-1/2 -translate-y-1/2">
            <CheckCircle color={Colors.success} width={16} height={16} />
          </div>
        );
      }

      if (autoSaveError) {
        return (
          <div className="absolute right-4 top-1/2 -translate-y-1/2">
            <ErrorCircle color={Colors.error} />
          </div>
        );
      }
    }, [isAutoSaving, isAutoSaved, autoSaveError]);

    if (variant === 'compact') {
      return (
        <div
          className={`relative flex w-full flex-col ${
            onClick ? 'cursor-pointer' : ''
          }`}
          onClick={onClick}
        >
          {startAdornment && (
            <div className="absolute left-2 flex h-full items-center">
              {startAdornment}
            </div>
          )}
          {renderInput()}
          {renderError()}
          {renderLabel()}
          {autoSaveIndicator}
          {endAdornment && (
            <div className="absolute right-[12px] top-[50%] translate-y-[-50%]">
              {endAdornment}
            </div>
          )}
        </div>
      );
    }

    return (
      <div
        className={`relative flex w-full flex-col ${
          onClick ? 'cursor-pointer' : ''
        }`}
        onClick={onClick}
      >
        {label && <label className="mb-2">{label}</label>}
        {startAdornment && (
          <div className="absolute left-0 flex h-full items-center">
            {startAdornment}
          </div>
        )}
        <div className="flex h-full w-full flex-col items-center">
          {renderInput()}
          {autoSaveIndicator}
          {endAdornment && (
            <div className="absolute right-[12px] top-[50%] translate-y-[-50%]">
              {endAdornment}
            </div>
          )}
          {renderError()}
        </div>
      </div>
    );
  }
);
