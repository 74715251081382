import { Divider } from '@elements/Divider';
import { Typography } from '@elements/Typography';
import { Candidate } from '@models/candidate';
import { usePopup } from '@providers/PopupProvider';
import { useCandidatePatch } from '@services/user/use-candidate-patch';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAccountContext } from 'src/providers/UserContext';
import { CTA, ResumeCTA } from './CTAs';
import { LinkedinPopup } from './LinkedinPopup';

export const ProfileCTASection = () => {
  const { t } = useTranslation('profile', { keyPrefix: 'cta' });
  const { user } = useAccountContext<Candidate>();

  const { patch } = useCandidatePatch();
  const { show: showPopup } = usePopup();

  return (
    <div className="p-6">
      <CTA
        title={t('linkedin_title')}
        description={t('linkedin_description')}
        statusMessage={
          <Link
            to={user?.accountInfo?.linkedinUrl ?? ''}
            target="_blank"
            className="max-w-[200px] truncate"
          >
            <Typography color="primary" className="max-w-[200px] truncate">
              {user?.accountInfo?.linkedinUrl
                ?.replaceAll('www.', '')
                .replaceAll('https://', '')
                .replaceAll('linkedin.com', '')}
            </Typography>
          </Link>
        }
        cta={
          user?.accountInfo?.linkedinUrl
            ? t('linkedin_unlink_button')
            : t('linkedin_button')
        }
        onClick={() => {
          if (user?.accountInfo?.linkedinUrl) {
            patch({ linkedinUrl: '' });
          } else {
            showPopup({
              body: <LinkedinPopup />
            });
          }
        }}
      />
      <Divider className="my-4" />
      <ResumeCTA />

      <Divider className="my-4" />
      <CTA
        title={t('referral_title')}
        description={t('referral_description')}
        cta={t('referral_button')}
        link="/refer"
      />
    </div>
  );
};
