export class WorkExperience {
  constructor(
    public id?: string,
    public title?: string,
    public company?: string,
    public startDate?: Date,
    public endDate?: Date | null,
    public description?: string | null
  ) {}

  public static fromJson(json: any): WorkExperience {
    return new WorkExperience(
      json.id,
      json.title,
      json.company,
      new Date(json.startDate),
      json.endDate ? new Date(json.endDate) : null,
      json.description
    );
  }

  public isValid(): boolean {
    return Boolean(
      this.title && this.company && this.startDate && this.description
    );
  }
}
