import { CheckIcon } from '@icons/index';
import React, { ReactNode, useEffect, useState } from 'react';
import { Divider } from './Divider';
import { Typography } from './Typography';
interface WizardProps {
  children?: ReactNode;
  className?: string;
  activeStep: number;
  onStepChange?: (step: number) => void;
}

interface WizardStepProps {
  title: string;
  children: ReactNode;
}

export const WizardStep = ({ children }: WizardStepProps) => {
  return <>{children}</>;
};

export default function Wizard({
  children,
  className,
  activeStep: activeStepProp,
  onStepChange
}: WizardProps) {
  const [activeStep, setActiveStep] = useState(activeStepProp);
  useEffect(() => {
    setActiveStep(activeStepProp);
  }, [activeStepProp]);

  const handleStepChange = (newStep: number) => {
    setActiveStep(newStep);
    onStepChange?.(newStep);
  };

  const titles = React.Children.map(children, child => {
    if (React.isValidElement<WizardStepProps>(child)) {
      return child.props.title;
    }
    return null;
  });

  const Badge = ({ index }: { index: number }) => {
    return (
      <div
        className={`flex h-8 w-8 items-center justify-center rounded-full p-2 text-center ${
          index <= activeStep ? 'bg-secondary' : 'bg-light'
        } text-white`}
      >
        {index < activeStep ? (
          <CheckIcon className="h-4 w-4 text-white" fill="currentColor" />
        ) : (
          <Typography
            variant="body1"
            className="ps-[1px] font-semibold text-white"
          >
            {index + 1}
          </Typography>
        )}
      </div>
    );
  };
  const Step = ({ step, index }: { step: string; index: number }) => {
    return (
      <div
        key={index}
        className={`flex w-full items-center justify-center rounded-[10px_10px_10px_4px] bg-white px-3 py-2 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.20)] ${
          index < activeStep ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (index < activeStep) {
            handleStepChange(index);
          }
        }}
      >
        <div className="flex items-center gap-2">
          <div className="me-2 h-8 w-8">
            <Badge index={index} />
          </div>
          <Typography variant="body1">{step}</Typography>
        </div>
      </div>
    );
  };

  return (
    <div className={className + ' h-full'}>
      <div
        className="grid w-full justify-between pb-2"
        style={{
          gridTemplateColumns: `${titles
            ?.map((_, index) =>
              index < titles.length - 1
                ? titles.length + 'fr 1fr'
                : titles.length + 'fr'
            )
            .join(' ')}`
        }}
      >
        {titles?.map((title, index) => (
          <React.Fragment key={index}>
            <Step key={index} step={title} index={index} />
            {index < titles.length - 1 && (
              <div className="flex w-full items-center justify-center px-2">
                <Divider />
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="h-full">
        {React.Children.map(children, (child, index) => {
          if (index === activeStep && React.isValidElement(child)) {
            return <div className="h-full pt-8">{child}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
}
