import { ChevronDown, ChevronUp } from '@icons/index';
import { useState } from 'react';
import { Card } from './Card';
import { Typography } from './Typography';
interface CollapseCardProps {
  title: string | React.ReactNode;
  defaultOpen?: boolean;
  children: React.ReactNode;
}

export const CollapseCard = ({
  title,
  children,
  defaultOpen = false
}: CollapseCardProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  return (
    <Card className="!p-0">
      <div
        className="flex cursor-pointer items-center justify-between p-6"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography
          variant="h5"
          color="dark"
          className="w-full pe-4 text-lg !font-[500]"
        >
          {title}
        </Typography>
        {isOpen ? (
          <ChevronUp className="text-secondary" />
        ) : (
          <ChevronDown className="text-secondary" />
        )}
      </div>
      {isOpen && <div className="p-6 pt-0">{children}</div>}
    </Card>
  );
};
