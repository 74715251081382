import useIsMobile from '@hooks/is-mobile';

interface IContainer {
  children?: React.ReactNode;
  className?: string;
}
export const Container = ({ className, children }: IContainer) => {
  const isMobile = useIsMobile();
  return (
    <div
      className={`container mx-auto ${isMobile ? 'mt-4' : 'mt-8'} ${className ? className : ''}`}
      data-testid="container"
    >
      {children}
    </div>
  );
};
