import { VacancySummary } from '@components/vacancies/VacancySummary';
import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Typography } from '@elements/Typography';
import { Status } from '@models/Status';
import { Vacancy } from '@models/Vacancy';
import { useVacancyPatch } from '@services/vacancies/patch-vacancy';
import { Colors } from '@utils/ColorUtils';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
interface VerifyVacancySummaryProps {
  vacancy: Vacancy;
  onPublish: () => void;
}

export const VerifyVacancySummary = ({
  vacancy,
  onPublish
}: VerifyVacancySummaryProps) => {
  const { t } = useTranslation('vacancy_intake', {
    keyPrefix: 'verify_vacancy_summary'
  });
  const { patch, loading } = useVacancyPatch(vacancy.id);
  return (
    <Card className="flex flex-col gap-2 py-8">
      <Typography variant="h2" className="mb-4 text-2xl text-secondary">
        {t('title', { name: vacancy.title })}
      </Typography>
      <VacancySummary vacancy={vacancy} />
      <div className="flex justify-end">
        <Button
          variant="secondary"
          className="mt-4 flex items-center gap-2"
          size="sm"
          onClick={async () => {
            await patch({
              flags: {
                ...vacancy.flags,
                readStatus: Status.VERIFIED
              }
            });
            onPublish();
          }}
        >
          {loading && <ClipLoader size={16} color={Colors.white} />}
          {t('publish_vacancy')}
        </Button>
      </div>
      {/* <MarkdownViewer content={vacancy.markdownSummary!} /> */}
    </Card>
  );
};
