import ReactCookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
export const CookieConsent = () => {
  const { t } = useTranslation('cookie_disclaimer');

  return (
    <ReactCookieConsent
      disableStyles={true}
      location="bottom"
      buttonText="Accept"
      enableDeclineButton={true}
      containerClasses="text-center animate-fade-in w-[90%] flex flex-col gap-5 items-center justify-between rounded-[4px_20px_20px_20px] bg-white p-6 shadow-[0px_4px_8px_0px_rgba(0,0,0,0.20)] fixed left-[5%] right-[5%] !bottom-4"
      buttonClasses="bg-secondary text-[#fff] min-w-[150px] border-[1px] border-secondary rounded-[20px] font-medium p-[10px_24px] transition duration-200 hover:opacity-70"
      declineButtonClasses="border-[#000] min-w-[150px] me-2 border-[1px] rounded-[20px] font-medium p-[10px_24px] transition duration-200 hover:opacity-70"
      buttonWrapperClasses="flex gap-3"
    >
      <div className="flex flex-col gap-2">
        <span>{t('excerpt')}</span>
        <Link to="/cookie-policy">{t('read_more')}</Link>
      </div>
    </ReactCookieConsent>
  );
};
