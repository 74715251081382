import { Typography } from '@elements/Typography';
import {
  GaugeIcon,
  GearIcon,
  Glasses as GlassesIcon,
  GroupIcon,
  User as UserIcon
} from '@icons/index';
import { useTranslation } from 'react-i18next';
export const WhatWeOfferSection = () => {
  const { t } = useTranslation('home', { keyPrefix: 'employer.what_we_offer' });

  const offers = [
    {
      title: t('reach'),
      icon: (
        <GroupIcon
          fill="currentColor"
          className="text-secondary"
          width={24}
          height={24}
        />
      )
    },
    {
      title: t('matching'),
      icon: (
        <GlassesIcon
          fill="currentColor"
          className="text-secondary"
          width={24}
          height={24}
        />
      )
    },
    {
      title: t('speed'),
      icon: (
        <GaugeIcon
          fill="currentColor"
          className="text-secondary"
          width={24}
          height={24}
        />
      )
    },
    {
      title: t('depth'),
      icon: (
        <UserIcon
          fill="currentColor"
          className="text-secondary"
          width={24}
          height={24}
        />
      )
    },
    {
      title: t('control'),
      icon: (
        <GearIcon
          fill="currentColor"
          className="text-secondary"
          width={24}
          height={24}
        />
      )
    }
  ];
  return (
    <div className="rounded-[10px_40px_40px_40px] bg-dark p-12">
      <Typography variant="h2" className="mb-10 !leading-10 text-white">
        {t('title')}
      </Typography>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-5">
        {offers.map(feature => (
          <div
            key={feature.title}
            className="flex w-full flex-col items-center justify-center rounded-[20px] bg-white px-4 py-6 shadow-[2px_4px_16px_0px_rgba(0,0,0,0.12)] md:px-8 md:py-10"
          >
            <div className="mb-6 flex h-[50px] w-[50px] items-center justify-center rounded-full bg-light-blue">
              {feature.icon}
            </div>
            <Typography
              variant="h5"
              color="dark"
              className="h-[120px] text-center !text-base !font-[500]"
            >
              {feature.title}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
