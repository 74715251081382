import { NotificationCard } from '@components/notifications/NotificationCard';
import { Container } from '@elements/Container';
import { Divider } from '@elements/Divider';
import InfiniteScroll from '@elements/InfiniteScroll';
import { Typography } from '@elements/Typography';
import { useBackground } from '@providers/BackgroundProvider';
import { useNotifications } from '@services/notifications/use-notifications';

export const NotificationsPage = () => {
  const { notifications, isLoading, hasReachedEnd, handleLoadMore } =
    useNotifications();
  useBackground(false);

  return (
    <Container className="pt-4">
      <Typography variant="h1">Notifications</Typography>
      <Divider className="my-4" />
      <InfiniteScroll
        isLoading={isLoading}
        isCompleted={hasReachedEnd}
        onLoadMore={handleLoadMore}
      >
        {notifications?.map(notification => (
          <NotificationCard notification={notification} key={notification.id} />
        ))}
      </InfiniteScroll>
    </Container>
  );
};

/**
 *   CANDIDATE_NEW_MATCH = 'CANDIDATE_NEW_MATCH',
  CANDIDATE_MATCH_CHANGED = 'CANDIDATE_MATCH_CHANGED',
  CANDIDATE_INTAKE_REMINDER = 'CANDIDATE_INTAKE_REMINDER',
  CANDIDATE_INTERVIEW_CHECK = 'CANDIDATE_INTERVIEW_CHECK',
  EMPLOYER_INTAKE_REMINDER = 'EMPLOYER_INTAKE_REMINDER',
  EMPLOYER_VACANCY_INTAKE_REMINDER = 'EMPLOYER_VACANCY_INTAKE_REMINDER',
  EMPLOYER_INTERVIEW_CHECK = 'EMPLOYER_INTERVIEW_CHECK',
  EMPLOYER_MATCH_CHANGED = 'EMPLOYER_MATCH_CHANGED'
 */
