import GoogleIcon from '@assets/google-icon.png';
import { Button } from '@elements/Button';
import { useToast } from '@elements/Toast';
import { AccountType } from '@models/UserAccount';
import { useGoogleLogin } from '@react-oauth/google';
import useGoogleCallback from '@services/auth/use-google-callback';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface IGoogleLoginButton {
  label: string;
  color?: 'primary' | 'secondary' | 'info';
  mode?: 'register' | 'login';
  accountType?: AccountType;
  className?: string;
  [key: string]: any;
}

export const GoogleLoginButton = ({
  label,
  color = 'info',
  mode = 'register',
  accountType,
  className,
  ...props
}: IGoogleLoginButton) => {
  const { t } = useTranslation('register');
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const { error } = useToast();

  useGoogleCallback(mode, accessToken, accountType);

  const googleLogin = useGoogleLogin({
    onSuccess: async response => {
      setAccessToken(response.access_token);
    },
    onError: () => {
      error(t('google_failed'));
    }
  });

  return (
    <Button
      variant={color}
      onClick={() => googleLogin()}
      className={`flex items-center justify-center ${className}`}
      {...props}
    >
      <img
        src={GoogleIcon}
        alt="Google"
        className="mr-3"
        width={20}
        height={20}
      />
      {label}
    </Button>
  );
};
