import { Card } from '@elements/Card';
import { Container } from '@elements/Container';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import { KoraApi } from '@services/api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';

export const SignUpsPage = () => {
  const [searchParams] = useSearchParams();
  const apiKey = searchParams.get('apiKey');
  const { data } = useSignUps(apiKey!);
  const navigate = useNavigate();
  if (!apiKey) {
    navigate('/');
  }
  return (
    <>
      <MetaTags title="Signups" />
      <Container>
        <Card>
          <Typography variant="h1" className="text-center text-primary">
            Kora Signups
          </Typography>
          <div className="mt-10 flex flex-col gap-4 text-center">
            {data?.map((signup: string, index: number) => (
              <div key={index}>
                <Typography variant="h3">
                  {index + 1}. {signup}
                </Typography>
              </div>
            ))}
          </div>
        </Card>
      </Container>
    </>
  );
};

const useSignUps = (apiKey: string) => {
  const fetcher = async (url: string) => {
    const data = await KoraApi.get(
      url,
      {},
      {
        headers: {
          'x-api-key': apiKey
        }
      }
    );
    return data.data;
  };
  const { data, ...swr } = useSWR(`/auth/demo-signups`, fetcher);
  return { data, ...swr };
};
