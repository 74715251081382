import { useEffect, useState } from 'react';

export interface CountdownBarProps {
  duration: number; // duration in milliseconds
  className?: string;
}

export const CountdownBar = ({ duration, className }: CountdownBarProps) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now();

    const updateProgress = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;
      const newProgress = Math.min((elapsed / duration) * 100, 100);

      if (newProgress < 100) {
        setProgress(newProgress);
        requestAnimationFrame(updateProgress);
      } else {
        setProgress(100);
      }
    };

    const animationFrame = requestAnimationFrame(updateProgress);

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  }, [duration]);

  return (
    <progress
      value={progress}
      max={100}
      className={`w-full rounded-full [&::-moz-progress-bar]:bg-primary [&::-webkit-progress-bar]:bg-slate-300 [&::-webkit-progress-value]:rounded-lg [&::-webkit-progress-value]:bg-primary ${className}`}
    />
  );
};
