import { Card } from '@elements/Card';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { Typography } from '@elements/Typography';
import { Trans, useTranslation } from 'react-i18next';

export const PrivacyPage = () => {
  const { t } = useTranslation('privacy_statement');
  return (
    <ContainerWithBackButton className="flex flex-col gap-4 py-10">
      <Card className="">
        <Typography variant="h1" color="dark">
          {t('employer.title')}
        </Typography>
        <div className="mt-12 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.introduction.title')}
          </Typography>
          <Typography variant="body1" color="text">
            {t('employer.introduction.content')}
          </Typography>
        </div>
        <div className="mt-10 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.data_collection.title')}
          </Typography>
          <Typography variant="body1" color="text">
            {t('employer.data_collection.content')}
          </Typography>
          <ul className="my-6 list-disc space-y-4 ps-6">
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.data_collection.types.identification')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.data_collection.types.professional')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.data_collection.types.company')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.data_collection.types.chat')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.data_collection.types.technical')}
                </Trans>
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" color="text">
            {t('employer.data_collection.outro')}
          </Typography>
        </div>
        <div className="mt-10 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.legal_basis.title')}
          </Typography>
          <Typography variant="body1" color="text">
            {t('employer.legal_basis.content')}
          </Typography>
          <ul className="my-6 list-disc space-y-4 ps-6">
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.legal_basis.consent')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.legal_basis.contract')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.legal_basis.legitimateInterest')}
                </Trans>
              </Typography>
            </li>
          </ul>
        </div>
        <div className="mt-10 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.usage.title')}
          </Typography>
          <Typography variant="body1" color="text">
            {t('employer.usage.content')}
          </Typography>
          <ul className="my-6 list-disc space-y-4 ps-6">
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.usage.purposes.matching')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.usage.purposes.support')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.usage.purposes.improvement')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.usage.purposes.legalCompliance')}
                </Trans>
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" color="text">
            {t('employer.usage.outro')}
          </Typography>
        </div>
        <div className="mt-10 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.data_sharing.title')}
          </Typography>
          <Typography variant="body1" color="text">
            {t('employer.data_sharing.content')}
          </Typography>
          <ul className="my-6 list-disc space-y-4 ps-6">
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.data_sharing.withCompanies')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.data_sharing.withProviders')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.data_sharing.legalRequirement')}
                </Trans>
              </Typography>
            </li>
          </ul>
        </div>
        <div className="mt-10 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.cookies.title')}
          </Typography>
          <Typography variant="body1" color="text">
            {t('employer.cookies.content')}
          </Typography>
        </div>
        <div className="mt-10 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.retention.title')}
          </Typography>
          <Typography variant="body1" color="text">
            {t('employer.retention.content')}
          </Typography>
          <ul className="my-6 list-disc space-y-4 ps-6">
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.retention.cv')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.retention.chatHistory')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.retention.technicalData')}
                </Trans>
              </Typography>
            </li>
          </ul>
        </div>

        <div className="mt-10 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.rights.title')}
          </Typography>
          <Typography variant="body1" color="text">
            {t('employer.rights.content')}
          </Typography>
          <ul className="my-6 list-disc space-y-4 ps-6">
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.rights.access')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.rights.rectification')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.rights.erasure')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.rights.restriction')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.rights.objection')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.rights.portability')}
                </Trans>
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" color="text">
            <Trans
              components={{
                b: <span className="font-bold" />,
                a: <a className="text-secondary no-underline" />
              }}
            >
              {t('employer.rights.contact')}
            </Trans>
          </Typography>
        </div>
        <div className="mt-10 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.data_breaches.title')}
          </Typography>
          <Typography variant="body1" color="text">
            {t('employer.data_breaches.content')}
          </Typography>
        </div>
        <div className="mt-10 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.automated_decision_making.title')}
          </Typography>
          <Typography variant="body1" color="text">
            {t('employer.automated_decision_making.content')}
          </Typography>
        </div>
        <div className="mt-10 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.security.title')}
          </Typography>
          <Typography variant="body1" color="text">
            {t('employer.security.content')}
          </Typography>
          <ul className="my-6 list-disc space-y-4 ps-6">
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.security.measures.encryption')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.security.measures.accessControl')}
                </Trans>
              </Typography>
            </li>
            <li>
              <Typography variant="body1" color="text">
                <Trans components={{ b: <span className="font-bold" /> }}>
                  {t('employer.security.measures.audits')}
                </Trans>
              </Typography>
            </li>
          </ul>
        </div>
        <div className="mt-10 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.updates.title')}
          </Typography>
          <Typography variant="body1" color="text">
            {t('employer.updates.content')}
          </Typography>
        </div>
        <div className="mt-10 px-6">
          <Typography variant="h2" color="primary" className="mb-4">
            {t('employer.contact.title')}
          </Typography>
          <Typography variant="body1" color="text" className="mb-4">
            {t('employer.contact.content')}
          </Typography>
          <Typography variant="body1" color="text">
            <Trans
              components={{
                b: <span className="font-bold" />,
                a: <a className="text-secondary no-underline" />
              }}
            >
              {t('employer.contact.email')}
            </Trans>
          </Typography>
          <Typography variant="body1" color="text">
            <Trans
              components={{
                b: <span className="font-bold" />,
                a: <a className="text-secondary no-underline" />
              }}
            >
              {t('employer.contact.address')}
            </Trans>
          </Typography>
        </div>
      </Card>
    </ContainerWithBackButton>
  );
};
