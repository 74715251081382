import { Candidate } from '@models/candidate';
import { KoraApi } from '@services/api';
import { useChatRoom } from '@services/chat/use-chat';
import { useAccountContext } from 'src/providers/UserContext';

export const useResumeDelete = () => {
  const { user, mutate } = useAccountContext<Candidate>();
  const { mutate: mutateIntakeChat } = useChatRoom({
    aboutType: 'UserAccount',
    aboutId: user!.id
  });

  const deleteResume = async () => {
    if (!user) return;

    await KoraApi.delete(`/candidate/resume`);
    mutate();
    mutateIntakeChat();
  };

  return { deleteResume };
};
