import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Input } from '@elements/Input';
import { PlaceInput } from '@elements/PlaceInput';
import { Select } from '@elements/Select';
import { Typography } from '@elements/Typography';
import { LocationPin } from '@icons/index';
import { Vacancy } from '@models/Vacancy';
import { useVacancyPatch } from '@services/vacancies/patch-vacancy';
import { Colors } from '@utils/ColorUtils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

export interface BasicVacancyInfoProps {
  vacancy: Vacancy;
  onNext: () => void;
}
export const BasicVacancyInfo = ({
  vacancy,
  onNext
}: BasicVacancyInfoProps) => {
  const { t } = useTranslation('vacancy_intake', {
    keyPrefix: 'basic_vacancy_info'
  });
  const { t: tCommon } = useTranslation('common');
  const { patch, loading } = useVacancyPatch(vacancy.id);
  const [title, setTitle] = useState(vacancy.title);
  const [location, setLocation] = useState(
    vacancy.address ?? vacancy.employer?.address ?? undefined
  );
  const [minHoursPerWeek, setMinHoursPerWeek] = useState(
    vacancy.minHoursPerWeek ?? 38
  );
  const [homeworkDays, setHomeworkDays] = useState(vacancy.homeworkDays ?? 0);
  const [requiredEducation, setRequiredEducation] = useState(
    vacancy.requiredEducation?.[0] ?? 'none'
  );
  const [requiredLanguages, setRequiredLanguages] = useState(
    vacancy.requiredLanguages ?? []
  );
  const [requiredVisa, setRequiredVisa] = useState(vacancy.requiredVisa ?? []);
  return (
    <Card className="!p-6">
      <Typography variant="h5" color="dark" className="mb-4">
        {t('form_title')}
      </Typography>
      <Typography variant="body1" color="dark" className="mb-6">
        {t('form_description')}
      </Typography>
      <div className="flex flex-col gap-4">
        <Input
          variant="compact"
          label={t('title')}
          value={title}
          onChange={e => setTitle(e.target.value)}
        />

        <PlaceInput
          inputProps={{
            label: (
              <div className="flex items-center gap-2">
                <LocationPin width={16} height={16} />
                {t('location')}
              </div>
            ),
            variant: 'compact'
          }}
          defaultValue={location}
          onSelect={_location => {
            setLocation(_location);
          }}
        />
      </div>
      {/* <Divider className="my-6" /> */}
      <div className="mt-4 flex flex-col gap-4">
        <Input
          variant="compact"
          label={t('min_hours_per_week')}
          value={minHoursPerWeek}
          type="number"
          onChange={e => setMinHoursPerWeek(Number(e.target.value))}
        />
        <Select
          label={t('work_location')}
          disableSearch
          options={[
            { label: t('hybrid_work'), value: 'hybrid' },
            { label: t('remote_work'), value: 'remote' },
            { label: t('office_work'), value: 'office' }
          ]}
          value={
            homeworkDays === 0
              ? 'office'
              : homeworkDays === 5
                ? 'remote'
                : 'hybrid'
          }
          onChange={e => {
            if (e === 'hybrid') {
              setHomeworkDays(3);
            } else if (e === 'remote') {
              setHomeworkDays(5);
            } else {
              setHomeworkDays(0);
            }
          }}
        />

        {homeworkDays > 0 && homeworkDays < 5 && (
          <div className="col-span-3 flex gap-2">
            <Input
              variant="compact"
              label={t('homework_days')}
              value={homeworkDays}
              onChange={e => setHomeworkDays(Number(e.target.value))}
            />
            <Input
              variant="compact"
              label={t('office_days')}
              value={5 - homeworkDays}
              onChange={e => setHomeworkDays(5 - Number(e.target.value))}
            />
          </div>
        )}
        <Input
          variant="compact"
          label={t('required_education')}
          value={requiredEducation}
          onChange={e => setRequiredEducation(e.target.value)}
        />
        <Select
          label={t('required_languages')}
          disableSearch
          options={[
            { label: t('dutch'), value: 'nl' },
            { label: t('dutch_or_english'), value: 'nl_or_en' },
            { label: t('does_not_matter'), value: 'none' }
          ]}
          value={
            requiredLanguages.includes('Dutch') &&
            requiredLanguages.includes('English')
              ? 'nl_or_en'
              : requiredLanguages.includes('Dutch')
                ? 'nl'
                : requiredLanguages.includes('English')
                  ? 'en'
                  : 'none'
          }
          onChange={e => {
            if (e === 'nl') {
              setRequiredLanguages(['Dutch']);
            } else if (e === 'nl_or_en') {
              setRequiredLanguages(['Dutch', 'English']);
            } else {
              setRequiredLanguages([]);
            }
          }}
        />
        <Select
          label={t('required_visa')}
          options={[
            { label: t('dutch_visa'), value: 'Dutch' },
            { label: t('world_visa'), value: 'Worldwide' },
            { label: t('eu_visa'), value: 'EU' },
            { label: t('no_visa'), value: 'None' }
          ]}
          disableSearch
          value={requiredVisa?.[0] ?? 'None'}
          onChange={e => {
            if (!e) return;
            if (e === 'None') {
              setRequiredVisa([]);
            } else {
              setRequiredVisa([e]);
            }
          }}
        />
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          variant="secondary"
          size="sm"
          onClick={async () => {
            await patch({
              title,
              address: location,
              minHoursPerWeek,
              requiredEducation: [requiredEducation],
              requiredLanguages,
              homeworkDays,
              requiredVisa,
              flags: {
                ...vacancy.flags,
                basicInfoValid: true
              }
            });
            onNext();
          }}
        >
          {loading && (
            <ClipLoader color={Colors.white} size={16} className="mr-2" />
          )}
          {tCommon('next')}
        </Button>
      </div>
    </Card>
  );
};
