import { KoraApi } from '@services/api';

export const useAnalyticsRecordCreate = () => {
  const create = async (type: string, data: any) => {
    try {
      console.log('createAnalyticsRecord', type, data);
      await KoraApi.post('/analytics', { type, data });
    } catch (error) {}
  };

  return { create };
};
