import {
  BasicVacancyInfoIntakeStep,
  UploadVacancyIntakeStep,
  VerifyVacancyDetailsIntakeStep,
  VerifyVacancySummaryIntakeStep
} from '@components/vacancies/VacancyIntake';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { MetaTags } from '@elements/MetaTags';
import Wizard, { WizardStep } from '@elements/Wizard';
import { Status } from '@models/Status';
import { useVacancy } from '@services/vacancies/use-vacancy';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
export const VacancyIntakePage = () => {
  const { vacancyId } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const { vacancy } = useVacancy(vacancyId!);
  const navigate = useNavigate();
  const { t } = useTranslation('vacancy_intake');
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized || !vacancy || !vacancy.flags?.readStatus) return;
    if (vacancy?.flags?.readStatus === Status.DONE) {
      setCurrentStep(1);
    }
    if (vacancy.flags.basicInfoValid) {
      setCurrentStep(2);
    }
    if (vacancy.flags.detailsValid) {
      setCurrentStep(3);
    }
    setInitialized(true);
  }, [vacancy]);

  const handleStepChange = (step: number) => {
    setCurrentStep(step);
  };

  return (
    <ContainerWithBackButton
      onClickBackButton={() => {
        navigate(`/vacancies/${vacancyId}`);
      }}
    >
      <MetaTags title={t('page_title')} />
      <div className="flex h-[calc(100vh-200px)] w-full flex-col">
        {vacancy && (
          <Wizard activeStep={currentStep} onStepChange={handleStepChange}>
            <WizardStep title={t('progress_bar.step_1')}>
              <UploadVacancyIntakeStep
                vacancy={vacancy}
                onNext={() => handleStepChange(1)}
              />
            </WizardStep>
            <WizardStep title={t('progress_bar.step_2')}>
              <BasicVacancyInfoIntakeStep
                vacancy={vacancy}
                onNext={() => handleStepChange(2)}
              />
            </WizardStep>
            <WizardStep title={t('progress_bar.step_3')}>
              <VerifyVacancyDetailsIntakeStep
                vacancy={vacancy}
                onPrevious={() => handleStepChange(0)}
                onNext={() => handleStepChange(3)}
              />
            </WizardStep>
            <WizardStep title={t('progress_bar.step_4')}>
              <VerifyVacancySummaryIntakeStep
                vacancy={vacancy}
                onPublish={() => {
                  navigate(`/vacancies`);
                }}
              />
            </WizardStep>
          </Wizard>
        )}
      </div>
    </ContainerWithBackButton>
  );
};
