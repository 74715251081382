import WavingHand from '@assets/waving_hand.png';
import { ChatBubble } from '@components/chat';
import { ChatBaseBubble } from '@components/chat/ChatBubble';
import { Button } from '@elements/Button';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const DemoChat = () => {
  const [secondMessage, setSecondMessage] = useState(false);
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [redirectMessageVisible, setRedirectMessageVisible] = useState(false);
  const isMobile = useIsMobile();

  const { t } = useTranslation('home');
  useEffect(() => {
    setTimeout(() => {
      setSecondMessage(true);
    }, 1000);
  }, []);

  const selectRole = (role: string) => {
    setSelectedRole(role);
    setTimeout(() => {
      setRedirectMessageVisible(true);
    }, 3000);
  };

  const roleSelector = () => {
    return (
      <div className="flex">
        <ChatBaseBubble
          className={`animation-delay-1000 ${isMobile ? 'max-w-[90%]' : 'w-[60%] !max-w-[500px]'} mt-3 animate-fade-in`}
          isUser={false}
          message={
            <>
              <Typography variant="body1">
                {t('role_selector.title')}
              </Typography>
              <div className="mt-5 flex flex-col">
                <div>
                  <Button
                    disabled={selectedRole !== null}
                    variant="secondary"
                    className={`mb-4 text-left font-semibold ${isMobile ? 'text-sm' : ''}`}
                    onClick={() => selectRole('employer')}
                  >
                    {t('role_selector.employer')}
                  </Button>
                </div>
                <div>
                  <Button
                    disabled={selectedRole !== null}
                    variant="secondary"
                    className={`text-left font-semibold ${isMobile ? 'text-sm' : ''}`}
                    onClick={() => selectRole('candidate')}
                  >
                    {t('role_selector.candidate')}
                  </Button>
                </div>
              </div>
            </>
          }
        />
      </div>
    );
  };

  const redirectMessage = () => {
    return (
      <div className="flex">
        <ChatBaseBubble
          className="mt-5 animate-fade-in"
          isUser={false}
          message={
            <>
              <Typography variant="body1">
                {t('role_selector.redirect')}
              </Typography>
              <div className="flex justify-start">
                <Link to={`/register?type=${selectedRole?.toUpperCase()}`}>
                  <Button
                    variant="secondary"
                    className={`mt-5 ${isMobile ? 'text-sm' : ''}`}
                  >
                    {t('role_selector.redirect_button')}
                  </Button>
                </Link>
              </div>
            </>
          }
        />
      </div>
    );
  };

  return (
    <div
      className={`h-full min-h-[500px] rounded-lg bg-[#ffffff99] p-4 ${
        isMobile && 'min-h-[535px]'
      }`}
    >
      <ChatBubble
        message={<img src={WavingHand} width={32} alt="Waving hand" />}
        className="animate-fade-in"
        isUser={false}
        isLast={true}
        messageId="1"
        translatable={false}
        quickActions={[]}
        sendMessage={() => {}}
      />
      {secondMessage && roleSelector()}
      {selectedRole && (
        <div className="mt-3 animate-fade-in">
          <ChatBubble
            className="mt-3 animate-fade-in"
            message={t(`role_selector.${selectedRole}`)}
            isUser={true}
            messageId="2"
            translatable={false}
            quickActions={[]}
            sendMessage={() => {}}
          />
        </div>
      )}
      {redirectMessageVisible && redirectMessage()}
    </div>
  );
};
