import { MarkdownViewer } from '@elements/MarkdownViewer';
import { Typography } from '@elements/Typography';
import { AccountType } from '@models/UserAccount';
import { Vacancy } from '@models/Vacancy';
import { useAccountContext } from '@providers/UserContext';
import { useMarkdownContent } from '@services/use-markdown-content';
import { useTranslation } from 'react-i18next';

interface VacancySummaryProps {
  vacancy: Vacancy;
}

export const VacancySummary = ({ vacancy }: VacancySummaryProps) => {
  const { t } = useTranslation('vacancy_intake', {
    keyPrefix: 'verify_vacancy_summary'
  });
  const { user } = useAccountContext();
  const { markdownContent: employerDescription } = useMarkdownContent(
    vacancy.employer?.summaryUrl!,
    'about the company'
  );
  return (
    <>
      <Typography variant="body1" className="mb-2 text-base">
        {vacancy.description}
      </Typography>
      <div className="flex flex-col">
        <Typography variant="body1" className="text-base">
          📍 {t('location')}: {vacancy.address?.city},{' '}
          {vacancy.address?.country}
        </Typography>
        <Typography variant="body1" className="text-base">
          {vacancy.salaryMin && vacancy.salaryMax ? (
            <>
              💰 {t('salary')}: {vacancy.salaryMin} - {vacancy.salaryMax}
            </>
          ) : (
            <>
              💰 {t('salary')}: {t('negotiable')}
            </>
          )}
        </Typography>
        <Typography variant="body1" className="text-base">
          🏠 {t('work_location')}:{' '}
          {!vacancy.homeworkDays ? 5 : vacancy.homeworkDays} days per week{' '}
          {!vacancy.homeworkDays ? 'at location' : 'at home'}
        </Typography>
      </div>
      <div>
        <Typography variant="h3" className="mt-4 !text-xl text-primary">
          {t('about_the_company')}
        </Typography>
        <MarkdownViewer content={employerDescription} />
      </div>
      <div>
        <Typography variant="h3" className="mt-4 !text-xl text-primary">
          {t('skills_and_tech_stack')}
        </Typography>
        <ul className="mb-2 ml-6 list-disc">
          {vacancy.requiredSkills?.map(skill => (
            <li className="my-1 leading-6" key={skill}>
              {skill}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <Typography variant="h3" className="mt-4 !text-xl text-primary">
          {t('what_you_will_do')}
        </Typography>
        <ul className="mb-2 ml-6 list-disc">
          {vacancy.responsibilities?.map(responsibility => (
            <li className="my-1 leading-6" key={responsibility}>
              {responsibility}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <Typography variant="h3" className="mt-4 !text-xl text-primary">
          {t('benefits')}
        </Typography>
        <ul className="mb-2 ml-6 list-disc">
          {vacancy.benefits?.map(benefit => (
            <li className="my-1 leading-6" key={benefit}>
              {benefit}
            </li>
          ))}
        </ul>
      </div>
      {user?.accountType === AccountType.EMPLOYER && (
        <div className="mt-10 text-center">
          <Typography variant="caption" className="text-dark">
            {t('summary_disclaimer')}
          </Typography>
        </div>
      )}
    </>
  );
};
