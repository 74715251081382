import { ProgressCircle } from '@components/profile';
import { VacancySummary } from '@components/vacancies/VacancySummary';
import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { ContainerWithBackButton } from '@elements/ContainerWithBackButton';
import { Divider } from '@elements/Divider';
import { Typography } from '@elements/Typography';
import { Trash } from '@icons/index';
import { useBackground } from '@providers/BackgroundProvider';
import { useDeleteVacancy } from '@services/vacancies/use-delete-vacancy';
import { useVacancy } from '@services/vacancies/use-vacancy';
import { Colors } from '@utils/ColorUtils';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const VacancyDetailPage = () => {
  useBackground(false);
  const { vacancyId } = useParams();
  const { vacancy } = useVacancy(vacancyId!, true);
  const { t } = useTranslation('vacancies');
  const { deleteVacancy } = useDeleteVacancy(vacancyId!);
  const navigate = useNavigate();

  const handleDelete = async () => {
    const isDeleted = await deleteVacancy();
    if (isDeleted) {
      navigate('/vacancies');
    }
  };

  const Body = () => {
    return (
      <>
        <div className="flex gap-8">
          <div className="flex w-full flex-col justify-between gap-8">
            <div className="group flex items-center gap-2">
              <Typography
                variant="h1"
                className={`line-clamp-2 text-xl font-bold leading-[3rem] ${
                  vacancy?.title ? '' : 'italic'
                }`}
                enableTooltip
                tooltipPlacement="top"
              >
                {vacancy?.title ?? t('draft')}
              </Typography>
            </div>

            <Link
              to={`/companies/${vacancy?.employer?.id}`}
              className="no-underline"
            >
              <Typography variant="h2" className="text-secondary">
                {vacancy?.employer?.companyName}
              </Typography>
            </Link>
          </div>
          {vacancy?.intakeProgress !== undefined && (
            <div className="flex items-center justify-end pl-3">
              <ProgressCircle
                hideButton
                percentage={
                  vacancy?.intakeProgress ? vacancy.intakeProgress * 100 : 0
                }
              />
            </div>
          )}
        </div>
        <Divider className="my-6 w-full" />
        <div className="mb-4 flex">
          <Link to={`/vacancies/${vacancyId}/intake`}>
            <Button variant="info">{t('edit')}</Button>
          </Link>
          <Button variant="void" onClick={handleDelete}>
            <Trash />
          </Button>
        </div>
        <Card>
          {vacancy ? (
            <VacancySummary vacancy={vacancy} />
          ) : (
            <ClipLoader color={Colors.secondary} />
          )}
        </Card>
      </>
    );
  };

  return (
    <ContainerWithBackButton
      loading={!vacancy}
      onClickBackButton={() => navigate(`/vacancies`)}
    >
      <Body />
    </ContainerWithBackButton>
  );
};
