import { Address } from './address';
import { Resume } from './Resume';

export class Candidate {
  constructor(
    public firstName?: string | null,
    public lastName?: string | null,
    public summaryUrl?: string,
    public summaryDraftUrl?: string,
    public phone?: string | null,
    public email?: string | null,
    public intakeProgress?: number,
    public birthDate?: Date | null,
    public profilePicture?: string | null,
    public address: Address | null = null,
    public resume?: Resume | null,
    public countryCode?: string | null,
    public indRegistered?: boolean,
    public linkedinUrl?: string | null,
    public beingMatched?: boolean,
    public preferredSalary?: number | null
  ) {}

  static fromJson(json: any): Candidate {
    return new Candidate(
      json.firstName,
      json.lastName,
      json.summaryUrl,
      json.summaryDraftUrl,
      json.phone,
      json.email,
      json.intakeProgress,
      json.birthDate ? new Date(json.birthDate) : null,
      json.profilePicture,
      json.address ? Address.fromJson(json.address) : null,
      json.resume ? Resume.fromJson(json.resume) : null,
      json.countryCode,
      json.indRegistered,
      json.linkedinUrl,
      json.beingMatched,
      json.preferredSalary
    );
  }
}
