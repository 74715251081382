import { ChevronDown, ChevronUp } from '@icons/index';
import { Colors } from '@utils/ColorUtils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { IInput, Input } from './Input';
import OutsideAlerter from './OutsideAlerter';

type Option = {
  label: string;
  value: string | number;
  icon?: string;
};

export interface SelectProps {
  label?: string;
  options?: Option[];
  onChange?: (value: string | number | undefined) => void;
  inputProps?: IInput;
  value?: string | number;
  direction?: 'up' | 'down';
  disableSearch?: boolean;
  disabled?: boolean;
}
export const Select = ({
  options,
  disabled,
  onChange,
  value,
  label,
  disableSearch,
  direction = 'down',
  inputProps
}: SelectProps) => {
  const { t } = useTranslation('common');

  const [activeOption, setActiveOption] = useState<Option | undefined>(
    value ? options?.find(option => option.value === value) : undefined
  );

  const [activeText, setActiveText] = useState<string>(
    activeOption?.label ?? ''
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    const newActiveOption = value
      ? options?.find(option => option.value === value)
      : undefined;
    setActiveOption(newActiveOption);
    setActiveText(newActiveOption?.label ?? '');
    setIsSearching(false);
  }, [value, options]);

  const handleClose = () => {
    setIsOpen(false);
    // setActiveText(activeOption?.label ?? '');
    setIsSearching(false);
  };

  // Only filter options if search is enabled and user is actively searching
  const filteredOptions =
    disableSearch || !isSearching
      ? options
      : options?.filter(
          option =>
            !activeText ||
            option.label.toLowerCase().includes(activeText.toLowerCase()) ||
            option.value
              .toString()
              .toLowerCase()
              .includes(activeText.toLowerCase())
        );

  return (
    <div className="relative">
      <OutsideAlerter onClickOutside={handleClose}>
        <Input
          label={label}
          variant={label ? 'compact' : 'default'}
          disabled={disableSearch || disabled}
          value={activeText}
          onClick={() => {
            setIsOpen(prev => !prev);
          }}
          onChange={e => {
            if (disableSearch) return;
            setActiveText(e.target.value);
            setIsSearching(true);
            if (!isOpen) setIsOpen(true);
          }}
          startAdornment={
            activeOption?.icon && (
              <span className="ml-2 mr-2">{activeOption?.icon}</span>
            )
          }
          endAdornment={
            <Button
              variant="info"
              size="sm"
              className="flex items-center gap-2 border-none !bg-transparent !p-0"
              onClick={e => {
                e.stopPropagation();
                setIsOpen(!isOpen);
                if (isOpen) {
                  setActiveText(activeOption?.label ?? '');
                  setIsSearching(false);
                }
              }}
            >
              {isOpen && !disabled ? (
                <ChevronUp color={Colors.placeholder} />
              ) : (
                <ChevronDown color={Colors.placeholder} />
              )}
            </Button>
          }
          enableAutoSave={false}
          {...inputProps}
          className={`${inputProps?.className} ${
            disabled
              ? '!cursor-default !bg-light-gray'
              : '!cursor-pointer !bg-white'
          }`}
        />
        {isOpen && !disabled && (
          <div
            className={`absolute left-[-5px] z-[9999] max-h-[300px] w-auto min-w-full overflow-y-auto rounded-[20px] bg-white p-[16px_24px] shadow-[0px_4px_8px_0px_rgba(0,0,0,0.08),0px_-2px_8px_0px_rgba(0,0,0,0.08)] ${
              direction === 'up' ? 'bottom-[40px] top-auto' : 'top-[40px]'
            }`}
          >
            {filteredOptions?.map((option, index) => (
              <Button
                variant="info"
                size="sm"
                className="w-full whitespace-nowrap border-none bg-transparent !px-0 text-left"
                key={`language-${index}`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setActiveOption(option);
                  setActiveText(option.label);
                  onChange?.(option.value);
                  setIsOpen(false);
                }}
              >
                {option.icon && <span className="mr-2">{option.icon}</span>}
                {option.label}
              </Button>
            ))}
            {filteredOptions?.length === 0 && (
              <div className="text-gray-500 py-2">{t('no_results')}</div>
            )}
          </div>
        )}
      </OutsideAlerter>
    </div>
  );
};
