import { Candidate } from '@models/candidate';
import { Employer } from '@models/Employer';
import { AccountType, UserAccount } from '@models/UserAccount';
import useAccount from '@services/account/use-account';
import { useNotifications } from '@services/notifications/use-notifications';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { io } from 'socket.io-client';

// Define `IUserContext` to take children as its prop
export interface IUserContext {
  children: ReactNode;
}

// Generic UserContextType that includes the generic `T` for accountInfo type
export interface UserContextType<T extends Candidate | Employer | null> {
  user: UserAccount<T> | null;
  isLoggedIn: boolean;
  accountType: AccountType;
  setAccountType: (accountType: AccountType) => void;
  isLoading?: boolean;
  mutate: () => void;
}

// Using a generic type `T` for the context, defaulting to `any`
export const AccountContext = createContext<UserContextType<any>>({
  user: null,
  accountType: AccountType.CANDIDATE,
  isLoggedIn: false,
  isLoading: true,
  mutate: () => {},
  setAccountType: () => {}
});

// Singleton socket instances
let userSocket: ReturnType<typeof io> | null = null;
let notificationSocket: ReturnType<typeof io> | null = null;

const getOrCreateUserSocket = (userId: string) => {
  if (!userSocket) {
    userSocket = io(import.meta.env.VITE_API_URL, {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000
    });
    userSocket.emit('join', 'user-updates-' + userId);
    userSocket.on('connection', socket => {
      socket.join(userId);
    });
  }
  return userSocket;
};

const getOrCreateNotificationSocket = (userId: string) => {
  if (!notificationSocket) {
    notificationSocket = io(import.meta.env.VITE_API_URL);
    notificationSocket.emit('join', 'notifications-' + userId);
  }
  return notificationSocket;
};

export const AccountContextProvider = ({ children }: IUserContext) => {
  const { user, isLoading, mutate } = useAccount();
  const { mutate: mutateNotifications } = useNotifications();
  const [accountType, setAccountType] = useState<AccountType>(() => {
    // Try to get the stored account type from localStorage
    const storedAccountType = localStorage.getItem('accountType');
    // If it exists and is a valid AccountType, use it; otherwise default to CANDIDATE
    if (
      storedAccountType &&
      Object.values(AccountType).includes(storedAccountType as AccountType)
    ) {
      return storedAccountType as AccountType;
    }
    return AccountType.CANDIDATE;
  });

  useEffect(() => {
    if (user) {
      const socket = getOrCreateUserSocket(user.id);
      const notifSocket = getOrCreateNotificationSocket(user.id);

      // Set up listeners
      const userUpdateHandler = () => mutate();
      const notificationHandler = () => mutateNotifications();

      socket.on('message', userUpdateHandler);
      notifSocket.on('message', notificationHandler);

      if (user.accountType !== accountType) {
        setAccountType(user.accountType);
      }

      return () => {
        // Remove listeners but keep the connection
        socket.off('message', userUpdateHandler);
        notifSocket.off('message', notificationHandler);
      };
    }
  }, [user]);

  // Cleanup sockets when the app unmounts
  useEffect(() => {
    return () => {
      if (userSocket) {
        userSocket.disconnect();
        userSocket = null;
      }
      if (notificationSocket) {
        notificationSocket.disconnect();
        notificationSocket = null;
      }
    };
  }, []);

  const changeAccountType = (accountType: AccountType) => {
    if (user) throw new Error('Cannot change account type while logged in');
    setAccountType(accountType);
    // Store the new account type in localStorage
    localStorage.setItem('accountType', accountType);
  };

  return (
    <AccountContext.Provider
      value={{
        user,
        isLoggedIn: Boolean(user),
        accountType,
        isLoading,
        mutate,
        setAccountType: changeAccountType
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

// Adjusted hook to include generic type `T`
export const useAccountContext = <T extends Candidate | Employer | null>() =>
  useContext(AccountContext) as UserContextType<T>;
