import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import PercentageSlider from '@elements/PercentageSlider';
import { Typography } from '@elements/Typography';
import { Match, MatchingStatus } from '@models/Match';
import { useAnalyticsRecordCreate } from '@services/analytics/use-analytics-record-create';
import { usePatchCandidateMatch } from '@services/candidate/use-patch-candidate-match';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface VacancyMatchCardProps {
  match: Match;
}

export const VacancyMatchCard = ({ match }: VacancyMatchCardProps) => {
  const { t } = useTranslation('recommended_jobs');
  const vacancy = match.vacancy;
  const { patchWithConfirm } = usePatchCandidateMatch(match.id);
  const { create: createAnalyticsRecord } = useAnalyticsRecordCreate();

  const applyButton = () => {
    return (
      <Button
        variant="info"
        size="sm"
        className="w-full"
        onClick={() =>
          patchWithConfirm({
            status: MatchingStatus.APPLIED,
            title: vacancy.isScraped
              ? t('apply_check_title')
              : t('apply_confirm_title'),
            description: vacancy.isScraped
              ? t('apply_check_description')
              : t('apply_confirm_description')
          })
        }
      >
        {t('apply')}
      </Button>
    );
  };

  return (
    <Card>
      <div className="flex flex-col">
        <div className="mb-8 flex flex-col gap-4">
          <div className="flex justify-between gap-2">
            <Typography
              variant="h5"
              className="line-clamp-2 h-[48px] leading-6"
            >
              {vacancy.title}
            </Typography>
            {!vacancy.isScraped && (
              <div className="flex max-h-[24px] items-center justify-center rounded-[8px_8px_2px_8px] bg-success p-[2px_6px] text-xs text-white">
                {t('verified')}
              </div>
            )}
          </div>
          <Typography variant="body1" className="text-light">
            {vacancy.employer?.companyName}
          </Typography>
        </div>
        <PercentageSlider percentage={match.percentage} />
        <div className="mt-8 flex justify-between gap-4">
          <Link to={`/jobs/${match.vacancy.id}`} className="w-6/12">
            <Button variant="primary" size="sm" className="h-full w-full">
              {t('job_details')}
            </Button>
          </Link>
          <div className="w-6/12">
            {match.vacancy.isScraped && match.vacancy.originalUrl ? (
              <Link
                to={match.vacancy.originalUrl.trim()}
                onClick={() =>
                  createAnalyticsRecord('VISIT_ORIGINAL_VACANCY', {
                    vacancyId: match.vacancy.id
                  })
                }
                target="_blank"
                className="w-full"
              >
                {applyButton()}
              </Link>
            ) : (
              applyButton()
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
