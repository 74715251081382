import { useQueryParameters } from '@providers/QueryParametersProvider';
import React, { ReactNode, useEffect, useState } from 'react';

// Define the prop types for Tab
interface TabProps {
  title: string;
  children: ReactNode;
}

// Tab Component
const Tab: React.FC<TabProps> = ({ children }) => {
  return <div>{children}</div>;
};

// Define the prop types for Tabs
interface TabsProps {
  children: ReactNode;
  compact?: boolean;
  cta?: ReactNode;
}

// Tabs Component
const Tabs: React.FC<TabsProps> = ({ children, cta, compact }) => {
  // Extract titles from children
  const titles = React.Children.map(children, child => {
    if (React.isValidElement<TabProps>(child)) {
      return child.props.title;
    }
    return null;
  });

  const { setQueryParam, getQueryParam } = useQueryParameters();
  const activeTab = getQueryParam('tab');
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (activeTab) {
      const index = titles
        ?.map(title => title.toUpperCase())
        .indexOf(activeTab);
      if (index !== -1) {
        setActiveIndex(index ?? 0);
      }
    }
  }, [activeTab]);

  const handleSelectTab = (title: string, index: number) => {
    setQueryParam('tab', title.toUpperCase());
    setActiveIndex(index);
  };

  const header = () => {
    if (compact) {
      return (
        <div className="mt-4 flex justify-between border-b border-[rgba(0,0,0,0.2)]">
          <div className="flex space-x-4">
            {titles?.map((title, index) => (
              <div
                key={index}
                className={`cursor-pointer px-2 pb-3 ${
                  activeIndex === index ? 'border-b-2 border-primary' : ''
                }`}
                onClick={() => handleSelectTab(title, index)}
              >
                {title}
              </div>
            ))}
          </div>
          {cta}
        </div>
      );
    } else {
      return (
        <div className="flex justify-between border-b border-[rgba(0,0,0,0.2)] py-8">
          <div className="flex space-x-4">
            {titles?.map((title, index) => (
              <div
                key={index}
                data-testid={`tab-${index}`}
                className={`flex cursor-pointer items-center justify-center rounded-[20px] p-[6px_24px] ${
                  activeIndex === index
                    ? 'bg-primary text-white'
                    : 'text-gray-500 border border-black'
                }`}
                onClick={() => handleSelectTab(title, index)}
              >
                {title}
              </div>
            ))}
          </div>
          {cta}
        </div>
      );
    }
  };

  return (
    <div className="w-full">
      {/* Tab Titles */}
      {header()}
      {/* Tab Content */}
      <div>
        {React.Children.map(children, (child, index) => {
          if (index === activeIndex && React.isValidElement(child)) {
            return <div className="pt-8">{child}</div>;
          }
          return null;
        })}
      </div>
    </div>
  );
};

export { Tab, Tabs };
