import { Button } from '@elements/Button';
import { Typography } from '@elements/Typography';
import { useAccountContext } from '@providers/UserContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const EmployerPresentSection = () => {
  const { t } = useTranslation('home', { keyPrefix: 'employer' });
  const { t: tHome } = useTranslation('home');
  const { accountType } = useAccountContext();

  return (
    <div
      className="rounded-[10px_40px_40px_40px] p-12"
      style={{
        background: 'linear-gradient(96deg, #016 83.95%, #02C 100%)'
      }}
    >
      <div className="grid grid-cols-1 gap-12 md:grid-cols-[3fr_2fr]">
        <div className="flex flex-col items-center justify-center">
          <Typography variant="h2" className="!leading-10 text-white">
            {t('present_title')}
          </Typography>
        </div>
        <div className="flex flex-col items-center justify-center">
          <Link to={`/register?type=${accountType}`}>
            <Button variant="secondary" className="px-10 py-2">
              {tHome('try_for_free')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
