import { useToast } from '@elements/Toast';
import { MatchingStatus } from '@models/Match';
import { useValidationPopup } from '@providers/ValidationPopupProvider';
import { KoraApi } from '@services/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCandidateFunnel } from './use-candidate-funnel';
import { useCandidateMatches } from './use-candidate-matches';
interface PatchOptions {
  status?: MatchingStatus;
  onSuccess?: () => void;
  archived?: boolean;
}

interface PatchWithConfirmOptions {
  title?: string;
  description?: string;
  status?: MatchingStatus;
  archived?: boolean;
  onSuccess?: () => void;
}

export const usePatchCandidateMatch = (_matchId: string) => {
  const [matchId, setMatchId] = useState<string>(_matchId);
  const { mutate } = useCandidateMatches();
  const { mutate: mutateFunnel } = useCandidateFunnel();
  const { success } = useToast();
  const { t } = useTranslation('recommended_jobs');
  const { show } = useValidationPopup();
  const navigate = useNavigate();

  useEffect(() => {
    setMatchId(_matchId);
  }, [_matchId]);

  const patch = async ({ status, onSuccess, archived }: PatchOptions) => {
    await KoraApi.patch(`/candidate/matches/${matchId}`, {
      status,
      archived
    });
    if (status === MatchingStatus.APPLIED) {
      success(t('apply_success'));
    }

    mutate();
    mutateFunnel();
    onSuccess?.();
    navigate('/funnel');
  };

  const patchWithConfirm = ({
    status,
    title,
    description,
    archived,
    onSuccess
  }: PatchWithConfirmOptions) => {
    show({
      title: title ?? t('apply_confirm_title'),
      description: description ?? t('apply_confirm_description'),
      callback: async () => {
        await patch({ status, onSuccess, archived });
      }
    });
  };

  return { patch, patchWithConfirm };
};
