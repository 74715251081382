import { Button } from '@elements/Button';
import { Input } from '@elements/Input';
import { Select } from '@elements/Select';
import { Trash } from '@icons/index';
import { Skill, SkillType } from '@models/Skill';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface SkillEditorProps {
  skill: Skill;
  onDelete: () => void;
  onChange: (skill: Skill) => void;
}

export const SkillEditor = ({
  skill,
  onDelete,
  onChange
}: SkillEditorProps) => {
  const [title, setTitle] = useState(skill.stack);
  const [category, setCategory] = useState(skill.type);
  const { t } = useTranslation('candidate_intake', {
    keyPrefix: 'resume_intake.skill_editor'
  });
  const [level, setLevel] = useState(skill.yearsOfExperience);

  const skillTypeOptions = [
    { label: t('programming'), value: 'PROGRAMMING' },
    { label: t('databases'), value: 'DATABASES' },
    { label: t('analytics'), value: 'ANALYTICS' },
    { label: t('data_science'), value: 'DATA_SCIENCE' },
    { label: t('testing'), value: 'TESTING' },
    { label: t('system_administration'), value: 'SYSTEM_ADMINISTRATION' },
    { label: t('tools_and_frameworks'), value: 'TOOLS_AND_FRAMEWORKS' },
    { label: t('networking'), value: 'NETWORKING' },
    { label: t('devops'), value: 'DEVOPS' },
    { label: t('operating_systems'), value: 'OPERATING_SYSTEMS' },
    { label: t('languages'), value: 'LANGUAGES' },
    { label: t('other'), value: 'OTHER' }
  ];
  return (
    <div className="flex w-full items-center gap-4">
      <div className="grid w-full grid-cols-3 gap-4">
        <Input
          label={t('title')}
          defaultValue={title}
          variant="compact"
          onChange={e => {
            setTitle(e.target.value);
            skill.stack = e.target.value;
            onChange(skill);
          }}
        />
        <Select
          options={skillTypeOptions}
          label={t('category')}
          value={category}
          disableSearch
          onChange={e => {
            if (!e) return;
            const value = e as SkillType;
            setCategory(value);
            skill.type = value;
            onChange(skill);
          }}
        />
        <Input
          label={t('years_of_experience')}
          defaultValue={level}
          variant="compact"
          type="number"
          onChange={e => {
            setLevel(Number(e.target.value));
            skill.yearsOfExperience = Number(e.target.value);
            onChange(skill);
          }}
        />
      </div>
      <div>
        <Button variant="void" size="sm" className="!p-0" onClick={onDelete}>
          <Trash />
        </Button>
      </div>
    </div>
  );
};
