import { ChevronLeft, ChevronRight } from '@icons/index';
import { useEffect, useState } from 'react';

type CalendarProps = {
  locale?: string;
  defaultValue?: Date;
  onChange?: (date: Date) => void;
  onlyMonthYear?: boolean;
};

export const Calendar: React.FC<CalendarProps> = ({
  locale = 'en-US',
  defaultValue,
  onChange,
  onlyMonthYear,
  ...props
}) => {
  const initialDate = defaultValue || new Date();
  const [date, setDate] = useState(initialDate);
  const [days, setDays] = useState<(string | Date)[]>([]);
  const [selectedDate, setSelectedDate] = useState(
    defaultValue?.toDateString() || ''
  );

  const [year, setYear] = useState(initialDate.getFullYear());
  const [month, setMonth] = useState(initialDate.getMonth());
  const [view, setView] = useState<'days' | 'years' | 'months'>('days');

  useEffect(() => {
    displayCalendar();
  }, [year, month]);

  useEffect(() => {
    if (!defaultValue) return;
    if (defaultValue.toDateString() === selectedDate) return;
    setSelectedDate(defaultValue.toDateString());
    setDate(defaultValue);
    setYear(defaultValue.getFullYear());
    setMonth(defaultValue.getMonth());
  }, [defaultValue]);

  const displayCalendar = () => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const firstDayIndex = (firstDay.getDay() + 6) % 7; // Adjust index to start from Monday
    const numberOfDays = lastDay.getDate();
    const tempDays = [];

    // Add empty days to align the first day of the month correctly
    for (let x = 0; x < firstDayIndex; x++) {
      tempDays.push('');
    }

    // Add days of the month
    for (let i = 1; i <= numberOfDays; i++) {
      let currentDate = new Date(year, month, i);
      tempDays.push(currentDate);
    }

    setDays(tempDays);
  };

  const handlePrevMonth = () => {
    if (month === 0) {
      setYear(year - 1);
      setMonth(11);
    } else {
      setMonth(month - 1);
    }
    setDate(new Date(year, month - 1));
    setSelectedDate('');
  };

  const handlePrevYear = () => {
    setYear(year - 1);
    setMonth(11);
    setDate(new Date(year - 1, 11));
    setSelectedDate('');
  };

  const handleNextYear = () => {
    setYear(year + 1);
    setMonth(0);
    setDate(new Date(year + 1, 0));
    setSelectedDate('');
  };

  const handleNextMonth = () => {
    if (month === 11) {
      setYear(year + 1);
      setMonth(0);
    } else {
      setMonth(month + 1);
    }
    const newDate = new Date(year, month + 1);
    setDate(newDate);
    if (onlyMonthYear && onChange) {
      onChange(newDate);
    }
    setSelectedDate('');
  };

  const handleDayClick = (day: Date) => {
    if (day) {
      setSelectedDate(day.toDateString());
      onChange && onChange(day);
    }
  };

  const formattedMonth = date.toLocaleString(locale, {
    month: 'long'
    // year: 'numeric'
  });
  const formattedYear = date.toLocaleString(locale, {
    year: 'numeric'
  });

  // Get day names based on the locale, starting from Monday
  const dayNames = Array.from({ length: 7 }).map((_, index) => {
    const day = new Date(2021, 0, index + 4); // Start from Monday, 4th January 2021
    return day.toLocaleDateString(locale, { weekday: 'short' }).substring(0, 1);
  });

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    // Show 24 years before and after current year for a nice grid
    for (let year = currentYear - 70; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };

  const handleYearSelect = (selectedYear: number) => {
    setYear(selectedYear);
    setDate(new Date(selectedYear, month));
    setView('days');
    setSelectedDate('');
    if (onlyMonthYear && onChange) {
      onChange(new Date(selectedYear, month));
    }
  };

  const scrollToSelectedYear = () => {
    const selectedYearElement = document.getElementById(`year-${year}`);
    const scrollContainer = document.getElementById('year-scroll-container');
    if (selectedYearElement && scrollContainer) {
      // Calculate scroll position to center the element
      const containerHeight = scrollContainer.clientHeight;
      const elementTop = selectedYearElement.offsetTop;
      const elementHeight = selectedYearElement.clientHeight;

      scrollContainer.scrollTop =
        elementTop - containerHeight / 2 + elementHeight / 2;
    }
  };

  useEffect(() => {
    if (view === 'years') {
      setTimeout(scrollToSelectedYear, 0);
    }
  }, [view]);

  // Add this new function to get month names
  const getMonthOptions = () => {
    return Array.from({ length: 12 }).map((_, index) => {
      const date = new Date(2021, index, 1);
      return date.toLocaleString(locale, { month: 'long' });
    });
  };

  const handleMonthSelect = (selectedMonthIndex: number) => {
    setMonth(selectedMonthIndex);
    setDate(new Date(year, selectedMonthIndex));
    setView('days');
    setSelectedDate('');
    if (onlyMonthYear && onChange) {
      onChange(new Date(year, selectedMonthIndex));
    }
  };

  return (
    <div
      className="bg-gray-100 relative flex flex-col items-center justify-center"
      {...props}
    >
      <div className="w-[340px] p-4">
        <div
          className={`flex flex-col items-center justify-between ${
            onlyMonthYear ? 'mb-0' : 'mb-4'
          }`}
        >
          {view === 'days' ? (
            <>
              <div className="mb-1 flex w-full items-center justify-between">
                <button
                  onClick={handlePrevYear}
                  data-testid="prev-year"
                  className="px-2 text-xl text-secondary"
                >
                  <ChevronLeft />
                </button>
                <div
                  className="text-lg font-semibold text-secondary hover:cursor-pointer"
                  onClick={() => setView('years')}
                >
                  {formattedYear}
                </div>
                <button
                  onClick={handleNextYear}
                  data-testid="next-year"
                  className="px-2 text-xl text-secondary"
                >
                  <ChevronRight />
                </button>
              </div>
              <div className="flex w-full items-center justify-between">
                <button
                  onClick={handlePrevMonth}
                  data-testid="prev-month"
                  className="px-2 text-xl text-secondary"
                >
                  <ChevronLeft />
                </button>
                <div
                  className="text-lg font-semibold text-secondary hover:cursor-pointer"
                  onClick={() => setView('months')}
                >
                  {formattedMonth}
                </div>
                <button
                  onClick={handleNextMonth}
                  data-testid="next-month"
                  className="px-2 text-xl text-secondary"
                >
                  <ChevronRight />
                </button>
              </div>
            </>
          ) : view === 'years' ? (
            <>
              <div className="w-8" />
              <div
                className="text-lg font-semibold text-secondary hover:cursor-pointer"
                onClick={() => setView('days')}
              >
                Select Year
              </div>
              <div className="w-8" />
            </>
          ) : (
            <>
              <div className="w-8" />
              <div
                className="text-lg font-semibold text-secondary hover:cursor-pointer"
                onClick={() => setView('days')}
              >
                Select Month
              </div>
              <div className="w-8" />
            </>
          )}
        </div>

        {view === 'days' ? (
          <>
            {!onlyMonthYear && (
              <>
                <div className="text-gray-500 mb-2 grid grid-cols-7 gap-2 text-center text-sm font-semibold">
                  {dayNames.map((dayName, index) => (
                    <div key={index}>{dayName}</div>
                  ))}
                </div>

                <div className="grid grid-cols-7 gap-2">
                  {days.map((day, index) => (
                    <div
                      key={index}
                      className={`flex h-8 w-8 cursor-pointer items-center justify-center rounded-full ${
                        day ? 'hover:bg-blue-100' : ''
                      } ${
                        day &&
                        day instanceof Date &&
                        day.toDateString() === selectedDate
                          ? 'bg-secondary text-white'
                          : ''
                      }`}
                      onClick={() => {
                        const isDate = day instanceof Date;
                        if (!isDate) return;
                        handleDayClick(day);
                      }}
                    >
                      {day && day instanceof Date && day.getDate()}
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        ) : view === 'years' ? (
          <div
            id="year-scroll-container"
            className="no-scrollbar grid h-[240px] grid-cols-4 gap-2 overflow-y-auto"
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            {getYearOptions().map(yearOption => (
              <div
                key={yearOption}
                id={`year-${yearOption}`}
                className={`flex h-10 cursor-pointer items-center justify-center rounded-md hover:bg-blue-100 ${
                  yearOption === year ? 'bg-secondary text-white' : ''
                }`}
                onClick={() => handleYearSelect(yearOption)}
              >
                {yearOption}
              </div>
            ))}
          </div>
        ) : (
          <div className="grid h-[240px] grid-cols-3 gap-2">
            {getMonthOptions().map((monthName, index) => (
              <div
                key={monthName}
                className={`flex h-10 cursor-pointer items-center justify-center rounded-md hover:bg-blue-100 ${
                  index === month ? 'bg-secondary text-white' : ''
                }`}
                onClick={() => handleMonthSelect(index)}
              >
                {monthName}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
