import { Vacancy } from '@models/Vacancy';
import { useSocket } from '@services/socket/use-socket';
import useSWR from 'swr';
import { useEmployerVacancies } from '../employer/use-employer-vacancies';

export const useVacancy = (id: string, disableRefresh = false) => {
  const { data, isLoading, ...swr } = useSWR(`/vacancies/${id}`, {
    refreshInterval: disableRefresh ? undefined : 10000 // 10 seconds
  });
  const { mutate: mutateIndex } = useEmployerVacancies();
  let vacancy = null;
  if (data) {
    vacancy = Vacancy.fromJson(data);
  }

  const roomId = vacancy ? `vacancy-updates-${vacancy.id}` : '';
  useSocket(roomId, () => {
    swr.mutate();
    mutateIndex();
  });

  return {
    vacancy,
    ...swr,
    isLoading
  };
};
