import { Colors } from '@utils/ColorUtils';
import { ClipLoader } from 'react-spinners';
import { BackButton } from './BackButton';
import { Container } from './Container';

interface ContainerWithBackButtonProps {
  children?: React.ReactNode | React.ReactNode[];
  loading?: boolean;
  rightContent?: React.ReactNode;
  onClickBackButton?: () => void;
  [key: string]: unknown;
}

export const ContainerWithBackButton = ({
  children,
  loading,
  onClickBackButton,
  rightContent,
  ...props
}: ContainerWithBackButtonProps) => {
  return (
    <Container {...props}>
      {!loading ? (
        <div className="grid grid-cols-[1fr_5fr_1fr] items-start pt-4">
          <BackButton onClick={onClickBackButton} />
          <div className="w-full px-4">{children}</div>
          <div className="flex w-full justify-end">{rightContent}</div>
        </div>
      ) : (
        <div className="flex h-full items-center justify-center">
          <ClipLoader color={Colors.primary} size={50} />
        </div>
      )}
    </Container>
  );
};
